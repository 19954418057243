import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';
import { FeatureToggleService } from '@trend-common/feature-toggle.service';
import { Account } from '../../../core/stores/account';

@Component({
  selector: 'feature-flags-modal',
  templateUrl: 'feature-flags-modal.html',
  styleUrls: ['feature-flags-modal.scss']
})
export class FeatureFlagModal {
  public featureFlags:any[];
  public featureFlagsLoading:boolean = true;
  public featureFlagsError:boolean = false;
  public addNewFeatureFlagDialog:boolean = false;
  public newFeatureFlagKeyFC:FormControl = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<FeatureFlagModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private feedbackMessagesService:FeedbackMessagesService,
    private _featureToggleService:FeatureToggleService,
    private _account:Account
  ) {}

  ngOnInit():void {
    this.getAccountFeatureFlags();
  }

  public getAccountFeatureFlags():void {
    this.featureFlagsError = false;
    this.featureFlagsLoading = true;

    this._account.getAllAccountFeatures().subscribe((features:any) => {
      this.featureFlags = features.feature_flags;
      this.featureFlagsLoading = false;
    }, (err:object) => {
      console.error('FeatureFlagModal | _account.getAllAccountFeatures failed',err);
      this.featureFlagsError = true;
      this.featureFlagsLoading = false;
    });
  }

  public addAccountFeatureFlag(featureKey):void {
    this._account.addAccountFeature(featureKey).subscribe(() => {
      this.dialogRef.close();

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Feature Flags Added',
        text: 'The feature flag was added.'
      });
    }, (err:object) => {
      console.error('FeatureFlagModal | addAccountFeature failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not add the new feature flag.'
      });
    });
  }

  public deleteFeatureFlag(featureKey):void {
    this._account.deleteAccountFeature(featureKey).subscribe(() => {
      this.getAccountFeatureFlags();

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Feature Flag Deleted',
        text: 'The feature flag was successfully deleted.'
      });
    }, (err:object) => {
      console.error('FeatureFlagModal | deleteFeatureFlag failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not delete the feature flag.'
      });
    });
  }

  public addFeatureFlag():void {
    this._account.addAccountFeature(this.newFeatureFlagKeyFC.value).subscribe((featureFlags) => {
      this.featureFlags = featureFlags.feature_flags;
      this.hideNewFeatureFlagDialog();
      this.newFeatureFlagKeyFC.reset();
    }, err => {
      console.error('FeatureFlagModal | addFeatureFlag failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not add the feature flag.'
      });
    });
  }

  public showNewFeatureFlagDialog():void {
    this.addNewFeatureFlagDialog = true;
  }

  public hideNewFeatureFlagDialog():void {
    this.addNewFeatureFlagDialog = false;
  }

  public cancel():void {
    this.dialogRef.close();
  }
}
