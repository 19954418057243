<div class="realtimeRangeModal">
  <header>
    <h3>Customize real-time range</h3>
  </header>

  <form>
    <section>
        <div class="inputs">
          <div class="tonicFormField realtimeValue">
            <mat-form-field appearance="outline" class="value">
              <input matInput [formControl]="valueFC" maxlength="4" name="rangeValue">
            </mat-form-field>
          </div>

          <div class="tonicFormField realtimeUnit">
            <mat-form-field appearance="outline" class="tonicFormField range">
              <mat-select [(ngModel)]="rangeUnit" name="rangeUnit">
                <mat-option [value]="'hours'">Hours</mat-option>
                <mat-option [value]="'days'">Days</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-error *ngIf="valueFC.invalid">Required and must contain only numbers.</mat-error>
    </section>

    <footer>
      <button type="submit" mat-button class="button button--primary" (click)="setRange()" [disabled]="valueFC.invalid">Set</button>
      <button type="button" mat-button class="button button--secondary" (click)="cancel()">Cancel</button>
    </footer>
  </form>
</div>
