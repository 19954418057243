import { Injectable } from '@angular/core';
import { DispatcherParamsStoreService } from '@trend-core/dispatcher/dispatcher-params-store.service';
import { DispatcherServiceFactory } from '@trend-core/dispatcher/dispatcher.service.factory';

import { FilterListService } from './filter-list.service';

@Injectable()
export class FilterListServiceFactory extends DispatcherServiceFactory {
  constructor(public dispatcherParamsStoreService:DispatcherParamsStoreService) {
    super(dispatcherParamsStoreService);
  }

  public new(service:any, filterSetName:string, mediatorName:string):any {
    let filterService = new FilterListService();
    filterService.setFilterSet(filterSetName);

    return super.new(service, filterSetName, mediatorName, filterService);
  }
}
