import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { timer, Subscription } from 'rxjs';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';

@Injectable()
export class PollingService implements DispatcherInterface {
  private pollingSubject = new Subject<any>();
  private isOn:boolean = true;

  public updateSubject = this.pollingSubject;
  /* tslint:disable */
  private pollingSub:Subscription;
  /* tslint:enable */

  constructor() {
    this.sub(10000);
  }

  public isPollingOn():boolean {
    return this.isOn;
  }

  public toggle(isOn:boolean){
    this.isOn = isOn;
  }

  public update():any {
    if(this.isOn) this.pollingSubject.next();
  }

  public unsub():void {
    this.pollingSub.unsubscribe();
  }

  public sub(initialDelay:number = 0):void {
    this.pollingSub = timer(initialDelay, 10000).subscribe(() => {
      this.update();
    });
  }

  public getSub():Subscription {
    return this.pollingSub;
  }

  public getDefaults():any {
    return null;
  }

  public onUpdate():any {
    return this.onPolling;
  }

  public onPolling(): Observable<any> {
    return this.pollingSubject.asObservable();
  }
}
