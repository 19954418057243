import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

import { TelemetryService } from '@trend-core/trend/telemetry.service';
// @ts-ignore
import ApexCharts from 'apexcharts';


@Component({
  selector: 'telemetry-chart-drilldown-modal',
  templateUrl: 'telemetry-chart-drilldown-modal.html',
  styleUrls: ['telemetry-chart-drilldown-modal.scss']
})
export class TelemetryChartDrilldownModal {
  public modalTitleChartType:string;
  public modalTitleChartTimeframe:string;
  public chartData:object[];
  public chartIsReady:boolean = false;
  public chartError:boolean = false;
  public chartWidth:number;
  private _originalChartData:object[];

  constructor(
    public dialogRef: MatDialogRef<TelemetryChartDrilldownModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private telemetryService:TelemetryService
  ) {}

  ngOnInit():void {
    if(this.modalData && this.modalData['groupId'] && this.modalData['timeframe']) {
      this.modalTitleChartType = this.modalData['chartHeader'];
      this.modalTitleChartTimeframe = moment(this.modalData['timeframe']['from']).format('MM/DD/YYYY, H:mm:ss');
      this._loadChartData();
    }
  }

  private _loadChartData():void {
    this.telemetryService.getHooksPerStat(this.modalData['groupId'], this.modalData['timeframe']).subscribe(data => {
      let chartData = this.telemetryService.formatPluginsDataForStackedChart(data, this.nsToMs);
      this._originalChartData = JSON.parse(JSON.stringify(chartData));
      this.chartWidth = chartData['timestamps'].length > 200 ? chartData['timestamps'].length * 20 : undefined;

      var options = {
        chart: {
          height: window.innerHeight - 100 - 90,
          type: "line",
          stacked: true,
          animations: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: false
        },
        colors: ['#00A185', '#FDCA0A', '#AC63CA', '#0F6', '#FA532C', '#4990e2', '#665889', '#DB6A04', '#466CA4', '#399360', '#DB2D1C'],
        series: chartData['series'],
        stroke: {
          width: [4, 4, 4, 4, 4, 2]
        },
        plotOptions: {
          bar: {
            columnWidth: "10"
          }
        },
        xaxis: {
          categories: chartData['timestamps'],
          labels: {
            show: false
          }
        },
        yaxis: [
          {
            seriesName: 'Plugins time (ms)',
            show: true,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: false,
            },
            title: {
              text: 'Plugins time (ms)',
              show: true
            },
            labels: {
              style: {
                fontSize: '14px'
              }
            }
          }
        ],
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
            format: 'dd MMM'
          },
          custom: ({series, seriesIndex, dataPointIndex, w}) => {
            return this.buildTooltipHtml(w, dataPointIndex, this._originalChartData, chartData['timestamps'], chartData['hooksSeries'], chartData['luaSeries']);
          },
          fixed: {
            enabled: false
          }
        },
        legend: {
          horizontalAlign: "left",
          offsetX: 40
        }
      };

      var chart = new ApexCharts(document.querySelector("#chart"), options);

      chart.render();

      this.chartIsReady = true;
    }, (err:object) => {
      console.error('',err);
      this.chartError = true;
    });
  }

  public cancel():void {
    this.dialogRef.close();
  }

  public nsToMs(nsValue:number) {
    return parseFloat((nsValue * 0.000001).toFixed(2));
  }

  public buildTooltipHtml(chart, index:number, chartData, timestamps, hooksSeries, luaSeries):string {
    let templateBase:string = `
      <div class="pluginsTooltip">
        <header>
          <div class="seriesEntry">
            <div class="seriesEntry__label">
              <span class="seriesDot" style="background-color: ` + chart['config']['colors'][chart['config']['series'].length - 1] +`;"></span>
              <span>` + chartData['series'][chartData['series'].length - 1]['name'] + `</span>
            </div>

            <div class="seriesEntry__value">
              <span style="color: ` + chart['config']['colors'][chartData['series'].length - 1] + `">` + chartData['series'][chartData['series'].length - 1]['data'][index] + `ms</span>
            </div>
          </div>
        </header>

        <div class="pluginsTooltip__content">`;

    let templatePluginsTime:number = 0;
    let templatePlugins:string = '<section class="pluginsTooltip__section pluginsTooltip__section--plugins"><h3>Transaction Plugins<span>time (ms) | count</span></h3>';
    for(var i=0; i<chartData['series'].length; i++) {
      if(chartData['series'].length - 1 === i) break; // Dont include total transaction time. (always last)
      templatePluginsTime += chartData['series'][i]['data'][index];

      templatePlugins +=
        `<div class="seriesEntry">
           <div class="seriesEntry__label">
             <span class="seriesDot" style="background-color: ` + chart['config']['colors'][i] +`;"></span>
             <span>` + chartData['series'][i]['name'] + `</span>
           </div>

           <div class="seriesEntry__value">
             <span style="color: ` + chart['config']['colors'][i] + `">` + chartData['series'][i]['data'][index] + `</span><span class="sep">|</span><span>` + chartData['series'][i]['count'][index] + `</span>
           </div>
        </div>`;
    }
    templatePlugins += `
      <div class="seriesEntry seriesEntry--total">
         <div class="seriesEntry__label">
           <span>Total plugins time: </span>
         </div>

         <div class="seriesEntry__value">
           <span>` + templatePluginsTime.toFixed(2) + `ms</span>
         </div>
      </div>
    `;
    templatePlugins += '</section>';

    let templateHooksTime:number = 0;
    let templateHooks:string = '<section class="pluginsTooltip__section pluginsTooltip__section--hooks"><h3>Transaction Hooks Timings<span>time (ms) | count</span></h3>';
    for(let key in hooksSeries['time']) {
      if(hooksSeries['count'][key][index] === 0) continue;
      templateHooksTime += hooksSeries['time'][key][index];
      templateHooks +=
        `<div class="seriesEntry">
          <div class="seriesEntry__label">
            <span class="">` + key + `</span>
          </div>

          <div class="seriesEntry__value">
            <span class="">` + hooksSeries['time'][key][index] + `</span><span class="sep">|</span><span>` + hooksSeries['count'][key][index] + `</span>
          </div>
        </div>`;
    }
    if(templateHooksTime > 0) {
      templateHooks += `
        <div class="seriesEntry seriesEntry--total">
           <div class="seriesEntry__label">
             <span>Total hooks time: </span>
           </div>

           <div class="seriesEntry__value">
             <span>` + templateHooksTime.toFixed(2) + `ms</span>
           </div>
        </div>
      `;
    } else {
      templateHooks += `
        <div class="emptySection">
          No hooks information
        </div>
      `;
    }
    templateHooks += '</section>';

    let templateLua:string = '';
    if(Object.keys(luaSeries['time']).length > 0) {
      templateLua += '<section class="pluginsTooltip__section pluginsTooltip__section--hooks"><h3>Transaction Lua Timings<span>time (ms) | count</span></h3>';
      for(let key in luaSeries['time']) {
        if(luaSeries['count'][key][index] === 0) continue;
        templateLua +=
          `<div class="seriesEntry">
            <div class="seriesEntry__label">
              <span class="">` + key + `</span>
            </div>

            <div class="seriesEntry__value">
              <span class="">` + luaSeries['time'][key][index] + `</span><span class="sep">|</span><span>` + luaSeries['count'][key][index] + `</span>
            </div>
          </div>`;
      }
      templateLua += '</section>';
    }

    let templateEnd:string = `</div></div>`;
    return templateBase + templatePlugins + templateHooks + templateLua + templateEnd;
  }
}
