<button (click)="openAddPendingUserModal()"
        mat-raised-button color="primary"
        style="margin-left: 15px; margin-top: 15px; margin-bottom: 15px;">
  <span *ngIf="totalUserCount == 0">CREATE ACCOUNT ADMIN USER</span>
  <span *ngIf="totalUserCount > 0">ADD USER TO ACCOUNT</span>
</button>

<mat-tab-group (selectedTabChange)="onTabChange()">
  <mat-tab label="Verified Users">
    <div class="tableWrapper tableWrapper--hasDrawer">
      <data-table
        id="verified-users-table"
        [_mediator]="accountVerifiedUsersTableMediator"
        [_onSelect]="onVerifiedUsersTableSelectRow"
        [_searchConfig]="[ 'email', 'first_name', 'last_name' ]"
        [_searchPlaceholder]="'Search Users'">
      </data-table>
      <drawer
        [_targetEl]="'verified-users-table'"
        [_mediator]="verifiedUserDetailsMediator"
        [_uuidProp]="'user_id'">
      </drawer>
    </div>
  </mat-tab>
  <mat-tab label="Pending Users">
    <div class="tableWrapper tableWrapper--hasDrawer">
      <data-table
        id="pending-users-table"
        [_mediator]="accountPendingUsersTableMediator"
        [_onSelect]="onPendingUsersTableSelectRow"
        [_searchConfig]="['email']"
        [_searchPlaceholder]="'Search Users'">
      </data-table>
      <drawer
        [_targetEl]="'pending-users-table'"
        [_mediator]="pendingUserDetailsMediator"
        [_uuidProp]="'user_id'">
      </drawer>
    </div>
  </mat-tab>
</mat-tab-group>
