import { Injectable } from "@angular/core";
import { Observable, Subscription, Subscriber } from 'rxjs';
import * as moment from 'moment';

import { BucketedEntry } from '../bucketed-entry-t';

import { WindowService } from '../trend/window';

@Injectable()
export class AdapterService {

  constructor(public windowService:WindowService){}

  /*
    Default buildBucketedEntry
    Invoked by mediator algo functions to build a range of data using a bucket of data populated prior
    params: entry, object of the current entry to be filled up by the
            bucket, list of entries to be injected into the current entry
            xProp, current x domain property name
            yProp, current y domain property name
  */
  buildBucketedEntry(entry:object, bucket:any[], xProp?:string, yProp?:string | string[]):BucketedEntry {
    let bucketedEntry:BucketedEntry, xVal, yVal;

    //set dynamic date range text
    if(xProp && xProp === "timestamp"){
      let _d1 = new Date(bucket[0].timestamp);
      let _d2 = new Date(entry['timestamp']);

      //if bucket had only 1, it means there should only be 2 day buckets
      if(bucket.length > 1){
        xVal = String(moment(_d1).format('MMM DD')) + " to " + String(moment(_d2).format('DD/YY'));
        xVal = this.getDateRangeString(_d1, _d2, "to", false);
      } else {
        // xVal = String(moment(_d1).format('MMM DD')) + " and " + String(moment(new Date(entry[xProp])).format('DD/YY'));
        xVal = this.getDateRangeString(_d1, entry[xProp], "and", false);
      }

    //if not using timestamp, just throw everything into the xseries specified
    } else {
      xVal = entry[xProp];
    }

    //tally up bucket values
    for(let b = 0; b < bucket.length; b++){
      let bucketItem = bucket[b];

      if(yProp instanceof Array) {
        for(var prop=0;prop<yProp.length; prop++) {
          for(let bi in bucketItem[yProp[prop]]){
            if(!entry[yProp[prop]][bi]){
              entry[yProp[prop]][bi] = bucketItem[yProp[prop]][bi];
            } else {
              entry[yProp[prop]][bi] = entry[yProp[prop]][bi] + bucketItem[yProp[prop]][bi];
            }
          }
        }
      } else {
        for(let bi in bucketItem[yProp]){
          if(!entry[yProp][bi]){
            entry[yProp][bi] = bucketItem[yProp][bi];
          } else {
            entry[yProp][bi] = entry[yProp][bi] + bucketItem[yProp][bi];
          }
        }
      }
    }

    bucketedEntry = {
      entry: entry,
      xVal: xVal,
      yVal: yVal
    };

    return bucketedEntry;
  }

  /*
    Default getStep
    This method is used to decide if we need to bucketize the dataset or not
    It's invoked from specific mediators in their algos and can be overwritten to
    use a different metric for decided what the limit on data should be

    In a case where bucketing is needed, step must be anything above (rounded) 1

    params: data, list of data, just for lenght in the default scenario
  */
  getStep(data:any[]):number {
    let barWidth = 10;
    let chartWidth = this.windowService.getWindow().innerWidth - 600;
    let step:number = Math.ceil((data.length * barWidth) / chartWidth);

    return step;
  }

  /*
    Default getRegularDateString
    Invoked inside of algos in mediators to build strings of dates for a single day or hour
    params: timestamp string or date object, gets passed to native JS to build date
  */
  getRegularDateString(timestamp:any, hours:boolean):string {
    //hourly representation is more accurate using local while the daily is more accurate using utc
    let date = hours ? moment(timestamp).utcOffset(moment().utcOffset()) : moment.utc(timestamp),
        format = hours ? 'HH:00' : 'ddd MMM DD',
        string = String(date.format(format));

    return string;
  }

  /*
    Default getDateRangeString
    Invoked inside of algos or bucketing to build strings of date ranges using a specific splitter text
    params: fromTimestamp and toTimestamp, date object, gets passed to native JS to build date
  */
  getDateRangeString(fromTimestamp:Date, toTimestamp:Date, splitter:string, hours:boolean):string {
    let fromFormat = hours ? 'HH:00' : 'MMM DD',
        toFormat = hours ? 'HH:00' : 'DD/YY',

        fromString = String(moment.utc(fromTimestamp).format(fromFormat)),
        toString = String(moment.utc(toTimestamp).format(toFormat)),

        string = fromString + " " + splitter + " " + toString;

    return string;
  }
}
