import { Injectable } from '@angular/core';

@Injectable()
export class PagesParamsStore  {

  params:any;

  constructor(){}

  getParams():any {
    return this.params;
  }

  setParams(value:any) {
    this.params = value;
  }
}
