import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AgentService } from '@trend-core/trend/agent.service';


@Component({
  selector: 'vm-data',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'vm-data.html',
  styleUrls: ['vm-data.scss']
})
export class VmData {
  public vmData:object;
  public vmDataLoaded:boolean = false;
  public vmDataError:boolean = false;

  constructor(
    private agentService:AgentService,
    private route:ActivatedRoute
  ) {}

  ngOnInit():void {
    this.agentService.fetchGroupVmData(this.route.snapshot.parent.params.group_id).subscribe(vmData => {
      this.vmData = vmData;
      this.vmDataLoaded = true;
    }, (err:object) => {
      this.vmData = {};
      this.vmDataError = true;
      console.error('GroupDetailsDrawer | couldnt retreive group vmData', err)
    });
  }
}
