import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';
import * as moment from 'moment';
import { TimeframeConfig } from '@trend-core/timeframe-config-t';
import { PollingService } from '../polling.service';

import { DispatcherParamsStoreService } from '@trend-core/dispatcher/dispatcher-params-store.service';

@Injectable()
export class TimeframeService implements DispatcherInterface {
  constructor(private dispatcherParamsStoreService:DispatcherParamsStoreService,
              private pollingService:PollingService) {}

  private forcedTimeframeUpdateSubject = new Subject<any>();
  private triggered:boolean = false;

  updateSubject = new Subject<any>();
  hideSubject = new Subject<any>();
  showSubject = new Subject<any>();

  defaultRealtimeConfig:TimeframeConfig = {
    value: 7,
    unit: 'days',
    rangeType: 'week',
    from: moment().subtract(1, 'week').utcOffset(moment().utcOffset()).format(),
    to: moment().utcOffset(moment().utcOffset()).format(),
    isRealtime: true
  };

  defaultStaticTimeConfig:TimeframeConfig = {
    value: 7,
    unit: 'days',
    rangeType: 'week',
    from: moment().subtract(1, 'week').utcOffset(moment().utcOffset()).format(),
    to: moment().utcOffset(moment().utcOffset()).format(),
    isRealtime: false
  }

  private timeframe:TimeframeConfig = this.defaultRealtimeConfig;

  getDefaults():Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.getTimeframe());
    });
  }

  getTimeframe():TimeframeConfig {
    return this.timeframe;
  }

  setTimeframe(timeframe:TimeframeConfig):void {
    this.timeframe = timeframe;
  }

  setTimeframeTo(to):void {
    this.timeframe.to = to;
  }

  setTimeframeFrom(from):void {
    this.timeframe.from = moment(from).subtract(this.timeframe.value, this.timeframe.unit).format();
  }

  formatRangeForDisplay(dateRange: object):string {
    let formattedFrom: string = moment(dateRange['from']).format('MMMM Do YYYY');
    let formattedTo: string = moment(dateRange['to']).format('MMMM Do YYYY');

    return formattedFrom + ' to ' + formattedTo;
  }

  formatDateForDisplay(date:string):string {
    return moment(date).format('MMMM Do YYYY');
  }

  getRealtime():boolean {
    return !this.dispatcherParamsStoreService.hasDefaults();
  }

  update(payload: TimeframeConfig):void {
    return this.updateTimeframe(payload);
  }

  //this method is what is invoked after fetches are completed
  updateTimeframe(payload: TimeframeConfig, forced?:boolean) {
    this.timeframe = payload;
    if(forced) {
      this.timeframe['forced'] = true;
    } else if(!forced && this.timeframe.hasOwnProperty('forced')) {
      delete this.timeframe['forced'];
    }
    this.updateSubject.next(this.timeframe);
  }

  //this method is invoked by other places and subsequently invokes the regular update method above
  forceTimeframeUpdate(payload: TimeframeConfig, rangeType?: string, forceRealtime?:boolean) {
    this.timeframe = payload;
    this.togglePolling(payload.isRealtime)
    this.forcedTimeframeUpdateSubject.next(payload);
  }

  setDefault(payload: TimeframeConfig):void {
    this.timeframe = payload;
  }

  resetTimeframe():void {
    this.timeframe = Object.assign({}, this.defaultRealtimeConfig);
  }

  softTrigger():void {
    if(this.triggered === false){
      this.triggered = true;
      this.updateSubject.next(this.timeframe);
    }
  }

  hardTrigger():void {
    timer(500).subscribe(() => {
      this.updateSubject.next(this.timeframe);
    });
  }

  onUpdate():Observable<any> {
    return this.updateSubject.asObservable();
  }

  onTimeframeUpdate(): Observable<any> {
    return this.updateSubject.asObservable();
  }

  onForcedTimeframeUpdate(): Observable<any> {
    return this.forcedTimeframeUpdateSubject.asObservable();
  }

  hideTimeframe():void {
    this.hideSubject.next();
  }

  onHide():Observable<any> {
    return this.hideSubject.asObservable();
  }

  showTimeframe():void {
    this.showSubject.next();
  }

  onShow():Observable<any> {
    return this.showSubject.asObservable();
  }

  togglePolling(isOn:boolean):void {
    this.pollingService.toggle(isOn);
  }
}
