import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { External } from '../common/external/external';
import { Dashboard } from '../common/dashboard/dashboard';

import { AuthService } from '@trend-common/auth/auth.service';

// Pages
import { LoginPage } from '../pages/login/login';
import { UsersPage } from '../pages/users/users';
import { AccountsPage } from '../pages/accounts/accounts';
import { AccountsList } from '../pages/accounts/accounts-list/accounts-list';
import { AccountsTelemetry } from '../pages/accounts/accounts-telemetry/accounts-telemetry';
import { AccountPage } from '../pages/account/account';
import { AccountGroups } from '../pages/account/groups/groups';
import { AccountGroup } from '../pages/account/group/group';
import { GroupTelemetry } from '../pages/account/group/group-telemetry/group-telemetry';
import { GroupLibraries } from '../pages/account/group/group-libraries/group-libraries';
import { GroupAgentEnvironments } from '../pages/account/group/group-agent-environments/group-agent-environments';
import { VmData } from '../pages/account/group/vm-data/vm-data';
import { AccountUsers } from '../pages/account/users/users';
import { AccountTelemetry } from '../pages/account/account-telemetry/account-telemetry';
import { AgentsPage } from '../pages/agents/agents';

export const routes: Routes = [
  { path: '', redirectTo: 'accounts', pathMatch: 'full' },
  {
    path: '',
    component: External,
    children: [
      { path: 'login', component: LoginPage }
    ]
  },
  {
    path: '',
    component: Dashboard,
    children: [
      { path: 'users', component: UsersPage, canActivate: [ AuthService ]},
      { path: 'accounts', component: AccountsPage, canActivate: [ AuthService ], children: [
        { path: '', redirectTo: 'list', pathMatch: 'full' },
        { path: 'list', component: AccountsList },
        { path: 'telemetry', component: AccountsTelemetry },
      ]},
      { path: 'accounts/:account_id',
        component: AccountPage,
        children: [
          { path: '', redirectTo: 'groups', pathMatch: 'full' },
          { path: 'groups', component: AccountGroups },
          { path: 'users', component: AccountUsers },
          { path: 'telemetry', component: AccountTelemetry }
        ]
      },
      { path: 'accounts/:account_id/groups/:group_id', component: AccountGroup, canActivate: [ AuthService ], children: [
        { path: '', redirectTo: 'groups', pathMatch: 'full' },
        { path: 'telemetry', component: GroupTelemetry },
        { path: 'vmdata', component: VmData },
        { path: 'libraries', component: GroupLibraries },
        { path: 'agent-environments', component: GroupAgentEnvironments },
      ] },
      { path: 'agents', component: AgentsPage, canActivate: [ AuthService ] },
      { path: '**', redirectTo: '/accounts' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
