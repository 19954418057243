import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import {
  TREND_COLORS,
  TREND_DARK_TEXT_COLORS,
  TREND_LIGHT_TEXT_COLORS,
  TREND_DARK_FUNCTIONAL_COLORS,
  TREND_LIGHT_FUNCTIONAL_COLORS
} from './colors';

@Injectable()
export class CssVariablesService {
  private _varsUpdateSub = new Subject();
  private _DARK_THEME:any = new Map([
    // LAYOUT
    ['layout_base_padding', '20px'],
    ['layout_main_menu_width', '64px'],
    ['layout_group_menu_width', '260px'],
    ['layout_group_menu_item_height', '60px'],
    ['layout_header_height', window['INTERNAL_SHELL'] ? '60px' : '40px'],
    ['layout_footer_height','32px'],
    // COLORS
    ['color_brand_primary', TREND_COLORS['trend:red']],
    ['color_brand_secondary', TREND_COLORS['trend:grey']],
    ['color_secondary', TREND_COLORS['blue:60']],
    ['color_secondary_hover', TREND_COLORS['blue:50']],
    ['color_main_background', TREND_COLORS['gray:100']],
    ['color_secondary_background', TREND_COLORS['gray:90']],
    ['color_tertiary_title_background', TREND_COLORS['gray:85']],
    ['color_tertiary_background', TREND_COLORS['gray:70']],
    ['color_quaternary_background', TREND_COLORS['gray:60']],
    ['color_menu_border', TREND_COLORS['gray:85']],
    ['color_main_border', TREND_COLORS['gray:70']],
    ['color_modal_header_border', TREND_COLORS['gray:60']],
    ['color_filters_odd', TREND_COLORS['gray:85']],
    ['color_filters_hover', TREND_COLORS['gray:80']],
    // STATUS COLORS
    ['color_high_risk_background', TREND_DARK_FUNCTIONAL_COLORS['risk_high']],
    ['color_medium_risk_background', TREND_DARK_FUNCTIONAL_COLORS['risk_medium']],
    ['color_low_risk_light_background', '#fce2a9'],
    ['color_low_risk_background', TREND_DARK_FUNCTIONAL_COLORS['risk_low']],
    ['color_safe_risk_background', TREND_DARK_FUNCTIONAL_COLORS['risk_safe']],
    ['color_information_background', TREND_DARK_FUNCTIONAL_COLORS['risk_information']],
    ['color_risk_unknown_background', TREND_DARK_FUNCTIONAL_COLORS['risk_unknown']],
    ['color_loading_yellow', '#FAFAD6'],
    // TEXT
    ['text_base_color', TREND_DARK_TEXT_COLORS['primary']],
    ['text_light_color', TREND_DARK_TEXT_COLORS['secondary']],
    ['text_dark_color', TREND_DARK_TEXT_COLORS['emphasis']],
    ['text_link', TREND_DARK_TEXT_COLORS['link']],
    ['text_error', TREND_DARK_TEXT_COLORS['error']],
    ['text_warning', TREND_DARK_TEXT_COLORS['warning']],
    // GENERAL
    ['border_radius', '3px'],
    // FORMS
    ['color_form_border', TREND_COLORS['gray:60']],
    // MENU
    ['color_header_background', TREND_COLORS['gray:100']],
    ['color_menu_shadow', TREND_COLORS['gray:100']],
    ['menu_active_item_background_color', TREND_COLORS['gray:80']],
    ['menu_hover_item_background_color', TREND_COLORS['gray:90']],
    ['menu_group_item_border_color', TREND_COLORS['gray:90']],
    // TABLE
    ['color_loading_background', TREND_DARK_FUNCTIONAL_COLORS['table_loading']],
    ['color_loading_text', TREND_DARK_FUNCTIONAL_COLORS['gray:70']],
    // CALENDAR
    ['color_disabled_date', TREND_COLORS['gray:50']],
    // DRAWER
    ['color_drawer_background', TREND_COLORS['gray:90']],
  ]);

  private _LIGHT_THEME:any = new Map([
    // LAYOUT
    ['layout_base_padding', '20px'],
    ['layout_main_menu_width', '64px'],
    ['layout_group_menu_width', '260px'],
    ['layout_header_height', window['INTERNAL_SHELL'] ? '60px' : '40px'],
    ['layout_footer_height', '32px'],
    // COLORS
    ['color_brand_primary', TREND_COLORS['trend:red']],
    ['color_brand_secondary', TREND_COLORS['trend:grey']],
    ['color_secondary', TREND_COLORS['blue:50']],
    ['color_secondary_hover', TREND_COLORS['blue:60']],
    ['color_main_background', '#FFF'],
    ['color_secondary_background', '#FAFAFA'],
    ['color_tertiary_title_background', TREND_COLORS['gray:10']],
    ['color_tertiary_background', TREND_COLORS['gray:20']],
    ['color_quaternary_background', TREND_COLORS['gray:40']],
    ['color_menu_border', TREND_COLORS['gray:30']],
    ['color_main_border', TREND_COLORS['gray:20']],
    ['color_modal_header_border', TREND_COLORS['gray:30']],
    ['color_filters_odd', TREND_COLORS['gray:10']],
    ['color_filters_hover', TREND_COLORS['gray:20']],
    // STATUS COLORS
    ['color_high_risk_background', TREND_LIGHT_FUNCTIONAL_COLORS['risk_high']],
    ['color_high_risk_light_background', TREND_COLORS['red:10']],
    ['color_medium_risk_background', TREND_LIGHT_FUNCTIONAL_COLORS['risk_medium']],
    ['color_low_risk_light_background', '#fce2a9'],
    ['color_low_risk_background', TREND_LIGHT_FUNCTIONAL_COLORS['risk_low']],
    ['color_safe_risk_background', TREND_LIGHT_FUNCTIONAL_COLORS['risk_safe']],
    ['color_information_background', TREND_LIGHT_FUNCTIONAL_COLORS['risk_information']],
    ['color_risk_unknown_background', TREND_LIGHT_FUNCTIONAL_COLORS['risk_unknown']],
    // TEXT
    ['text_base_color', TREND_LIGHT_TEXT_COLORS['primary']],
    ['text_light_color', TREND_LIGHT_TEXT_COLORS['secondary']],
    ['text_dark_color', TREND_LIGHT_TEXT_COLORS['emphasis']],
    ['text_link', TREND_LIGHT_TEXT_COLORS['link']],
    ['text_error', TREND_LIGHT_TEXT_COLORS['error']],
    ['text_warning', TREND_LIGHT_TEXT_COLORS['warning']],
    // GENERAL
    ['border_radius', '3px'],
    // FORMS
    ['color_form_border', TREND_COLORS['gray:40']],
    // MENU
    ['color_header_background', '#FFF'],
    ['color_menu_shadow', '#FAFAFA'],
    ['menu_active_item_background_color', TREND_COLORS['gray:20']],
    ['menu_hover_item_background_color', TREND_COLORS['gray:10']],
    ['menu_group_item_border_color', TREND_COLORS['gray:30']],
    // TABLE
    ['color_loading_background', TREND_LIGHT_FUNCTIONAL_COLORS['table_loading']],
    ['color_loading_text', TREND_DARK_FUNCTIONAL_COLORS['gray:70']],
    // CALENDAR
    ['color_disabled_date', TREND_COLORS['gray:50']],
    // DRAWER
    ['color_drawer_background', '#FFF'],
  ]);

  private _themeNames:string[] = ['dark-theme', 'light-theme'];
  private _activeThemeName:string = localStorage.getItem('CloudOneAppSecTheme') || 'dark-theme';
  private _activeThemeVariables = this._activeThemeName === 'light-theme' ? this._LIGHT_THEME : this._DARK_THEME;

  constructor() {}


  public getTheme() {
    return this._activeThemeVariables;
  }

  public getThemeName():string {
    return this._activeThemeName;
  }

  public setTheme(themeName?:string):void {
    let activeThemeName = themeName ? themeName : localStorage.getItem('CloudOneAppSecTheme') || 'dark-theme';
    if(activeThemeName !== 'dark-theme' && activeThemeName !== 'light-theme') activeThemeName = 'dark-theme';
    this._activeThemeName = activeThemeName;
    this._activeThemeVariables = this._activeThemeName === 'dark-theme' ? this._DARK_THEME : this._LIGHT_THEME;
    localStorage.setItem('CloudOneAppSecTheme', this._activeThemeName);
    for(var i=0; i<this._themeNames.length; i++) {
      document.body.classList.remove('_appsec_ns_'+this._themeNames[i]);
    }
    document.body.classList.add('_appsec_ns_'+this._activeThemeName);
    this.loadCssVariables();
    this._varsUpdateSub.next({ name: this._activeThemeName, vars: this._activeThemeVariables});
    if(window['MICRO_APPS_EVENT_EMITTER'] && window['MICRO_APPS_EVENT_EMITTER'].resolve) window['MICRO_APPS_EVENT_EMITTER'].resolve('themeChanged');
  }

  public toggleTheme():void {
    this._activeThemeName = this._activeThemeName === 'dark-theme' ? 'light-theme' : 'dark-theme';
    this._activeThemeVariables = this._activeThemeName === 'dark-theme' ? this._DARK_THEME : this._LIGHT_THEME;
    localStorage.setItem('CloudOneAppSecTheme', this._activeThemeName);
    for(var i=0; i<this._themeNames.length; i++) {
      document.body.classList.remove('_appsec_ns_'+this._themeNames[i]);
    }
    document.body.classList.add('_appsec_ns_'+this._activeThemeName);
    this.loadCssVariables();
    this._varsUpdateSub.next({ name: this._activeThemeName, vars: this._activeThemeVariables});
    if(window['MICRO_APPS_EVENT_EMITTER'] && window['MICRO_APPS_EVENT_EMITTER'].resolve) window['MICRO_APPS_EVENT_EMITTER'].resolve('themeChanged');
  }

  public onThemeChange():Observable<any> {
    return this._varsUpdateSub.asObservable();
  }

  public loadCssVariables():void {
    Array.from(this._activeThemeVariables.entries()).forEach(([name, value]) => {
      document.body['style'].setProperty(`--${name}`, value);
    });
  }

  public unloadCssVariables():void {
    Array.from(this._activeThemeVariables.entries()).forEach(([name, value]) => {
      document.body['style'].removeProperty(`--${name}`);
    });
    for(var i=0; i<this._themeNames.length; i++) {
      document.body.classList.remove('_appsec_ns_'+this._themeNames[i]);
    }
  }
}
