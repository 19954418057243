<h1>Cloud One Application Security Admin console</h1>

<p>To log in, please visit Trend Micro ADFS authentication service. Choose a region:</p>

<a class="regionLink" (click)="getADFSUrl('')">US-1</a>
<a class="regionLink" (click)="getADFSUrl('trend-us-1')">TREND-US-1</a>
<a *ngIf="isProd" class="regionLink" (click)="getADFSUrl('au-1')">AU-1</a>
<a *ngIf="isProd" class="regionLink" (click)="getADFSUrl('de-1')">DE-1</a>
<a *ngIf="isProd" class="regionLink" (click)="getADFSUrl('gb-1')">GB-1</a>
<a *ngIf="isProd" class="regionLink" (click)="getADFSUrl('in-1')">IN-1</a>
<a *ngIf="isProd" class="regionLink" (click)="getADFSUrl('jp-1')">JP-1</a>
<a *ngIf="isProd" class="regionLink" (click)="getADFSUrl('sg-1')">SG-1</a>
<a *ngIf="isProd" class="regionLink" (click)="getADFSUrl('ca-1')">CA-1</a>
