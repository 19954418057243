import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer} from 'rxjs';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';
import { DrawerBody } from './drawer-body-t';

/*
  This service is used for all drawer messaging and subs
*/

@Injectable()
export class DrawerService implements DispatcherInterface {

  updateSubject = new Subject<any>();
  closeSubject = new Subject<any>();

  private drawerBody:DrawerBody = {
    body: {}
  };

  private loadTimer:Observable<any> = timer(100);
  private timerSub:Subscription;
  private pairHash:string;

  constructor() {}

  getDefaults():Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.getDrawerBody());
    });
  }

  getDrawerBody():DrawerBody {
    return this.drawerBody;
  }

  getPairHash():any {
    return this.pairHash;
  }

  setDrawer(data:any):void {
    this.drawerBody = data;
  }

  //invoked by a component or class that wants to make use of the routing engine
  update(payload: object, force:boolean):void {
    //if no payload, or if there's an empty payload, just close it
    if(!payload || (payload && (Object.keys(payload).length === 0 || payload['body'] && Object.keys(payload['body']).length === 0))){
      this.closeSubject.next();
    } else {
      this.timerSub = this.loadTimer.subscribe(()=>{
        this.setDrawer(payload);
        this.updateSubject.next(payload);

        this.timerSub.unsubscribe();
      });
    }
  }

  //invoked by a component/class that wants to force data into the drawer without invoking the routing engine
  forceUpdateBodyInto(payload: object, componentToInject:any):void {
    if(!payload || (payload && (Object.keys(payload).length === 0 || payload['body'] && Object.keys(payload['body']).length === 0))){
      this.closeSubject.next();
    } else {
      this.timerSub = this.loadTimer.subscribe(()=>{
        this.setDrawer(payload);

        let forcedPayload = {
          data: payload,
          forced: true,
          component: componentToInject
        };

        this.updateSubject.next(forcedPayload);

        this.timerSub.unsubscribe();
      });
    }
  }

  //used when instance created by factory for mediator/dispatcher pair
  setPairHash(pairHash:string):void {
    this.pairHash = pairHash;
  }

  close():void {
    this.update({body:{}}, true);
    this.closeSubject.next();
  }

  onUpdate():Observable<any> {
    return this.updateSubject.asObservable();
  }

  onClose():Observable<any> {
    return this.closeSubject.asObservable();
  }
}
