<!-- <h1 class="pageTitle">{{ title }}</h1> -->

<header>
  <section class="summary">
    <h3>Summary</h3>
    <span *ngIf="accountIsLoading">Loading account details...</span>

    <table *ngIf="!accountIsLoading" class="infoTable">
      <tr>
        <td class="infoTable__key">Tenant Name</td>
        <!-- <td class="infoTable__value">{{ currentAccount.tenant_name }}</td> -->
        <td class="infoTable__value">
          <div *ngIf="!isChangingAccountName">
            <span>{{ currentAccount.tenant_name }}</span>
            <a class="accountNameChangeTrigger" (click)="showChangeAccountNameInput()">Change</a>
          </div>
          <div *ngIf="isChangingAccountName" class="accountName">
            <form [formGroup]="accountNameForm" autocomplete="off">
              <mat-form-field>
                <mat-label>Tenant Name</mat-label>
                <input id="new_user_email" name="new_user_email" value="{{currentAccount.tenant_name}}" formControlName="account_name" matInput required>
              </mat-form-field>
              <button (click)="changeAccountName()" mat-flat-button color="primary" type="button" name="change_utton">Save</button>
              <button (click)="cancelChangeAccountNameInput()" mat-flat-button type="button" name="cancel_change_utton">Cancel</button>
            </form>
          </div>
        </td>
      </tr>
      <tr>
        <td class="infoTable__key">Creation Date</td>
        <td class="infoTable__value">{{ currentAccount.created_on | timezonedDate }}</td>
      </tr>
      <tr>
        <td class="infoTable__key">Account ID</td>
        <td class="infoTable__value">{{ currentAccount.account_id }}</td>
      </tr>
      <tr>
        <td class="infoTable__key">Source</td>
        <td class="infoTable__value">{{ currentAccount.source }}</td>
      </tr>
      <tr>
        <td class="infoTable__key">Cloud One Acount ID</td>
        <td class="infoTable__value">{{ currentAccount.cloud_one_account_id || 'N/A' }}</td>
      </tr>
    </table>
  </section>

  <section class="billing">
    <h3>Billing</h3>
    <div *ngIf="currentAccountUsageLoaded && !currentAccountUsageError" class="">
      <div class="statsBox">
        <h4>Subscribed Licenses</h4>
        <span>{{ currentAccountUsage['licenses'] || 'Not set yet' }}</span>
      </div>
      <div class="statsBox">
        <h4>Last updated</h4>
        <small *ngIf="!currentAccountUsageNotSet">{{ currentAccountUsage['updatedOn'] | timezonedDate }}</small>
        <small *ngIf="currentAccountUsageNotSet">Not set yet</small>
      </div>
      <button (click)="changeSubscription()" mat-stroked-button color="primary">{{ currentAccountUsageNotSet ? 'Set Subscription' : 'Change subscription' }}</button>
    </div>
    <div *ngIf="!currentAccountUsageLoaded" class="">
      <span>Loading account subscription...</span>
    </div>
    <div *ngIf="currentAccountUsageError" class="">
      <span>Cannot get the account's billing subscription at the moment.</span>
    </div>
  </section>

  <section class="impersonation">
    <h3>Impersonation</h3>
    <span>Open the Application Security dashboard with this account's data.</span>
    <div class="impersonationButtons">
      <div class="">
        <button (click)="impersonateAccount('read-only')" mat-stroked-button color="primary">Read-only mode</button>
        <button (click)="impersonateAccount('full')" mat-stroked-button color="primary">Write mode</button>
      </div>
      <div class="">
        <button *ngIf="redImpersonate" (click)="impersonateAccount('read-only', true)" mat-stroked-button color="primary">RED | Read-only mode</button>
        <button *ngIf="redImpersonate" (click)="impersonateAccount('full', true)" mat-stroked-button color="primary">RED | Write mode</button>
      </div>
    </div>
  </section>

  <section class="misc">
    <h3>Account Settings</h3>
    <div class="">
      <button (click)="openAccountFeatureFlagsModal()" mat-stroked-button color="primary">Change Feature Flags</button>
    </div>
    <div class="">
      <button *ngIf="canDelete" class="deleteAccount" (click)="openDeleteAccountModal()" mat-flat-button color="warn">Delete Account</button>
    </div>
  </section>
</header>

<nav mat-tab-nav-bar
     [color]="'primary'"
     [backgroundColor]="''"
     class="">
  <a mat-tab-link
     routerLink="/accounts/{{ account_id }}/groups"
     [active]="activeTab == 'groups'">
     Groups
  </a>

  <a mat-tab-link
     routerLink="/accounts/{{ account_id }}/users"
     [active]="activeTab == 'users'">
     Users
  </a>

  <a mat-tab-link
     routerLink="/accounts/{{ account_id }}/telemetry"
     [active]="activeTab == 'telemetry'">
     Telemetry
  </a>
</nav>

<router-outlet></router-outlet>
