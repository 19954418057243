<header>
  <section class="brand">
    <img src="./assets/images/trend-micro-full.png" alt="Trend Micro">
    <div class="">
      <span class="title">Cloud One - Application Security&nbsp;&nbsp;|&nbsp;&nbsp;Admin Dashboard</span>
      <span class="region region--{{region}}">{{ region }}</span>
    </div>

  </section>

  <section class="user" [ngClass]="{ 'is-empty': !userObj }" [matMenuTriggerFor]="appMenu">
    <mat-icon>account_circle</mat-icon>
    <span>{{ userObj ? userObj.first_name : "" }} {{ userObj ? userObj.last_name : "" }}</span>

    <mat-menu class="userMenu" #appMenu="matMenu">
      <button class="logoutButton" (click)="logout()" mat-menu-item>Logout</button>
    </mat-menu>
  </section>
</header>

<nav>
  <ul>
    <!-- <li>
      <button routerLink="/home" routerLinkActive="activeMenuItem" mat-button color="mainMenuItem">Overview</button>
    </li> -->
    <li>
      <button routerLink="/accounts" routerLinkActive="activeMenuItem" mat-button color="mainMenuItem">Accounts</button>
    </li>
    <li>
      <button routerLink="/users" routerLinkActive="activeMenuItem" mat-button color="mainMenuItem">Users</button>
    </li>
    <li>
      <button routerLink="/agents" routerLinkActive="activeMenuItem" mat-button color="mainMenuItem">Agents</button>
    </li>
    <li *ngIf="isSuperAdmin" (click)="openFeatureFlagsMopdal()" class="adminItem">
      <button mat-button color="mainMenuItem">Feature Flags</button>
    </li>
  </ul>
</nav>

<section>
  <!-- <page-header><page-header> -->
  <breadcrumbs></breadcrumbs>
  <router-outlet></router-outlet>
</section>

<!-- <footer>
  App Protect kontroller footer
</footer> -->

<!-- <feedback-messages></feedback-messages> -->
