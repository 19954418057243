import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APIUrlService } from '../api/api-url.service';

import { Feed } from '../feed-t';

@Injectable()
export class Feeds {
  get feeds():Feed[] {
    return this._feeds;
  }
  set feeds(val:Feed[]) {
    this._feeds = val;
  }

  private _feeds:Feed[] = [
    { key: 'tor-exit-nodes', label: 'TOR Exit Nodes' },
    { key: 'default', label: 'Default' }
  ];

  constructor() {}

  getFeeds():Feed[] {
    return this.feeds;
  }

  getFeedByName(name:string):Feed {
    for(let x = 0; x < this.feeds.length; x++){
      if(this.feeds[x]['label'] === name){
        return this.feeds[x];
      }
    }

    console.error('Feed not found by name in feed config.');
    return { key: name, label: name };
  }

  getFeedByKey(key:string):Feed {
    for(let x = 0; x < this.feeds.length; x++){
      if(this.feeds[x]['key'] === key){
        return this.feeds[x];
      }
    }

    console.error('Feed not found by key in feed config.');
    return { key: key, label: key };
  }

  getFeedNameByKey(key:string):string {
    for(var i=0; i<this._feeds.length; i++) {
      if(this._feeds[i].key === key) return this._feeds[i].label;
    }
  }
}
