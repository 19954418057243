import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { timer } from 'rxjs';

/*
  USAGE: <copy-to-clipboard srcElementId="secretElement"></copy-to-clipboard>
*/
@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['copy-to-clipboard.scss']
})
export class CopyToClipboard {
  @Input() srcElementId:string;
  @HostBinding('class.copySuccess') copiedSuccess:boolean = false;
  @HostBinding('class.copyError') copiedError:boolean = false;

  successTimer = timer(1000);

  constructor() {}

  ngOnInit():void {}

  onCopyButtonClick() {
    if(!this.srcElementId || !document.getElementById(this.srcElementId)) {
      console.error('no valid srcElementId provided to CopyToClipboard');
      this.onCopyError();
      return;
    }

    let dummyTextArea = document.createElement('textarea');
    dummyTextArea.style.position = 'absolute';
    dummyTextArea.style.top = '-99999px';
    dummyTextArea.classList.add('copyToClipboardTempTextarea');
    dummyTextArea.value = document.getElementById(this.srcElementId).innerHTML;
    document.body.appendChild(dummyTextArea);
    dummyTextArea.select();

    try {
      var copySuccess = document.execCommand('copy');

      document.body.removeChild(dummyTextArea);

      this.copiedSuccess = true;

      this.successTimer.subscribe(() => {
        this.copiedSuccess = false;
      });
    } catch (err) {
      console.error('copyToClipboard: Error when copying to clipboard.', err);

      // user feedback for error
      this.onCopyError();

      document.body.removeChild(dummyTextArea);
    }
  }

  onCopyError():void {
    this.copiedSuccess = false;
    this.copiedError = true;

    this.successTimer.subscribe(() => {
      this.copiedError = false;
    });
  }
}
