import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modal service
import { ModalService } from '@trend-common/modals/modal.service';

// Modal components
import { CreateAccountModal } from './create-account-modal/create-account-modal';
import { AddPendingUserModal } from './add-pending-user-modal/add-pending-user-modal';
import { ValidatePendingUserModal } from './validate-pending-user-modal/validate-pending-user-modal';
import { DeleteVerifiedUserModal } from './delete-verified-user-modal/delete-verified-user-modal';
import { DeletePendingUserModal } from './delete-pending-user-modal/delete-pending-user-modal';
import { TelemetryChartDrilldownModal } from './telemetry-chart-drilldown-modal/telemetry-chart-drilldown-modal';
import { ChangeAccountBillingModal } from './change-account-billing-modal/change-account-billing-modal';
import { ChangeUserRoleModal } from './change-user-role-modal/change-user-role-modal';
import { FeatureFlagModal } from './feature-flags-modal/feature-flags-modal';
import { AccountFeatureFlagModal } from './account-feature-flags-modal/account-feature-flags-modal';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';

let matModuleArray:any[] = [
  MatDialogModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatIconModule
];

let modalsArray:any[] = [
  CreateAccountModal,
  AddPendingUserModal,
  ValidatePendingUserModal,
  DeletePendingUserModal,
  DeleteVerifiedUserModal,
  TelemetryChartDrilldownModal,
  ChangeAccountBillingModal,
  ChangeUserRoleModal,
  FeatureFlagModal,
  AccountFeatureFlagModal
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    matModuleArray
  ],
  declarations: [
    modalsArray
  ],
  providers: [
    ModalService
  ],
  entryComponents: [
    modalsArray
  ],
  exports: [
    modalsArray
  ]
})
export class ModalsModule {}
