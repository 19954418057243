import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';

@Component({
  selector: 'code-block',
  templateUrl: 'code-block.html',
  styleUrls: ['code-block.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CodeBlock implements OnInit {
  @Input() _title: string;
  @Input() _codeString: string;
  @Input() _codeStringId: string;
  @Input() _includeCopyToClipboard: boolean;
  @Input() _multiLine: boolean;

  public title:string;
  public codeString:string;
  public includeCopyToClipboard:boolean;
  public codeStringId:string;
  public multiLine:boolean;

  constructor() {}

  ngOnInit():void {
    this.title = this._title;
    this.codeString = this._codeString;
    this.codeStringId = this._codeStringId;
    this.includeCopyToClipboard = this._includeCopyToClipboard;
    this.multiLine = this._multiLine;
  }
}
