import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TelemetryService } from '@trend-core/trend/telemetry.service';

import Helpers from '@trend-common/helpers/helpers';


@Component({
  selector: 'group-libraries',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'group-libraries.html',
  styleUrls: ['group-libraries.scss']
})
export class GroupLibraries {
  public libraries:object;
  public librariesReady:boolean = false;

  constructor(
    private telemetryService:TelemetryService,
    private route:ActivatedRoute
  ) {}

  ngOnInit():void {
    this.telemetryService.getGroupLibraries(this.route.snapshot.parent.params.group_id).subscribe((libraries:object) => {
      this.libraries = libraries;
      this.librariesReady = true;
    }, (err:object) => {
      this.libraries = null;
      this.librariesReady = true;
      console.error('GroupLibraries | telemetryService.getGroupLibraries failed', err);
    });
  }

  public formatTime(dateTime:string) {
    return Helpers.formatTime(dateTime);
  }
}
