import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { AuthService } from '@trend-common/auth/auth.service';
import { APIUrlService } from '@trend-core/api/api-url.service';
import { DomService } from '@trend-core/dom.service';

import { FeedbackMessages } from '@trend-common/feedback-messages/feedback-messages';

import { APIUser } from '../../core/types/api/api-user-t';
import { User } from '../../core/stores/user';
import { Account } from '../../core/stores/account';
import { ModalService } from '@trend-common/modals/modal.service';
import { FeatureFlagModal } from '../../common/modals/feature-flags-modal/feature-flags-modal';


@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.html',
  styleUrls: ['./dashboard.scss']
})
export class Dashboard {
  public userObj:APIUser;
  public region:string;
  public isSuperAdmin:boolean = false;
  private logoutTimer = timer(100);

  constructor(
    private auth: AuthService,
    private router: Router,
    private user: User,
    private account: Account,
    private domService: DomService,
    private apiUrlService: APIUrlService,
    private modalService:ModalService,
  ) {}

  ngOnInit():void {
    let region = this.apiUrlService.getRegion();
    this.region = (region === 'stg' || region === 'test' || region === 'app-protect') ? 'us-1': region;
    this.user.getUser().then((user:APIUser) => {
      if(user.role)
      this.userObj = user;
    });
    this.account.getAllAccountFeatures().subscribe((features) => {
      this.isSuperAdmin = true;
    });

    setTimeout(() => {
      this.domService.removeComponent('feedback-messages');
      this.domService.appendComponent(FeedbackMessages);
    });
  }

  public openFeatureFlagsMopdal():void {
    this.modalService.openModal(FeatureFlagModal, {});
  }

  public logout():void {
    this.auth.logOut().subscribe(() => {});
    this.auth.removeToken();
    this.user.resetUser();

    this.logoutTimer.subscribe(() => {
      this.router.navigate(['login']);
    });
  }
}
