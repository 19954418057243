import { Component, ViewChild, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';

import { Group } from '../../../core/stores/group';
import { TelemetryService } from '@trend-core/trend/telemetry.service';

import { MatDataTableCols } from '@trend-common/mat-data-table/mat-data-table-colsDef-t';


@Component({
  selector: 'account-telemetry',
  templateUrl: 'account-telemetry.html',
  styleUrls: ['account-telemetry.scss'],
  host: {
    class: 'accountTelemetryPage'
  }
})
export class AccountTelemetry implements OnInit, OnDestroy {
  public accountId:string;
  public cols:string[] = [ 'group', 'exceptions', 'requests' ];
  public chartIsReadySubject:Subject<boolean> = new Subject();
  public chartDataSubject:Subject<any> = new Subject();

  @ViewChild('groupTmpl', {static: true}) groupTmpl : TemplateRef<any>;
  @ViewChild('exceptionsTmpl', {static: true}) exceptionsTmpl : TemplateRef<any>;
  @ViewChild('requestsTmpl', {static: true}) requestsTmpl : TemplateRef<any>;

  // mat-data-table members.
  public colsDef:MatDataTableCols;
  public searchConfig = {
    label: 'group',
    keys: [ 'group.name' ],
    info: 'Filter groups by name.'
  }
  public xaxisConfig = {
    categories: [],
    type: 'datetime',
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    labels: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  }
  public yaxisConfig = {
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    labels: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  }

  private _getAccountStatisticsSub:Subscription;

  constructor(
    public router:Router,
    private route:ActivatedRoute,
    private telemetryService:TelemetryService,
    private group:Group
  ) {}

  ngOnInit():void {
    this.colsDef = [
      {
        colId: 'group',
        colHeader: 'Group',
        colTmpl: this.groupTmpl
      },
      {
        colId: 'exceptions',
        colHeader: 'Exceptions (week)',
        colTmpl: this.exceptionsTmpl
      },
      {
        colId: 'requests',
        colHeader: 'Requests (week)',
        colTmpl: this.requestsTmpl
      }
    ];

    this.accountId = this.route.parent.snapshot.params.account_id;

    let timeframe:object = {};
    timeframe['from'] = moment().subtract(7, 'days').utc().startOf('day').format();
    timeframe['to'] = moment().utc().format();

    this._getAccountStatisticsSub = this.telemetryService.getAccountStatistics(this.accountId, timeframe).subscribe(stats => {
      let tableData = [];

      this.group.getGroupsByAccountId(this.accountId).then((groups:object[]) => {
        for(var groupId in stats['statistics']) {
          let groupName:string;

          for(var g=0; g<groups.length; g++) {
            if(groups[g]['group_id'] == groupId) {
              groupName = groups[g]['name'];
            }
          }

          if(groupName) {
            tableData.push({
              group: {
                accountId: this.accountId,
                id: groupId,
                name: groupName.slice(0, 20)
              },
              exceptions: this.telemetryService.getLineChartData(stats['statistics'][groupId], ['exceptions', 'dropped_messages', 'rejected_messages']),
              requests: this.telemetryService.getLineChartData(stats['statistics'][groupId], ['total_transactions', 'reported_transactions'])
            });
          }
        }

        this.chartDataSubject.next(tableData);
        this.chartIsReadySubject.next(true);
      }, (err:object) => {
        console.error('AccountTelemetry | group.getGroupById failed', err);
        this.chartDataSubject.error('Error');
      });
    }, err => {
      console.log('AccountTelemetry | telemetryService.getAccountStatistics failed', err);
      this.chartDataSubject.error('Error');
    });
  }

  ngOnDestroy():void {
    if(this._getAccountStatisticsSub) {
      this._getAccountStatisticsSub.unsubscribe();
      this._getAccountStatisticsSub = null;
    }
  }
}
