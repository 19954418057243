import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { Observable, Subscription, Subscriber } from 'rxjs';

import { DispatcherMediatorPair } from './dispatcher-mediator-pair-t';
import { DispatcherParamsStore } from './dispatcher-params-store-t';
import { AuthService } from '../../common/auth/auth.service';

import jsonurl from 'json-url';

@Injectable()
export class DispatcherParamsStoreService {

  private _params:DispatcherParamsStore = {};

  public get params():DispatcherParamsStore {
    return this._params;
  }

  public set params(val:DispatcherParamsStore){
    this._params = val;
  }

  constructor(
    private route:ActivatedRoute,
    private authService:AuthService){}

  public getParams():Promise<any> {
    if(Object.keys(this.params).length === 0) {
      if(this.hasDefaults()){
        try {
          return this.URLtoJson(this.route.queryParams['_value']['report'] || this.authService.report).then(obj => {
            this.params = obj;
          });
        } catch(e){
          console.error("Tried to generate deep linked content but couldn't find a source for a deep link.");
          console.error(e);
        }
      }
    }

    return new Promise(resolve => {
      resolve(this.params);
    });
  }

  public setParams(dispatcherName:string, value:any):void {
    const cleanParams = this.cleanParams(value);
    this.params[dispatcherName] = cleanParams;
  }

  private cleanParams(params:any):any {
    let cleanParams:any;

    if(typeof params === "string"){
      cleanParams = this.cleanStringParam(params);
    } if (typeof params === "number"){
      cleanParams = params;
    } if (typeof params !== "string" && typeof params !== "number"){
      cleanParams = this.cleanObjectParam(params);
    }

    return cleanParams;
  }

  //we must remove html from the params
  private cleanStringParam(value:string):string {
    return value.replace(/</g,"&lt;").replace(/>/g,"&gt;").replace(/'/g,"&apos;");
  }

  private cleanObjectParam(value:object):object {
    let valueCopy = Object.assign({}, value);

    for(let v in valueCopy){
      if(valueCopy[v] === null || undefined) continue;
      switch(typeof valueCopy[v]){
        case "object":
          valueCopy[v] = this.cleanObjectParam(valueCopy[v]);
        break;
        case "function":
          //this cheats the transpiler to take the real final string version of this function's transpiled constructor
          //first case is for unminified, second case is for minified
          if(valueCopy[v].toString().match(/name = \"(.*?)\";/)){
            valueCopy[v] = valueCopy[v].toString().match(/name = \"(.*?)\";/)[1];
          } else if(valueCopy[v].toString().match(/name=\"(.*?)\"/)){
            valueCopy[v] = valueCopy[v].toString().match(/name=\"(.*?)\"/)[1];
          } else {
            valueCopy[v] = "no constructor name found";
          }
        case "string":
          valueCopy[v] = this.cleanStringParam(valueCopy[v]);
        break;
        case "number":
          valueCopy[v] = this.cleanStringParam(valueCopy[v].toString());
          break;
      }
    }

    return valueCopy;
  }

  private uncleanParams(params:any):any {
    let uncleanParams:any;

    if(typeof params === "string"){
      uncleanParams = this.uncleanStringParam(params);
    } if (typeof params === "number"){
      uncleanParams = params;
    } if (typeof params !== "string" && typeof params !== "number"){
      uncleanParams = this.uncleanObjectParam(params);
    }

    return uncleanParams;
  }

  private uncleanStringParam(value:string):string {
    return value.replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&apos;/g,"'");
  }

  private uncleanObjectParam(value:object):object {
    let valueCopy = Object.assign({}, value);

    for(let v in valueCopy){
      if(valueCopy[v] === null || undefined) continue;
      switch(typeof valueCopy[v]){
        case "string":
          valueCopy[v] = this.uncleanStringParam(valueCopy[v]);
        break;
        case "object":
          valueCopy[v] = this.uncleanObjectParam(valueCopy[v]);
          break;
        case "number":
        break;
      }
    }

    return valueCopy;
  }

  public jsonToURL(json:object):any {
    return jsonurl('lzma').compress(json);
  }

  public URLtoJson(url:string):any {
    return jsonurl('lzma').decompress(url);
  }

  public hasDefaults():boolean {
    if(this.route.queryParams && this.route.queryParams['_value'] && this.route.queryParams['_value']['report']) {
      if(this.route.queryParams['_value']['report'] !== ""){
        return true;
      }
    } else {
      if(this.authService.report) {
        return true;
      }
    }

    return false;
  }

  public getPairParamDefaults(pairHash:string, dispatcherName:string):Promise<any> {
    if(this.route.queryParams['_value'] && this.params[pairHash]) {
      return this.uncleanParams(this.params)[pairHash][dispatcherName];
    } else {
      return null;
    }
  }

  public getPairHash(pair:DispatcherMediatorPair):string {
    let string = Md5.hashStr(pair.mediatorName ? pair.mediatorName : '' +
                             pair.dispatcherName ? pair.dispatcherName : '' +
                             pair.json ? JSON.stringify(pair.json) : '').toString();

    if (string === '') console.error('Can\'t create pair hash out of an empty dispatcher mediator pair, need at least one property.');

    return string;
  }
}
