import { Component } from '@angular/core';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { ModalService } from '@trend-common/modals/modal.service';
import { WindowService } from '@trend-core/trend/window'

import { PendingUsersTableMediator } from '../../../../core/mediators/pending-users-table.mediator';
import { DeletePendingUserModal } from '../../../modals/delete-pending-user-modal/delete-pending-user-modal';
import { AccountPendingUsersTableMediator } from '../../../../core/mediators/account-pending-users-table.mediator';

import { APIPendingUser } from '../../../../core/types/api/api-pending-user-t';

@Component({
  selector: 'pending-user-details',
  templateUrl: './pending-user-details.html',
  styleUrls: ['./pending-user-details.scss']
})
export class PendingUserDetailsDrawer {
  public data:APIPendingUser;
  public verificationUrl:string;

  constructor(
    private apiUrlService:APIUrlService,
    private modal:ModalService,
    private pendingUsersTableMediator:PendingUsersTableMediator,
    private accountPendingUsersTableMediator:AccountPendingUsersTableMediator,
    private windowService:WindowService
  ) {}

  ngOnInit():void {
    this.data = this['data'];
    this.verificationUrl = this.apiUrlService.dashboardUrl(true) + 'create-account/' + this.apiUrlService.getRegion() + '/' + this['data']['verification_token'];
  }

  private _postDeletePendingUser():void {
    if(this.windowService.getWindow().location.href.indexOf('accounts') === -1) {
      // we're on the all users page
      this.pendingUsersTableMediator['reloadTable']();
    } else {
      // we're on the account users page
      this.accountPendingUsersTableMediator['reloadTable']();
      this.accountPendingUsersTableMediator['drawerService']['close']();
    }
  }

  public openDeletePendingUserModal():void {
    this.modal.openModal(
      DeletePendingUserModal, {
        data: {
          afterSuccessFn: this._postDeletePendingUser.bind(this),
          pendingUser: this.data
        }
      }
    );
  }
}
