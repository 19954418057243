import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalConfig } from '../../core/modal-config-t';


@Injectable()
export class ModalService {

  constructor(public dialog: MatDialog) {}

  openModal(modalComp:any, modalConfig: ModalConfig, onOpen?:Function, onClose?:Function):MatDialogRef<any> {
    if(!modalComp || !modalConfig) {
      console.error('Modal service: missing modalComp, modalConfig or confirmFunc');
      return;
    }

    const dialogRef:MatDialogRef<any> = this.dialog.open(modalComp, modalConfig);

    dialogRef.afterClosed().subscribe(result => {
      if(onClose) onClose(result);
    });

    dialogRef.afterOpened().subscribe((result) => {
      if(onOpen) onOpen(result);
    });

    return dialogRef;
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
