import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

@Injectable()
export class Group {
  private _getGroupsPromise;
  private _accountGroups;
  private _accountGroupsEndpoint:string;

  constructor(
    private http:HttpClient,
    private apiUrl:APIUrlService
  ) {}

  private fetchAccountGroups(accountId:string) {
    return this.http.get(this.apiUrl.url() + 'accounts/' + accountId + '/groups');
  }

  public initAccountGroups(accountId:string) {
    this._getGroupsPromise = new Promise((resolve, reject) => {
      this.fetchAccountGroups(accountId).subscribe((groups) => {
        this._accountGroups = groups;
        resolve(groups);
      });
    });

    return this._getGroupsPromise;
  }

  public getGroupsByAccountId(accountId:string) {
    if(!this._accountGroups) {
      this.initAccountGroups(accountId);
    }

    return this._getGroupsPromise;
  }

  public getGroupById(accountID, groupId:string) {
    return new Promise((resolve, reject) => {
      if(this._accountGroups) {
        for(var i=0; i<this._accountGroups.length; i++) {
          if(this._accountGroups[i].group_id === groupId) {
            resolve(this._accountGroups[i]);
            break;
          }
        }
      } else {
        this.initAccountGroups(accountID).then(groups => {
          for(var i=0; i<this._accountGroups.length; i++) {
            if(this._accountGroups[i].group_id === groupId) {
              resolve(this._accountGroups[i]);
              break;
            }
          }
        });
      }
    });
  }

  public resetGroups():void {
    this._accountGroups = [];
  }
}
