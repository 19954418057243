import { Injectable } from '@angular/core';

@Injectable()
export class WindowService {

  constructor() {}

  //we call this any to use in tests
  getWindow():any {
    return window;
  }
}
