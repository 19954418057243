import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

let matModuleArray:any[] = [
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
];

import { Table } from './table';

@NgModule({
  imports: [
    NgxDatatableModule,
    BrowserAnimationsModule,
    FormsModule,
    matModuleArray
  ],
  providers: [

  ],
  declarations: [
    Table
  ],
  exports: [
    Table
  ]
})
export class TableModule {}
