import { Component } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: 'page-header.html'
})
export class PageHeader {
  title:string;

  onTitleChange(title:string):void {
    this.title = title;
  }
}
