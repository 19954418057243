import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { FeatureToggleService } from '@trend-common/feature-toggle.service';

@Injectable()
export class PostureService {
  private _postureEndpoint:string = this.apiUrlService.url() + 'posture';
  private _postureConnectionsEndpoint:string = this.apiUrlService.url() + 'posture/connections';

  constructor(
    private http:HttpClient,
    private apiUrlService:APIUrlService,
    private _featureToggleService:FeatureToggleService
  ) {}

  public getConnections():Observable<any> {
    return this.http.get(this._postureConnectionsEndpoint);
  }

  public getConnection(connectionId:string):Observable<any> {
    return this.http.get(this._postureConnectionsEndpoint + '/aws/' + connectionId);
  }

  public updateConnectionName(connectionId:string, connectionName:string):Observable<any> {
    return this.http.put(this._postureConnectionsEndpoint + '/' + connectionId, {
      name: connectionName
    });
  }

  public reactivateConnection(connectionId:string):Observable<any> {
    return this.http.put(this._postureConnectionsEndpoint + '/' + connectionId, {
      state: 'ACTIVE'
    });
  }

  public getCustomTagsKeys():Observable<any> {
    return this.http.get(this._postureEndpoint + '/functions/filters/custom');
  }

  public getExemptions():Observable<any> {
    return this.http.get(this._postureEndpoint + '/exemptions');
  }

  public saveExemptions(exemptionList):Observable<any> {
    return this.http.put(this._postureEndpoint + '/exemptions', exemptionList);
  }

  public getCustomTagsValueByKey(tagKey:string):Observable<any> {
    return this.http.get(this._postureEndpoint + '/functions/filters/custom/values?tag_key=' + tagKey);
  }

  public addConnection(name:string, type:string = 'aws'):Observable<any> {
    return this.http.post(this._postureConnectionsEndpoint + '/' + type, {
      name: name
    });
  }

  public addARNtoAWSConnection(connectionId:string, roleArn:string):Observable<any> {
    return this.http.put(this._postureConnectionsEndpoint + '/aws/' + connectionId, {
      role_arn: roleArn
    });
  }

  public deleteConnection(connectionId:string):Observable<any> {
    return this.http.delete(this._postureConnectionsEndpoint + '/' + connectionId);
  }

  public canActivate():Promise<boolean> {
    return this._featureToggleService.getFeatures().then((featureFlags) => {
      return featureFlags.enabled_features.indexOf('enablePosture') > -1;
    });
  }
}
