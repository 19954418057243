import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { Account } from '../../../core/stores/account';
import { Group } from '../../../core/stores/group';
import { BreadcrumbsService } from '../../../common/breadcrumbs/breadcrumbs.service';

import { APIUserGroupInfo } from '@trend-core/api/api-user-group-info-t';

@Component({
  selector: 'account-group',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'group.html',
  styleUrls: ['group.scss']
})
export class AccountGroup {
  public account_id:string;
  public group_id:string;
  public currentGroup:APIUserGroupInfo;
  public activeTab:string;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private breadcrumbsService:BreadcrumbsService,
    private account:Account,
    private group:Group
  ) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.setActiveTab();
      }
    });
  }

  ngOnInit():void {
    this.account_id = this.route.snapshot.params.account_id;
    this.group_id = this.route.snapshot.params.group_id;

    // Get app info.
    this.group.resetGroups();
    this.group.initAccountGroups(this.account_id).then(() => {
      this.group.getGroupById(this.account_id, this.group_id).then((group:APIUserGroupInfo) => {
        this.currentGroup = group;

        this.account.getAccount(this.account_id).subscribe(account => {
          this.breadcrumbsService.updateBreadcrumbs([
            {
              text:'Accounts',
              link: '/accounts'
            }, {
              text: account['tenant_name'],
              link: '/accounts/' + account['account_id'] + '/groups'
            }, {
              text: 'Group Details: ' + group['name'],
              link: ''
            }
          ]);
        });
      });
    });
  }

  setActiveTab():void {
    this.activeTab = this.route.children[0] ? this.route.children[0].routeConfig.path : 'telemetry';
  }
}
