import { Observable, Subject } from 'rxjs';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';

import { NgxDatatablePage } from '../table/ngx-datatable-page-t';
import { Injectable } from "@angular/core";

@Injectable()
export class PaginationService implements DispatcherInterface {
  private pageChangeSubject = new Subject<any>();
  private pagination:NgxDatatablePage = {
    count: 0,
    limit: 10,
    offset: 0,
    pageSize: 10
  };

  public updateSubject = this.pageChangeSubject;
  private pairHash:string;

  constructor() {}

  getDefaults():Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.getPagination());
    });
  }

  getPagination():any {
    return this.pagination;
  }

  // Set by the service factory.
  setPagination(paginationDefaults:NgxDatatablePage):void {
    this.pagination = paginationDefaults;
  }

  update(payload:NgxDatatablePage):void {
    this.pagination = payload;
    this.pageChangeSubject.next(this.pagination);
  }

  onUpdate():Observable<any> {
    return this.pageChangeSubject.asObservable();
  }

  hardTrigger():void {
    this.pageChangeSubject.next(this.pagination);
  }

  setPairHash(pairHash:string):void {
    this.pairHash = pairHash;
  }
}
