import { Component, OnInit, OnDestroy, HostBinding, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { timer } from 'rxjs';

import { RealtimeRangeModal } from './realtime-custom-range-modal/realtime-custom-range-modal';
import { TimeframeService } from './timeframe.service';
import { TimeframeConfig } from '@trend-core/timeframe-config-t';
import { ModalService } from '../modals/modal.service';
import { DispatcherComponent } from '@trend-core/dispatcher/dispatcher-component-i';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

@Component({
  selector: 'timeframe',
  templateUrl: 'timeframe.html',
  styleUrls: ['timeframe.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'LL',
        },
        display: {
          dateInput: 'LL',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
  ],
})
export class TimeFrame implements DispatcherComponent, OnInit, OnDestroy{
  isRealtimeOn:boolean = true;
  realtimeRange:string;
  staticRangeDisplay:string;
  activeRangeType:string;

  staticFromFC:FormControl;
  staticToFC:FormControl;

  realtimeCustomRangeModal:MatDialogRef<any>;

  activeTimeframe:TimeframeConfig;

  @HostBinding('class.visible') visible:boolean = true;

  constructor(
    private timeframeService: TimeframeService,
    private modalService: ModalService
  ) {}

  ngOnInit():void {
    this.timeframeService.resetTimeframe();

    timer(500).subscribe(() => {
      this.activeTimeframe = this.timeframeService.getTimeframe();
      this.isRealtimeOn = this.activeTimeframe.isRealtime;
      this.activeRangeType = this.activeTimeframe.rangeType;

      this.staticFromFC = new FormControl(moment(this.activeTimeframe.from), [Validators.required]);
      this.staticToFC = new FormControl(moment(this.activeTimeframe.to), [Validators.required]);

      this.timeframeService.onForcedTimeframeUpdate().subscribe((timeframe)=>{
        this.forceTimeframeUpdate(timeframe);
      });
    });

    this.timeframeService.onHide().subscribe(()=>{
      this.hide();
    });

    this.timeframeService.onShow().subscribe(()=>{
      this.show();
    });
  }

  ngOnDestroy():void {

  }

  public get startDate(): string {
    return new Date(this.staticFromFC.value).toISOString().split('T')[0]
  }

  public get endDate():string {
    return new Date(this.staticToFC.value).toISOString().split('T')[0]
  }

  public get today(): string {
    return new Date().toISOString().split('T')[0]
  }

  private hide():void {
    this.visible = false;
  }

  private show():void {
    this.visible = true;
  }

  capitalize(text:string):string {
    return text[0].toUpperCase() + text.slice(1);
  }

  forceTimeframeUpdate(timeframe:TimeframeConfig):void {
    this.activeTimeframe = timeframe;

    //depending on where the force comes from, must make sure the dates work
    if(this.activeTimeframe["from"].indexOf(' ') > -1){
      this.activeTimeframe['from'] = moment(timeframe['from'], 'MMMM Do YYYY').format();
      this.activeTimeframe['to'] = moment(timeframe['to'], 'MMMM Do YYYY').format();
    }

    this.staticRangeDisplay = this.timeframeService.formatRangeForDisplay(this.activeTimeframe);

    this.staticFromFC = new FormControl(moment(this.activeTimeframe.from), [Validators.required]);
    this.staticToFC = new FormControl(moment(this.activeTimeframe.to), [Validators.required]);

    this.activeRangeType = 'custom';
    this.isRealtimeOn = timeframe.isRealtime;

    this.timeframeService.updateTimeframe(timeframe, true);
  }

  handleRealtimeRangeClick(event:string):void {
    return this.realtimeRangeChanged(event);
  }

  realtimeRangeChanged(event:string):void {
    switch (event) {
      case 'lastday':
        this.activeTimeframe = {
          value: 24,
          unit: 'hours',
          rangeType: 'day',
          from: moment().subtract(1, 'days').format(),
          to: moment().format(),
          isRealtime: true
        }
        this.activeRangeType = 'day';
        break;
      case 'lastweek':
        this.activeTimeframe = {
          value: 7,
          unit: 'days',
          rangeType: 'week',
          from: moment().subtract(1, 'week').format(),
          to: moment().format(),
          isRealtime: true
        }
        this.activeRangeType = 'week';
        break;
      case 'custom':
        this.activeTimeframe.rangeType = "custom";
        this.activeRangeType = 'custom';
        break;
    }

    this.timeframeService.updateTimeframe(this.activeTimeframe);
  }

  handleToggleClick():void {
    //anything inside the timer is a result AFTER the click
    timer(0).subscribe(()=>{
      this.timeframeService.togglePolling(this.isRealtimeOn);
      this.activeTimeframe.isRealtime = this.isRealtimeOn;
      this.timeframeService.updateTimeframe(this.activeTimeframe);

      if(!this.isRealtimeOn){
        //turned realtime off
        this.staticFromFC = new FormControl(moment(this.activeTimeframe.from), [Validators.required]);
        this.staticToFC = new FormControl(moment(this.activeTimeframe.to), [Validators.required]);
      } else {
        //turned realtime on
        this.realtimeRangeChanged('lastweek');
        this.timeframeService.resetTimeframe();
      }
    });
  }

  openRealtimeCustomRangeModal():void {
    let currentRangeType = this.activeRangeType + "";
    this.realtimeCustomRangeModal = this.modalService.openModal(RealtimeRangeModal, {
      data: {
        timeframe: this.activeTimeframe
      }
    });

    this.realtimeCustomRangeModal.afterClosed().subscribe((action: string) => {
      if(action === 'timeSet') {
        this.activeTimeframe.rangeType = "custom";
        this.timeframeService.updateTimeframe(this.activeTimeframe);
        this.activeRangeType = "custom";
      } else {
        this.activeRangeType = currentRangeType;
      }
    });
  }

  staticTimeChanged(e:any):void {
    if(e.targetElement.getAttribute('data-placeholder') === 'Start Date'){
      this.activeTimeframe['from'] = moment(e.value).utcOffset(moment().utcOffset()).format();
    } else {
      this.activeTimeframe['to'] = moment(e.value).utcOffset(moment().utcOffset()).format();
    }

    this.activeTimeframe['value'] = moment(this.activeTimeframe['to']).diff(moment(this.activeTimeframe['from']), 'days');

    this.activeRangeType = 'custom';
    this.timeframeService.updateTimeframe(this.activeTimeframe);
  }
}
