<h1 mat-dialog-title>Delete Pending User</h1>

<div mat-dialog-content>
  Are you sure you want to delete the user {{ selectedVerifiedUserEmail }}?
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Cancel</button>
  <button (click)="deleteVerifiedUser()" mat-flat-button color="primary" matDialogClose>Delete</button>
</div>
