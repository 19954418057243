import { Component } from '@angular/core';

import { BreadcrumbsService } from './breadcrumbs.service';
import { Breadcrumb } from '../../core/types/components/breadcrumbs-t';

@Component({
  templateUrl: 'breadcrumbs.html',
  selector: 'breadcrumbs',
  styleUrls: ['breadcrumbs.scss']
})
export class Breadcrumbs {
  private updateSub;
  public levels:Breadcrumb[];

  constructor(
    private breadcrumbsService:BreadcrumbsService
  ) {}

  ngOnInit():void {
    this.updateSub = this.breadcrumbsService.onBreadcrumbsUpdate().subscribe(breadcrumbs => {
      this.levels = breadcrumbs;
    });
  }

  ngOnDestroy():void {
    if(this.updateSub) {
      this.updateSub.unsubscribe();
      this.updateSub = null;
    }
  }
}
