<ul class="messages-wrapper" *ngIf="messages">
  <li *ngFor="let messageId of objectKeys(messages)" class="message {{messages[messageId].type}} {{messages[messageId].animClass}}">
    <div class="message__icon">
      <i *ngIf="messages[messageId].type == 'error'" class="material-icons">cancel</i>
      <i *ngIf="messages[messageId].type == 'success'" class="material-icons">check_circle</i>
      <i *ngIf="messages[messageId].type == 'info'" class="material-icons">error</i>
      <i *ngIf="messages[messageId].type == 'warning'" class="material-icons">error_outline</i>
    </div>
    <div class="message__text">
      <h5 *ngIf="messages[messageId].title">{{messages[messageId].title}}</h5>
      <span>{{messages[messageId].text}}</span>
      <p *ngIf="messages[messageId].link" class="message__link">
        {{messages[messageId].link.text}}
      </p>
    </div>

    <div class="message__close" (click)="dismissMsg(messageId)">
      <i class="material-icons">close</i>
    </div>
    <div class="message__timer"
         [ngClass]="{'is-shown': messages[messageId].ttl}"
         [style.animation-duration]="messages[messageId].ttl + 'ms'">
    </div>
  </li>
</ul>
