import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-modal',
  templateUrl: 'confirm-modal.html',
  encapsulation: ViewEncapsulation.None
})
export class ConfirmModal {
  public title: string;
  public confirmFunc:Function;
  public confirmText:string;
  public confirmButtonText:string;
  public confirmButtonColor:string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModal>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}

  ngOnInit():void {
    if(!this.data['confirmFunc']) {
      console.error('ConfirmModal: no confirmFunc found in modal data');
      return;
    }

    this.title = this.data['title'];
    this.confirmFunc = this.data['confirmFunc'];
    this.confirmText = this.data['confirmText'];
    this.confirmButtonText = this.data['confirmButtonText'];
    this.confirmButtonColor = this.data['confirmButtonColor'] || 'primary';
  }

  public confirmWrapper():void {
    if(this.confirmFunc) this.confirmFunc();
    this.dialogRef.close();
  }

  public cancelFunc():void {
    if(typeof this.data['cancelFunc'] === 'function') {
      this.data['cancelFunc']();
    }

    this.dialogRef.close();
  }
}
