import { Injectable } from '@angular/core';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';
import { Observable, Subject, timer} from 'rxjs';

@Injectable()
export class SearchService implements DispatcherInterface {

  updateSubject = new Subject<any>();
  forceSubject = new Subject<any>();

  /* tslint:disable */
  searchParam = "";
  pairHash:string;
  /* tslint:enable */

  timer2seconds:Observable<number> = timer(2000);

  constructor() {}

  getDefaults():Promise<any> {
    return Promise.resolve(this.getSearchParam());
  }

  update(payload:any):void {
    this.searchParam = payload;
    this.updateSubject.next(payload);
  }

  getSearchParam():string {
    return this.searchParam;
  }

  onUpdate():Observable<any> {
    return this.updateSubject.asObservable();
  }

  onForce():Observable<any> {
    return this.forceSubject.asObservable();
  }

  forceSearch(payload):void {
    this.update(payload);
    this.forceSubject.next(payload);
  }

  setPairHash(pairHash:string):void {
    this.pairHash = pairHash;
  }

  hardTrigger():void {
    this.update(this.getSearchParam());
  }
}
