<header>
  <section>
    <h3>Summary</h3>
    <table class="infoTable">
      <tr>
        <td class="infoTable__key">Group Name</td>
        <td class="infoTable__value">{{ currentGroup.name || 'N/A' }}</td>
      </tr>
      <tr>
        <td class="infoTable__key">Creation Date</td>
        <td class="infoTable__value">{{ currentGroup.activated_on | timezonedDate }}</td>
      </tr>
      <tr>
        <td class="infoTable__key">Group ID</td>
        <td class="infoTable__value">{{ currentGroup.group_id || 'N/A' }}</td>
      </tr>
    </table>
  </section>
</header>

<nav mat-tab-nav-bar
     [color]="'primary'"
     [backgroundColor]="''"
     class="stickyPageTabs">
  <a mat-tab-link
     routerLink="/accounts/{{ account_id }}/groups/{{ group_id }}/telemetry"
     [active]="activeTab == 'telemetry'">
     Telemetry
  </a>

  <a mat-tab-link
     routerLink="/accounts/{{ account_id }}/groups/{{ group_id }}/vmdata"
     [active]="activeTab == 'vmdata'">
     Agent Config
  </a>

  <a mat-tab-link
     routerLink="/accounts/{{ account_id }}/groups/{{ group_id }}/libraries"
     [active]="activeTab == 'libraries'">
     Libraries
  </a>

  <a mat-tab-link
     routerLink="/accounts/{{ account_id }}/groups/{{ group_id }}/agent-environments"
     [active]="activeTab == 'agent-environments'">
     Agent Environment
  </a>
</nav>

<router-outlet></router-outlet>
