import { Component, ViewChild, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { timer, Subscription } from 'rxjs';

import { ModalService } from '@trend-common/modals/modal.service';
import { BreadcrumbsService } from '../../../common/breadcrumbs/breadcrumbs.service';

import { Account } from '../../../core/stores/account';
// import { User } from '../../../core/stores/user';

import { CreateAccountModal } from '../../../common/modals/create-account-modal/create-account-modal';
import { AddPendingUserModal } from '../../../common/modals/add-pending-user-modal/add-pending-user-modal';
import { ConfirmModal } from '@trend-common/modals/confirm-modal/confirm-modal';

import { AccountsTableMediator } from '../../../core/mediators/accounts-table.mediator';

import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';
// import { MatDataTableService } from '@trend-common/mat-data-table/mat-data-table.service';

import { MatDataTableCols } from '@trend-common/mat-data-table/mat-data-table-colsDef-t';


@Component({
  selector: 'accounts-list',
  templateUrl: 'accounts-list.html',
  styleUrls: ['accounts-list.scss']
})
export class AccountsList implements OnInit, OnDestroy {
  // mat-data-table members.
  public cols:string[] = [ 'account_id', 'tenant_name', 'source', 'cloud_one_account_id', 'created_on', 'actions' ];
  public colsDef:MatDataTableCols;
  public searchConfig = {
    label: 'accounts',
    keys: [ 'account_id', 'tenant_name', 'source', 'cloud_one_account_id' ],
    info: 'Accounts can by filtered by Account ID, Tenant Name, Source and Cloud One Account ID',
    width: '320px'
  }
  private _selectedAccountId:string;
  private _onTableReadySub:Subscription;

  @ViewChild('actionsTmpl', {static: true}) actionsTmpl : TemplateRef<any>;

  constructor(
    public accountsTableMediator:AccountsTableMediator,
    private router:Router,
    private breadcrumbsService:BreadcrumbsService,
    private modal:ModalService,
    private account:Account,
    // private user:User,
    private feedbackMessagesService:FeedbackMessagesService,
    // private matDataTableService:MatDataTableService
  ) {}

  ngOnInit():void {
    this.colsDef = [
      {
        colId: 'account_id',
        colHeader: 'Account ID'
      },
      {
        colId: 'tenant_name',
        colHeader: 'Tenant Name'
      },
      {
        colId: 'source',
        colHeader: 'Source'
      },
      {
        colId: 'cloud_one_account_id',
        colHeader: 'Cloud One Account ID'
      },
      {
        colId: 'created_on',
        colHeader: 'Creation Date'
      },
      {
        colId: 'actions',
        colHeader: 'Actions',
        colTmpl: this.actionsTmpl
      }
    ];

    this.breadcrumbsService.updateBreadcrumbs([
      {
        text:'Accounts List',
        link: ''
      }
    ]);

    // this._onTableReadySub = this.matDataTableService.onTableReady().subscribe((rowLength:number) => {
    //   if(rowLength == 0) return;
    //
    //   this._checkForDeleteButtonAvailability();
    // });
  }

  ngOnDestroy() {
    if(this._onTableReadySub) {
      this._onTableReadySub.unsubscribe();
      this._onTableReadySub = null;
    }
  }

  // private _checkForDeleteButtonAvailability() {
  //   this.user.getUser().then(user => {
  //     if(user['role'] === 'trend_super_admin') {
  //       this._showDeleteButton();
  //     }
  //   });
  // }

  // private _showDeleteButton() {
  //   timer(500).subscribe(() => {
  //     let deleteButtons = document.querySelectorAll('.deleteAccountButton');
  //     for(var i=0; i<deleteButtons.length; i++) {
  //       deleteButtons[i].classList.add('is-shown');
  //     }
  //   });
  // }

  public openCreateAccountModal():void {
    this.modal.openModal(
      CreateAccountModal, {
        data: {
          afterSuccessFn: this.postOpenCreateAccountModal.bind(this)
        }
      }
    )
  }

  public openAddPendingUserModal():void {
    this.modal.openModal(
      AddPendingUserModal, {
        data: {
          modalTitle: 'Add User to Account'
        }
      }
    )
  }

  private postOpenCreateAccountModal():void {
    this.accountsTableMediator['reloadTable']();
  }

  public onRowClick = (row:object):void => {
    this.router.navigate(['accounts/' + row['account_id']]);
  }

  private _confirmDeleteAccount() {
    this.account.deleteAccount(this._selectedAccountId).subscribe(() => {
      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Account successfully deleted.',
        text: 'The account and all associated ressources were successfully deleted.'
      });
      this.accountsTableMediator.reloadTable();
    }, (error:object) => {
      document.getElementById('deleteButton-' + this._selectedAccountId).classList.remove('is-loading');

      if(error['error']['status'].indexOf('409 Conflict') !== -1) {
        this.feedbackMessagesService.add({
          type: 'error',
          title: 'Account could not be deleted',
          text: 'All groups and users within the account must be deleted before deleting the account.'
        });
      } else {
        this.feedbackMessagesService.add({
          type: 'error',
          title: 'Something went wrong',
          text: 'The account could not be deleted at this time, please try again later.'
        });
      }
    });
  }

  private _cancelDeleteAccount() {
    document.getElementById('deleteButton-' + this._selectedAccountId).classList.remove('is-loading');
  }

  public openDeleteAccountModal(accountId:string, accountTenant:string, event:Event) {
    event.stopPropagation();
    document.getElementById('deleteButton-' + accountId).classList.add('is-loading');

    // this.user.getUser().then(user => {
      document.getElementById('deleteButton-' + accountId).classList.remove('is-loading');

      // if(user['role'] !== 'trend_super_admin') {
      //   this.feedbackMessagesService.add({
      //     type: 'error',
      //     title: 'Action not allowed',
      //     text: 'Insufficient privileges.'
      //   });
      //
      //   document.getElementById('deleteButton-' + accountId).classList.remove('is-loading');
      //   return;
      // }

      this._selectedAccountId = accountId;
      this.modal.openModal(
        ConfirmModal, {
          data: {
            title: 'Delete Account',
            confirmText: 'Are you sure you want to delete the account ' + accountTenant + ' (' + accountId + ')' + '? Deleting the account is irreversible and will delete all of its related ressources.',
            confirmButtonColor: 'warn',
            confirmFunc: this._confirmDeleteAccount.bind(this),
            cancelFunc: this._cancelDeleteAccount.bind(this),
          }
        }
      );
    // });
  }
}
