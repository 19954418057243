import { Injectable } from '@angular/core';

import { DispatcherMediatorPair } from '@trend-core/dispatcher/dispatcher-mediator-pair-t';
import { DispatcherParamsStoreService } from '@trend-core/dispatcher/dispatcher-params-store.service';

/*
  DispatcherServiceFactoryStore

  This service works as both the factory for new dispatcher services
  as well as the store for those services.

  The factory builds stores services using a hash ID built from the mediator & dispatcher relationship as well
  as the json default objects of the respective dispatcher services.

  The factory also can search the store using the same properties.

  TODO: Add different ways of searching the store.
*/

@Injectable()
export class DispatcherServiceFactory {

  private _dispatcherStore:any = {};

  public get dispatcherStore():any {
    return this._dispatcherStore;
  }

  public set dispatcherStore(val:any) {
    this._dispatcherStore = val;
  }

  constructor(public dispatcherParamsStoreService:DispatcherParamsStoreService) {}

  public new(service:any, dispatcherName:string, mediatorName:string, preCreatedInstance?:any):any {
    const newService:any = preCreatedInstance || new service();

    const pair:DispatcherMediatorPair = {
      mediatorName: mediatorName,
      dispatcherName: dispatcherName,
      json: newService.getDefaults()
    };

    let pairHash = this.dispatcherParamsStoreService.getPairHash(pair);
    newService.setPairHash(pairHash);

    if(!this.dispatcherStore[pairHash]) this.dispatcherStore[pairHash] = {};
    this.dispatcherStore[pairHash][dispatcherName] = newService;

    return this.dispatcherStore[pairHash][dispatcherName];
  }

  public getServiceInstance(service:any, dispatcherName:string, mediatorName:string):any {
    let pair:DispatcherMediatorPair = {
      mediatorName: mediatorName,
      dispatcherName: dispatcherName,
      json: new service().getDefaults()
    }

    let pairHash = this.dispatcherParamsStoreService.getPairHash(pair);

    if(this.dispatcherStore[pairHash]){
      return this.dispatcherStore[pairHash][dispatcherName] || false;
    } else {
      console.error('DispatcherServiceFactory: The pairHash was not found in the dispatcherStore. Could the third argument not be a mediator class?');
      return false;
    }
  }

  public killServiceInstance(service:any, dispatcherName:string, mediatorName:string):void {
    let pair:DispatcherMediatorPair = {
      mediatorName: mediatorName,
      dispatcherName: dispatcherName,
      json: new service().getDefaults()
    };

    let pairHash = this.dispatcherParamsStoreService.getPairHash(pair);

    delete this.dispatcherStore[pairHash];
  }
}
