import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APIUrlService } from '../api/api-url.service';

import { Algo } from '../algo-t';

@Injectable()
export class Algos {
  get algos():Algo[] {
    return this._algos;
  }
  set algos(val:Algo[]) {
    this._algos = val;
  }

  private _algos:Algo[] = [
    { key: 'http_params', label: 'HTTP Params' },
    { key: 'always_true', label: 'Always True' },
    { key: 'always_false', label: 'Always False' },
    { key: 'bad_function', label: 'Bad Function' },
    { key: 'bad_statement', label: 'Bad Statment' },
    { key: 'stacking_queries', label: 'Stacking Queries' },
    { key: 'syntax_error', label: 'Syntax Error' },
    { key: 'trailing_comment', label: 'Trailing Comment' },
    { key: 'union_set', label: 'Union Set' },
    { key: 'write', label: 'Write Control' },
    { key: 'read', label: 'Read Control' },
    { key: 'exec_control', label: 'Exec Control' },
    { key: 'redirect_control', label: 'Redirect Control' },
    { key: 'av_scanning', label: 'Antivirus Scanning' },
    { key: 'size_check', label: 'File Size Check' },
    { key: 'ip_threat_intel', label: 'IP Threat Intel' }
  ];

  constructor() {}

  getAlgos():Algo[] {
    return this.algos;
  }

  getAlgoByName(name:string):Algo {
    for(let x = 0; x < this.algos.length; x++){
      if(this.algos[x]['label'] === name){
        return this.algos[x];
      }
    }

    console.error('Algo not found by name in algo config.');
    return { key: name, label: name };
  }

  getAlgoByKey(key:string):Algo {
    for(let x = 0; x < this.algos.length; x++){
      if(this.algos[x]['key'] === key){
        return this.algos[x];
      }
    }

    console.error('Algo not found by key in algo config.');
    return { key: key, label: key };
  }

  getAlgoNameByKey(key:string):string {
    for(var i=0; i<this._algos.length; i++) {
      if(this._algos[i].key === key) return this._algos[i].label;
    }
  }
}
