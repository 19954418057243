import { Component, HostBinding, Input, OnInit, OnDestroy, ComponentFactoryResolver, ViewChild, Directive, ViewContainerRef, Type} from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { MediatorInterface } from '@trend-core/mediator/mediator-i';
import { DrawerBody } from './drawer-body-t';
import { SubscriberComponent } from '@trend-core/subscriber-component-i';
import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';

@Directive({
  selector: '[injected-drawer]'
})
export class InjectedDrawer {
  data:any;
  constructor(public viewContainerRef: ViewContainerRef){}
}

@Component({
  selector: 'drawer',
  templateUrl: 'drawer.html',
  styleUrls: ['drawer.scss']
})
export class Drawer implements SubscriberComponent, OnInit, OnDestroy {
  @Input() _mediator:MediatorInterface;
  @Input() _targetEl:string;
  @Input() _uuidProp:string;

  @ViewChild(InjectedDrawer) injectedDrawer: InjectedDrawer;
  @HostBinding('class.drawerOpen') isOpen = false;
  @HostBinding('class.drawerClosing') isClosing = false;

  data:DrawerBody;
  subscription:any;
  complete:boolean;
  error:any;
  closeSub:Subscription;
  isModal:boolean;
  isLoading:boolean = false;

  private loadTimer = timer(100);
  private cssAnimateTimer = timer(100); //TODO: const this duration globally
  private cssAnimSub:Subscription;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private feedbackMessagesService:FeedbackMessagesService) {}

  ngOnInit():void {
    if(this._mediator) {
      this.subscribe();
      this._mediator.dispatcherMap['drawer'].onClose().subscribe(() => {
        this.close();
      });
    }
  }

  ngOnDestroy():void {
    if(this._mediator) this._mediator.kill();
  }

  /*
    Update method can be invoked by the dispatcher (pagination, row changed, etc) or from the
    force method in the unique service of the drawer.
    ALWAYS FORCED. This is done because before we used to leave it up to the routing engine
    to complete the render however it's extremely limited so we've opted for always forcing. If
    it's not forced, it must be a legacy usage of the drawer and should be fixed.

    params: payload, object which should always be forced
    ex   payload = {
            data: {},
            forced: true, @WARNING depricated
            component: <Component> || 'ComponentName'
         }
  */
  update(payload:any):void {
    try {
      this.data = this._mediator.adapt(payload.data);
      this.renderDrawerBody(payload.data, payload.component);
    } catch(e){
      this.drawerError(e);
      return;
    }

    if(!this.isOpen){
      this.isOpen = true;

      let table = document.querySelectorAll('#' + this._targetEl)[0];

      if(table) {
        table.classList.add('drawer-open');
      } else {
        console.error('No table component found to resize!');
      }

      this.cssAnimSub = this.cssAnimateTimer.subscribe(() => {
        this.onCssAnimEnd();
      });
    }
  }

  //simple close method, removes data from state and shuts drawer with css
  close():void {
    if(this.isOpen){
      this.data = {
        body: {}
      };

      this.isOpen = false;
      this.isClosing = true;

      let table = document.querySelectorAll('#' + this._targetEl)[0];
      if(table) table.classList.remove('drawer-open');
      window.dispatchEvent(new Event('resize'));

      this.cssAnimSub = this.cssAnimateTimer.subscribe(() => {
        this.onCssAnimEnd();
        if(this._mediator.dispatcherMap['drawer']) this._mediator.dispatcherMap['drawer'].close();
      });
    }
  }
  /*
    renders the drawer body into the drawer directive in the template
    from a component class passed all the way from the invoke

    params: data, actual data to inject
            component, <Component> || 'ComponentName'
  */
  renderDrawerBody(data:any, component:object|string):void {
     this.loadTimer.subscribe(()=>{
       let componentClass;

       if(typeof component === 'string'){
         let allResolvedComponents = Array.from(this.componentFactoryResolver['_factories'].keys());
         componentClass = <Type<any>>allResolvedComponents.find((x: any) => x.name === component);
       } else {
         componentClass = component;
       }

       let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);

       if(this.injectedDrawer){
         let viewContainerRef = this.injectedDrawer.viewContainerRef;
         viewContainerRef.clear();

         let componentRef = viewContainerRef.createComponent(componentFactory);
         (<InjectedDrawer>componentRef.instance).data = data;
       }
    });
  }

  drawerError(e):void {
    console.error("Issue injecting data, could this be an non-existent or invalid event or an invalid row payload? Mediator could be incorrectly configured or data-set could be out of date and requires back-fill.");
    console.error(e);

    this.feedbackMessagesService.add({
      type: 'error',
      title: 'Error!',
      text: 'There was an error gathering the event details, please try again later or refer to support for ongoing issues.'
    });

    this.error = "We had a problem gathering and generating event details. Could this event be non-existent or invalid? Please refer to support for ongoing issues.";

  }

  subscribe():void {
    this.subscription = this._mediator.getRequestSubject(this).subscribe(
      data => this.update(data),
      error => this.error = error,
      () => this.complete = true
    );
  }

  //invoked after assumed css animation durations
  onCssAnimEnd():void {
    //this is to account for ngx not resizing on it's own
    window.dispatchEvent(new Event('resize'));
    this.isClosing = false;

    this.cssAnimSub.unsubscribe();
  }
}
