import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { APIUserGroup } from '@trend-core/api/api-user-group-t';
/*
  This service is used for all split pane messaging and subscriptions
*/

@Injectable()
export class DashboardSubService {
  private splitPaneTitleSubject = new Subject<any>();
  private typeFilterSubject = new Subject<any>();
  private menuFilterSubject = new Subject<any>();

  constructor() {}

  changeDashboardTitle(view:any){
    this.splitPaneTitleSubject.next(view);
  }

  onDashboardTitleChange():Observable<any> {
    return this.splitPaneTitleSubject.asObservable();
  }

  changeTypeFilter(data:any){
    this.typeFilterSubject.next(data);
  }

  onTypeFilterChange():Observable<any> {
    return this.typeFilterSubject.asObservable();
  }

  changeMenuFilter(group:APIUserGroup, page:string):void {
    this.menuFilterSubject.next({group: group, page: page});
  }

  onMenuFilterChange():Observable<any> {
    return this.menuFilterSubject.asObservable();
  }
}
