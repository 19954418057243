import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { DispatcherServiceFactory } from '@trend-core/dispatcher/dispatcher.service.factory';

import { AccountGroupsTableMediator } from '../../../core/mediators/account-groups-table.mediator';


@Component({
  selector: 'account-groups',
  templateUrl: 'groups.html',
  styleUrls: ['groups.scss']
})
export class AccountGroups {
  public title = 'Groups';

  constructor(
    public router:Router,
    public dispatcherServiceFactory:DispatcherServiceFactory,
    public accountGroupsTableMediator:AccountGroupsTableMediator,
  ) {}

  onGroupsTableRowClick(e:object):void {
    this.router.navigate(['/accounts/' + e['selected'][0].account_id + '/groups/' + e['selected'][0].group_id + '/telemetry']);
  }
}
