import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

import { APIUser } from '../types/api/api-user-t';
import { APIVerifiedUser } from '../types/api/api-verified-user-t';
import { APIPendingUser } from '../types/api/api-pending-user-t';
import { APIValidatingUser } from '../types/api/api-validating-user-t';
import { APICreatingUser } from '../types/api/api-creating-user-t';
import { APIUserRoles } from '../types/api/api-user-roles-t';


@Injectable()
export class User {
  public user:APIUser;
  private userEndpoint:string = this.api.url() + 'accounts/users/@me';
  private getAllPendingUserEndpoint:string = this.api.url() + 'accounts/@all/pending_users';
  private getAllVerifiedUserEndpoint:string = this.api.url() + 'accounts/@all/users';
  private verifiedUserEndpoint:string = this.api.url() + 'accounts/users';
  private userRolesEndpoint:string = this.api.url() + 'accounts/user-roles';
  private getUserPromise:Promise<APIUser>;

  constructor(
    private http:HttpClient,
    private api:APIUrlService
  ) {}

  initUser() {
    this.getUserPromise = new Promise((resolve, reject) => {
      this.fetchUser().subscribe((user) => {
        this.user = user;
        resolve(user);
      });
    });
  }

  public resetUser() {
    this.user = null;
  }

  fetchUser() {
    return this.http.get<APIUser>(this.userEndpoint);
  }

  getUser():Promise<APIUser> {
    if(!this.user) {
      this.initUser();
    }

    return this.getUserPromise;
  }

  getTotalUsersCountByAccountId(account_id:string) {
    return new Promise((resolve, reject) => {
      forkJoin(
        this.getVerifiedUsers(),
        this.getPendingUsers()
      ).subscribe(([verifiedUsers, pendingUsers]) => {
        let totalAccountUsers:number = 0;
        for(let i=0; i<verifiedUsers.length; i++) {
          if(verifiedUsers[i]['account_id'] == account_id) {
            totalAccountUsers++;
          }
        }
        for(let i=0; i<pendingUsers.length; i++) {
          if(pendingUsers[i]['account_id'] == account_id) {
            totalAccountUsers++;
          }
        }

        resolve(totalAccountUsers);
      }, (err:object) => {
        console.error('User | getTotalUsersCountByAccountId failed', err);
        reject('Could not retreive total number of account users');
      });
    })
  }

  createUser(newUser:APICreatingUser, accountId:string) {
    let accountPendingUserEndpoint:string = this.api.url() + 'accounts/' + accountId + '/pending_users';
    return this.http.post<any>(accountPendingUserEndpoint, newUser);
  }

  getVerifiedUsers():Observable<APIVerifiedUser[]> {
    return this.http.get<APIVerifiedUser[]>(this.getAllVerifiedUserEndpoint);
  }

  getUserRoles():Observable<APIUserRoles[]> {
    return this.http.get<APIUserRoles[]>(this.userRolesEndpoint);
  }

  updateUserRoles(account_id:string, user_id:string, role:string):Observable<any> {
    const endpoint:string = this.api.url() + 'accounts/' + account_id + '/users/' + user_id;
    return this.http.put<any>(endpoint, {
      role: role
    });
  }

  getPendingUsers():Observable<APIPendingUser[]> {
    return this.http.get<APIPendingUser[]>(this.getAllPendingUserEndpoint);
  }

  validatePendingUser(pendingUser:APIValidatingUser):Observable<any> {
    return this.http.post<any>(this.verifiedUserEndpoint, pendingUser);
  }

  deletePendingUser(pendingUser:APIPendingUser):Observable<any> {
    return this.http.delete<any>(this.api.url() + 'accounts/' + pendingUser['account_id'] + '/pending_users/' + pendingUser.pending_user_id);
  }

  deleteVerifiedUser(verifedUser:APIVerifiedUser):Observable<any> {
    return this.http.delete<any>(this.api.url() + 'accounts/' + verifedUser['account_id'] + '/users/' + verifedUser.user_id);
  }
}
