import { Subject, Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class MatDataTableService {
  constructor() {}

  private _tableReadySubject:Subject<number> = new Subject();
  private _goToFirstPageSubject:Subject<null> = new Subject();

  public onTableReady():Observable<number> {
    return this._tableReadySubject.asObservable();
  }

  public triggerTableReady(rowLength:number):void {
    this._tableReadySubject.next(rowLength);
  }

  public onFirstPageRequest():Observable<null> {
    return this._goToFirstPageSubject.asObservable();
  }

  public goToFirstPage():void {
    this._goToFirstPageSubject.next();
  }
}
