import { Component } from '@angular/core';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { ModalService } from '@trend-common/modals/modal.service';
import { WindowService } from '@trend-core/trend/window';

import { VerifiedUsersTableMediator } from '../../../../core/mediators/verified-users-table.mediator';
import { DeleteVerifiedUserModal } from '../../../modals/delete-verified-user-modal/delete-verified-user-modal';
import { ChangeUserRoleModal } from '../../../modals/change-user-role-modal/change-user-role-modal';
import { AccountVerifiedUsersTableMediator } from '../../../../core/mediators/account-verified-users-table.mediator';

import { APIVerifiedUser, APIUserRole } from '../../../../core/types/api/api-verified-user-t';

@Component({
  selector: 'verified-user-details',
  templateUrl: './verified-user-details.html',
  styleUrls: ['./verified-user-details.scss']
})
export class VerifiedUserDetailsDrawer {
  public data:APIVerifiedUser;
  public verificationUrl:string;

  constructor(
    private apiUrlService:APIUrlService,
    private modal:ModalService,
    private verifiedUsersTableMediator:VerifiedUsersTableMediator,
    private accountVerifiedUsersTableMediator:AccountVerifiedUsersTableMediator,
    private windowService:WindowService
  ) {}

  ngOnInit():void {
    this.data = this['data'];
    this.verificationUrl = this.apiUrlService.dashboardUrl() + 'create-account/' + this['data']['verification_token'];
  }

  private _postDeletePendingUser():void {
    if(this.windowService.getWindow().location.href.indexOf('accounts') === -1) {
      // we're on the all users page
      this.verifiedUsersTableMediator['reloadTable']();
    } else {
      // we're on the account users page
      this.accountVerifiedUsersTableMediator['reloadTable']();
    }

    this.accountVerifiedUsersTableMediator['drawerService']['close']();
  }

  private _postUserRoleChange(newUserRole:APIUserRole):void {
    if(this.windowService.getWindow().location.href.indexOf('accounts') === -1) {
      // we're on the all users page
      this.verifiedUsersTableMediator['reloadTable']();
    } else {
      // we're on the account users page
      this.accountVerifiedUsersTableMediator['reloadTable']();
    }

    this.data['role'] = newUserRole;
  }

  public openDeleteVerifiedUserModal():void {
    this.modal.openModal(
      DeleteVerifiedUserModal, {
        data: {
          afterSuccessFn: this._postDeletePendingUser.bind(this),
          verifiedUser: this.data
        }
      }
    );
  }

  public openChangeUserRoleModal():void {
    this.modal.openModal(
      ChangeUserRoleModal, {
        data: {
          afterSuccessFn: this._postUserRoleChange.bind(this),
          verifiedUser: this.data
        }
      }
    );
  }
}
