import { Component, OnInit } from '@angular/core';

import { User } from '../../../core/stores/user';
import { ModalService } from '@trend-common/modals/modal.service';
import { DrawerService } from '../../../common/drawer/drawer.service';
import { PagesParamsStore } from '../../pages-params.store';
import { DispatcherServiceFactory } from '@trend-core/dispatcher/dispatcher.service.factory';

import { AddPendingUserModal } from '../../../common/modals/add-pending-user-modal/add-pending-user-modal';

import { AccountPendingUsersTableMediator } from '../../../core/mediators/account-pending-users-table.mediator';
import { AccountVerifiedUsersTableMediator } from '../../../core/mediators/account-verified-users-table.mediator';

import { PendingUserDetailsDrawer } from '../../../common/drawer/drawers/pending-user-details/pending-user-details';
import { PendingUserDetailsMediator } from '../../../core/mediators/pending-user-details.mediator';

import { VerifiedUserDetailsDrawer } from '../../../common/drawer/drawers/verified-user-details/verified-user-details';
import { VerifiedUserDetailsMediator } from '../../../core/mediators/verified-user-details.mediator';

import { MatDataTableCols } from '@trend-common/mat-data-table/mat-data-table-colsDef-t';

@Component({
  selector: 'account-users',
  templateUrl: 'users.html',
  styleUrls: ['users.scss']
})
export class AccountUsers implements OnInit {
  public totalUserCount:number;
  public accountIsLoading:boolean;

  public verifiedCols:string[] = [ 'email', 'first_name', 'last_name', 'account_id', 'created_on' ];
  public verifiedColsDef:MatDataTableCols;

  constructor(
    public accountPendingUsersTableMediator:AccountPendingUsersTableMediator,
    public accountVerifiedUsersTableMediator:AccountVerifiedUsersTableMediator,
    public pendingUserDetailsMediator:PendingUserDetailsMediator,
    public verifiedUserDetailsMediator:VerifiedUserDetailsMediator,
    private modal:ModalService,
    private pagesParamsStore:PagesParamsStore,
    private dispatcherServiceFactory:DispatcherServiceFactory,
    private user:User
  ) {}

  ngOnInit():void {
    this.verifiedColsDef = [
      {
        colId: 'email',
        colHeader: 'Email'
      },
      {
        colId: 'first_name',
        colHeader: 'First Name'
      },
      {
        colId: 'last_name',
        colHeader: 'Last Name'
      },
      {
        colId: 'account_id',
        colHeader: 'Account ID'
      },
      {
        colId: 'created_on',
        colHeader: 'Created On'
      }
    ];

    this.accountIsLoading = true;
    let pageParams = this.pagesParamsStore.getParams();

    if(pageParams['account_id']) {
      this.user.getTotalUsersCountByAccountId(pageParams['account_id']).then((totalUserCount:number) => {
        this.totalUserCount = totalUserCount;
      });
    }
  }

  openAddPendingUserModal():void {
    this.modal.openModal(
      AddPendingUserModal, {
        data: {
          modalTitle: 'Add User to Account',
          account_id: this.pagesParamsStore.getParams()['account_id'],
          afterSuccessFn: this.postAddPendingUser.bind(this)
        }
      }
    )
  }

  postAddPendingUser():void {
    this.accountPendingUsersTableMediator['reloadTable']();
  }

  onTabChange():void {
    window.dispatchEvent(new Event('resize'));
  }

  onVerifiedUsersTableSelectRow(e:object) {
    this.dispatcherServiceFactory.getServiceInstance(DrawerService, 'mainDrawer', "VerifiedUserDetailsMediator").forceUpdateBodyInto(e['selected'][0], VerifiedUserDetailsDrawer);
  }

  onPendingUsersTableSelectRow(e:object) {
    this.dispatcherServiceFactory.getServiceInstance(DrawerService, 'mainDrawer', "PendingUserDetailsMediator").forceUpdateBodyInto(e['selected'][0], PendingUserDetailsDrawer);
  }
}
