import { Component, OnInit, OnDestroy  } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import jsonurl from 'json-url';

import { Account } from '../../core/stores/account';
// import { User } from '../../core/stores/user';

import { PagesParamsStore } from '../pages-params.store';

import { AuthService } from '@trend-common/auth/auth.service';
import { APIUrlService } from '@trend-core/api/api-url.service';
import { BreadcrumbsService } from '../../common/breadcrumbs/breadcrumbs.service';
import { ModalService } from '@trend-common/modals/modal.service';
import { BillingService } from '@trend-core/trend/billing';
import { WindowService } from '@trend-core/trend/window';
import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';

import { ConfirmModal } from '@trend-common/modals/confirm-modal/confirm-modal';
import { AccountFeatureFlagModal } from '../../common/modals/account-feature-flags-modal/account-feature-flags-modal';

import { ChangeAccountBillingModal } from '../../common/modals/change-account-billing-modal/change-account-billing-modal';


@Component({
  selector: 'account',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'account.html',
  styleUrls: ['account.scss']
})
export class AccountPage implements OnInit, OnDestroy {
  public currentAccount:object;
  public accountIsLoading:boolean;
  public currentAccountUsageLoaded:boolean = false;
  public currentAccountUsage:object = {};
  public currentAccountUsageNotSet:boolean = true;
  public currentAccountUsageError:boolean = false;
  public isChangingAccountName:boolean = false;

  public accountNameForm:FormGroup;
  public canDelete:boolean = true;
  public redImpersonate:boolean = false;
  public xdrIsLoaded:boolean = false;
  public xdr:boolean;

  private _account_id:string;
  public get account_id():string {
    return this._account_id;
  }
  public set account_id(account_id:string) {
    this._account_id = account_id;
  }

  private _activeTab:string;
  public get activeTab():string {
    return this._activeTab;
  }
  public set activeTab(activeTab:string) {
    this._activeTab = activeTab;
  }

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private pagesParamsStore:PagesParamsStore,
    private account:Account,
    private apiUrlService:APIUrlService,
    private authService:AuthService,
    private breadcrumbsService:BreadcrumbsService,
    private modalService:ModalService,
    private billingService:BillingService,
    private windowService:WindowService,
    private feedbackMessagesService:FeedbackMessagesService,
    // private user:User
  ) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this._setActiveTab();
      }
    });
  }

  ngOnInit():void {
    this.accountIsLoading = true;
    this.route.params.subscribe((params:object) => {
      if(params['account_id']) {
        this.account_id = params['account_id'];
        this.account.getAccount(params['account_id']).subscribe(account => {
          this.currentAccount = account;
          this.accountIsLoading = false;

          this.accountNameForm = new FormGroup({
            account_name: new FormControl({value: account.tenant_name, disabled: false})
          });

          this.breadcrumbsService.updateBreadcrumbs([
            {
              text:'Accounts',
              link: '/accounts'
            }, {
              text: account['tenant_name'],
              link: ''
            }
          ]);
        })

        this._fetchAccountSubscription();
      }

      this.pagesParamsStore.setParams(params);
    });

    // this.user.getUser().then(user => {
    //   if(user['role'] === 'trend_super_admin') {
    //       this.canDelete = true;
    //   }
    // });

    if(this.windowService.getWindow().location.href.indexOf('kontroller.app-protect.trendmicro.com') > -1) {
      this.redImpersonate = true;
    }
  }

  ngOnDestroy():void {
    this.currentAccountUsage = {};
  }

  private _setActiveTab():void {
    this.activeTab = this.route.children[0] ? this.route.children[0].routeConfig.path : 'groups';
  }

  private _fetchAccountSubscription():void {
    this.currentAccountUsageNotSet = false;
    this.currentAccountUsageError = false;

    this.billingService.fetchAccountsUsage(this.account_id).subscribe((usage:object) => {
      this.currentAccountUsage = {};
      this.currentAccountUsage['licenses'] = usage['subscribed_licenses'];
      this.currentAccountUsage['updatedOn'] = usage['updated_on'];
      this.currentAccountUsageLoaded = true;
    }, (err:HttpErrorResponse) => {
      if(err.status === 404) {
        this.currentAccountUsageNotSet = true;
      } else {
        this.currentAccountUsageError = true;
      }
      this.currentAccountUsageLoaded = true;
    });
  }

  private _compressJson(algoName:string, obj:object) {
    return jsonurl(algoName).compress(obj);
  }

  public impersonateAccount(viewMode:string, isRed:boolean = false):void {
    let dashboardParams:object = {
      account_id: this.account_id,
      auth_token: this.authService.getToken(),
      tenant_name: this.currentAccount['tenant_name'],
      api_endpoint: localStorage.getItem('c1as-api-endpoint')
    };

    dashboardParams['access_type'] = viewMode === 'read-only' ? 'view' : 'update';

    // Refresh the auth token before making reaching the dashboard to avoid 401 in the dashboard.
    this.authService.refreshToken().subscribe(() => {
      this._compressJson('lzstring', dashboardParams).then((account_access:string) => {
        // Use when dev.
        // this.windowService.getWindow().open('http://localhost:4800/external#/impersonate/' + account_access, "_blank");
        this.windowService.getWindow().open(this.apiUrlService.dashboardUrl(true, isRed) + 'impersonate/' + account_access, "_blank");
      });
    });
  }

  public changeSubscription():void {
    this.modalService.openModal(
      ChangeAccountBillingModal,
      {
        data: {
          account: this.currentAccount,
          currentLicenseCount: this.currentAccountUsage['licenses'],
          onSuccessFn: this._fetchAccountSubscription.bind(this)
        }
      }
    )
  }

  private _confirmDeleteAccount() {
    this.account.deleteAccount(this.currentAccount['account_id']).subscribe(() => {
      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Account successfully deleted.',
        text: 'The account and all associated ressources were successfully deleted.'
      });
      this.router.navigate(['accounts']);
    }, (error:object) => {
      if(error['error']['status'].indexOf('409 Conflict') !== -1) {
        this.feedbackMessagesService.add({
          type: 'error',
          title: 'Account could not be deleted',
          text: 'All groups and users within the account must be deleted before deleting the account.'
        });
      } else {
        this.feedbackMessagesService.add({
          type: 'error',
          title: 'Something went wrong',
          text: 'The account could not be deleted at this time, please try again later.'
        });
      }
    });
  }

  public openAccountFeatureFlagsModal():void {
    this.modalService.openModal(
      AccountFeatureFlagModal, {
        data: {
          accountId: this._account_id
        }
      }
    );
  }

  public openDeleteAccountModal() {
    // this.user.getUser().then(user => {
    //   if(user['role'] !== 'trend_super_admin') {
    //     this.feedbackMessagesService.add({
    //       type: 'error',
    //       title: 'Action not allowed',
    //       text: 'Insufficient privileges.'
    //     });
    //
    //     return;
    //   }

      this.modalService.openModal(
        ConfirmModal, {
          data: {
            title: 'Delete Account',
            confirmText: 'Are you sure you want to delete the account ' + this.currentAccount['tenant_name'] + ' (' + this.currentAccount['account_id'] + ')' + '? Deleting the account is irreversible and will delete all of its related ressources.',
            confirmButtonColor: 'warn',
            confirmFunc: this._confirmDeleteAccount.bind(this)
          }
        }
      );
    // });
  }

  public showChangeAccountNameInput():void {
    this.isChangingAccountName = true;
  }

  public changeAccountName():void {
    this.account.updateAccountName(this._account_id, this.accountNameForm.controls.account_name.value).subscribe((account) => {
      this.currentAccount = account;
      this.isChangingAccountName = false;

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Operation success.',
        text: 'The account name was changed.'
      });
    }, err => {
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Operation failed.',
        text: 'The account name could not be changed.'
      });
    });
  }

  public cancelChangeAccountNameInput():void {
    this.isChangingAccountName = false;
  }
}
