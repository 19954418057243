<h1 mat-dialog-title>{{ modalTitle || 'Add Pending User' }}</h1>

<div mat-dialog-content>
  <mat-form-field *ngIf="!this.modalData['account_id'] && accounts" appearance="outline">
    <mat-select id="new_user_account" [(ngModel)]="selectedAccountId" placeholder="Account" required>
      <mat-option *ngFor="let account of accounts" [value]="account.account_id">
        {{ account.tenant_name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>User Email</mat-label>
    <input id="new_user_email" [formControl]="emailFC" matInput required>
    <mat-error *ngIf="emailFC.invalid">{{ getInputErrorMessage(emailFC) }}</mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Cancel</button>
  <button (click)="addAccountUser()" mat-flat-button color="primary" matDialogClose [disabled]="emailFC.status == 'INVALID'">Create</button>
</div>
