import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIUser } from '@trend-core/api/api-user-t';
import { APIUrlService } from '@trend-core/api/api-url.service';
import { APIUserToValidate } from '@trend-core/api/api-user-to-validate-t';
import { APIUserAuth } from '@trend-core/api/api-user-auth-t';
import { APINewPendingUser } from '@trend-core/api/api-new-pending-user-t';
import { APIUserBasicInfo } from '@trend-core/api/api-user-basic-info-t';
import { APIPasswordChange } from '@trend-core/api/api-password-change-t';


@Injectable()
export class User {
  public user:APIUser;
  public onUpdateSubject:Subject<any>    = new Subject();
  private _usersEndpoint:string          = this.api.url() + 'accounts/users/';
  private _pendingUserEndpoint:string    = this.api.url() + 'accounts/pending_users';
  private _verifiedUserEndpoint:string;
  private _userEndpoint:string           = this.api.url() + 'accounts/users/@me';
  private _resetPasswordEndpoint:string;
  private _userPasswordEndpoint:string   = this.api.url() + 'accounts/users/@me/password';
  private _changePasswordEndpoint:string;
  private _getUserPromise:Promise<APIUser>;

  constructor(
    private http:HttpClient,
    private api:APIUrlService
  ) {}

  public initUser() {
    this._getUserPromise = new Promise((resolve) => {
      this._fetchUser().subscribe((user) => {
        user['guides'] = localStorage.getItem('guides');
        this.onUpdateSubject.next(user);
        this.user = user;
        resolve(user);
      });
    });
  }

  private _fetchUser():Observable<APIUser> {
    return this.http.get<APIUser>(this._userEndpoint);
  }

  public getUser():Promise<APIUser> {
    if(!this.user) {
      this.initUser();
    } else if(this.user.role.indexOf('impersonated') > -1) {
      this.user['guides'] = localStorage.getItem('guides');
      return Promise.resolve(this.user);
    }

    return this._getUserPromise;
  }

  public resetUser():void {
    this.user = null;
  }

  public onUpdate():Observable<any> {
    return this.onUpdateSubject.asObservable();
  }

  public addUserToAccount(newUser:APINewPendingUser):Observable<any> {
    return this.http.post(this._pendingUserEndpoint, newUser);
  }

  public validateUser(pendingUser:APIUserToValidate):Observable<APIUserAuth> {
    this._verifiedUserEndpoint = this.api.url() + 'accounts/auth/activate';
    return this.http.post<APIUserAuth>(this._verifiedUserEndpoint, pendingUser);
  }

  public resetPassword(email:string, region:string):Observable<null> {
    this._resetPasswordEndpoint = this.api.url(region) + 'accounts/auth/reset';

    return this.http.post<any>(this._resetPasswordEndpoint, {
      email: email
    });
  }

  public changePasswordInternal(passwordChangePayload:APIPasswordChange):Observable<any> {
    return this.http.put<APIUser>(this._userPasswordEndpoint, passwordChangePayload);
  }

  public changePasswordExternal(newPassword:string):Observable<APIUserAuth> {
    this._changePasswordEndpoint = this.api.url() + 'accounts/auth/reset/password';

    return this.http.post<APIUserAuth>(this._changePasswordEndpoint, {
      password: newPassword
    });
  }

  public updateInformation(userInfo:APIUserBasicInfo):Observable<any> {
    return this.http.put(this._userEndpoint, userInfo);
  }

  public removeUserFromAccount(userId:string):Observable<any> {
    return this.http.delete(this._usersEndpoint + userId);
  }

  public removePendingUser(pendingUserId:string):Observable<any> {
    return this.http.delete(this._pendingUserEndpoint + '/' + pendingUserId);
  }

  public sendGuidesCompletion(seenGuideName:string):Observable<any> {
    return this.http.post(this._userEndpoint + '/seen_guides', {
      name: seenGuideName
    });
  }

  public canActivate() {
    return new Promise((resolve) => {
      this.getUser().then((user:APIUser) => {
        if(user.role !== 'user') {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    })
  }
}
