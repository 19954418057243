import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, Subscription, forkJoin, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { APIUrlService } from '../api/api-url.service';
import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';

import { APIUserGroup } from '../api/api-user-group-t';
import { APIUserGroupStatus } from '../api/api-user-group-status-t';
import { APIUserGroupAgentsStatus } from '../api/api-user-group-agents-status-t';
import { APIUserGroupInfo } from '../api/api-user-group-info-t';
import { FeedbackMessage } from '../feedback-message-t';

@Injectable()
export class Groups {
  public userGroupList:Array<APIUserGroup> = [];
  public areGroupsInited:boolean = false;
  private groupsEndpoint:string;
  private statusEndpoint:string;
  private agentsStatusEndpoint:string;
  private groupsUpdateSubject = new Subject<any>();
  private groupsLoadedSubject = new Subject<any>();

  private groupsPollingSub:Subscription;

  private pollingTimer = timer(10000, 10000);
  private _groupsPollingActive:boolean = false;
  private _d$:Subject<any> = new Subject();

  constructor(
    private http:HttpClient,
    private api:APIUrlService,
    private feedbackMessagesService:FeedbackMessagesService,
    private router:Router
  ){};

  public initUserGroups() {
    this.groupsEndpoint = this.api.url() + 'accounts/groups';
    this.statusEndpoint = this.api.url() + 'events/summary';
    this.agentsStatusEndpoint = this.api.url() + 'agent/groups/status';

    this.getUserGroups(true);

    this._groupsPollingActive = true;
    this.pollingTimer.pipe(takeUntil(this._d$)).subscribe(() => {
      this.getUserGroups();
    });
  }

  public mergeStatusInGroups(groupList:APIUserGroup[], statusList:APIUserGroupStatus[], groupsAgentStatus:APIUserGroupAgentsStatus[]):APIUserGroup[] {
    let statusListByGroupId:object = {};
    for(var i=0; i<statusList.length; i++) {
      statusListByGroupId[statusList[i].group_id] = statusList[i].status;
    }

    let agentStatusListByGroupId:object = {};
    for(var i=0; i<groupsAgentStatus.length; i++) {
      agentStatusListByGroupId[groupsAgentStatus[i].group_id] = groupsAgentStatus[i].status;
    }

    for(var j=0; j<groupList.length; j++) {
      if(statusListByGroupId.hasOwnProperty(groupList[j].group_id)) {
        if(statusListByGroupId[groupList[j].group_id] !== 'idle') {
          groupList[j].status = statusListByGroupId[groupList[j].group_id];
        } else {
          if(agentStatusListByGroupId.hasOwnProperty(groupList[j].group_id)) {
            groupList[j].status = agentStatusListByGroupId[groupList[j].group_id];
          } else {
            groupList[j].status = 'unknown';
          }
        }
      }
    }

    return groupList;
  }

  public cancelGroupsPolling():void {
    this._d$.next();
    this._d$.complete();
    this.userGroupList = [];
    this._groupsPollingActive = false;
  }

  public isGroupsPollingActive():boolean {
    return this._groupsPollingActive;
  }

  public isGroupsInitDone():boolean {
    return this.areGroupsInited;
  }

  public onGroupsLoaded():Observable<any> {
    return this.groupsLoadedSubject.asObservable();
  }

  public getGroups():APIUserGroup[] {
    return this.userGroupList;
  }

  public getUserGroups(initialLoad?:boolean):void {
    let getUserGroupsSub = forkJoin(
      this.getGroupList(),
      this.getGroupsStatus(),
      this.getGroupsAgentsStatus()
    ).subscribe(data => {
      getUserGroupsSub.unsubscribe();
      let groupList = data[0];
      let groupsStatus = data[1];
      let groupsAgentStatus = data[2];
      // Merge status in groups list.
      groupList = this.mergeStatusInGroups(groupList, groupsStatus, groupsAgentStatus);

      if(!this.groupsObjectIsDifferent(groupList) && groupList.length > 0) {
        return;
      }

      this.userGroupList = this.addStatusLabel(groupList);
      this.sortGroups(true);
      this.groupsUpdateSubject.next(this.userGroupList);
      if(initialLoad) {
        this.areGroupsInited = true;
        this.groupsLoadedSubject.next(this.userGroupList);
      }
    }, (error:object) => {
      this.groupsUpdateSubject.error(error);
      if(initialLoad) this.groupsLoadedSubject.error(error);
    });
  }

  public sortGroups(isAscending:boolean) {
    this.userGroupList.sort(function(a, b){
      if(a.name < b.name) { return isAscending ? -1 : 1; }
      if(a.name > b.name) { return isAscending ? 1 : -1; }
      return 0;
    });
  }

  public getGroupNameByGroupId(groupId:string):string {
    for(var i=0; i<this.userGroupList.length; i++) {
      if(this.userGroupList[i]['group_id'] === groupId) {
        return this.userGroupList[i]['name'];
      }
    }
  }

  public addStatusLabel(menuItems:Array<APIUserGroup>):Array<APIUserGroup> {
    // Add status label to group object based on the status prop.
    for(var i=0; i < menuItems.length; i++) {
      switch(menuItems[i]['status']) {
        case 'inactive':
          menuItems[i]['statusLabel'] = 'Inactive';
        break;
        case 'active':
          menuItems[i]['statusLabel'] = 'Ok';
        break;
        case 'recovered':
          menuItems[i]['statusLabel'] = 'Attacked';
        break;
        case 'ongoing':
          menuItems[i]['statusLabel'] = 'Attacks ongoing';
        break;
        case 'pending':
          menuItems[i]['statusLabel'] = 'Agent not yet connected';
        break;
        case 'unknown':
          menuItems[i]['statusLabel'] = 'N/A';
        break;
        default:
          menuItems[i]['statusLabel'] = menuItems[i]['status'];
        break;
      }
    }

    return menuItems;
  }

  public groupsObjectIsDifferent(newGroupsObject:object):boolean {
    if(newGroupsObject['length'] !== this.userGroupList.length) {
      return true;
    } else {
      for(var i=0; i < newGroupsObject['length']; i++) {
        for(var j=0; j < this.userGroupList.length; j++) {
          if(newGroupsObject[i].group_id == this.userGroupList[j].group_id) {
            // check for every group status.
            if(newGroupsObject[i].name != this.userGroupList[j].name || (newGroupsObject[i].status != this.userGroupList[j].status || (newGroupsObject[i].activated_on != this.userGroupList[j].activated_on))) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  public getGroupByGroupId(id:string):APIUserGroup {
    for(let a = 0; a < this.userGroupList.length; a++){
      if(id === this.userGroupList[a].group_id){
        return this.userGroupList[a];
      }
    }
  }

  public selectGroup(groupId:string, adding?:boolean):void {
    if(this.areGroupsInited) {
      this._doSelectGroup(groupId, adding);
    } else {
      this.onGroupsLoaded().pipe(takeUntil(this._d$)).subscribe((groups) => {
        this._doSelectGroup(groupId, adding);
      });
    }
  }

  private _doSelectGroup(groupId:string, adding?:boolean):void {
    for(var i=0; i<this.userGroupList.length; i++) {
      if(this.userGroupList[i].group_id === groupId || adding) {
        this.userGroupList[i]['isSelected'] = true;
      } else {
        this.userGroupList[i]['isSelected'] = false;
      }
    }
    // this.groupsUpdateSubject.next(this.userGroupList);
  }

  public handleGroupNotFound(errorObj:HttpErrorResponse, routeName?:string):void {
    const redirectRouteName = routeName || 'events';

    let messageConfig:FeedbackMessage = {
      type: 'error',
      text: 'Please contact support if the issue persists.',
      title: 'Something went wrong.',
      ttl: 5000
    }

    if(errorObj.status == 404) {
      messageConfig.title = 'Group not found.'
      messageConfig.text = 'The page could not be loaded, the requested group id was not found.'
    }

    this.feedbackMessagesService.add(messageConfig);
    this.router.navigate([redirectRouteName]);
  }

  public getGroupStatus(groupId:string):string {
    for(var i=0; i<this.userGroupList.length; i++) {
      if(this.userGroupList[i]['group_id'] === groupId) {
        return this.userGroupList[i]['status'];
      }
    }
  }

  public isGroupContainer(groupId:string):boolean {
    for(var i=0; i<this.userGroupList.length; i++) {
      if(this.userGroupList[i]['group_id'] === groupId) {
        return this.userGroupList[i]['metadata'] && Object.keys(this.userGroupList[i]['metadata']).length > 0;
      }
    }
  }

  public createGroup(group:object):Observable<any> {
    let payload = group;

    return this.http.post<object[]>(this.groupsEndpoint, payload);
  }

  public getGroupList():Observable<APIUserGroup[]> {
    return this.http.get<APIUserGroup[]>(this.groupsEndpoint);
  }

  public getGroupsStatus():Observable<APIUserGroupStatus[]> {
    return this.http.get<APIUserGroupStatus[]>(this.statusEndpoint);
  }

  public getGroupsAgentsStatus():Observable<APIUserGroupAgentsStatus[]> {
    return this.http.get<APIUserGroupAgentsStatus[]>(this.agentsStatusEndpoint);
  }

  public getGroupInfo(groupId:string):Observable<any> {
    return this.http.get<APIUserGroupInfo>(this.groupsEndpoint + '/' + groupId);
  }

  public onGroupsUpdate():Observable<any> {
    return this.groupsUpdateSubject.asObservable();
  }

  public deleteGroup(groupId:string):Observable<any> {
    return this.http.delete<null>(this.groupsEndpoint + '/' + groupId);
  }

  public saveGroupInfo(groupId:string, data:object):Observable<any> {
    return this.http.put(this.groupsEndpoint + '/' + groupId, data);
  }
}
