<button (click)="openCreateAccountModal()" mat-raised-button color="primary" style="margin-left: 15px; margin-top: 15px; margin-bottom: 15px;">CREATE ACCOUNT</button>

<button (click)="openAddPendingUserModal()" mat-raised-button color="primary" style="margin-left: 15px; margin-top: 15px; margin-bottom: 15px;">CREATE ACCOUNT ADMIN USER</button>

<mat-data-table
  [_colsDef]="colsDef"
  [_mediator]="accountsTableMediator"
  [_searchConfig]="searchConfig"
  [_tableClass]="'accountsTable'"
  [_onClickEvent]="onRowClick">
</mat-data-table>

<ng-template #actionsTmpl let-accountId="account_id" let-accountTenant="tenant_name">
  <button id="deleteButton-{{ accountId }}"
          class="deleteAccountButton is-shown"
          color="warn"
          (click)="openDeleteAccountModal(accountId, accountTenant, $event)"
          mat-mini-fab
          aria-label="Delete account">
    <mat-icon>delete</mat-icon>
  </button>
</ng-template>
