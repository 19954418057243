import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Platform } from '@angular/cdk/platform';
import { ErrorStateMatcher } from '@angular/material/core';

import { Breadcrumbs } from './breadcrumbs';
import { BreadcrumbsService } from './breadcrumbs.service';


// import {
//   MatIconModule,
//   MatButtonModule,
//   MatMenuModule
// } from '@angular/material';
//
// let matModuleArray:any[] = [
//   MatIconModule,
//   MatButtonModule,
//   MatMenuModule
// ];

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    Breadcrumbs
  ],
  providers: [
    Platform,
    ErrorStateMatcher,
    BreadcrumbsService
  ],
  entryComponents: [

  ],
  exports: [
    Breadcrumbs
  ]
})
export class BreadcrumbsModule {}
