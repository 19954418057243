<div class="accountTelemetry">
  <mat-data-table
    [_tableDataSubject]="chartDataSubject"
    [_colsDef]="colsDef"
    [_tableClass]="'telemetryTable'"
    [_searchConfig]="searchConfig">
  </mat-data-table>
</div>

<ng-template #groupTmpl let-groupData="group">
  <a class="groupCol_link" routerLink="/accounts/{{ groupData.accountId }}/groups/{{ groupData.id }}/telemetry">{{ groupData.name || 'No Group Name' }}</a>
  <span class="groupCol_id">{{ groupData.id }}</span>
</ng-template>
<ng-template #exceptionsTmpl let-chartData="exceptions" let-group="group">
  <data-charts
    [_chartId]="group.id+'-exceptions'"
    [_chartGroup]="group.id"
    [_chartType]="'line'"
    [_chartForeColor]="'#AFB7C8'"
    [_chartBGColor]="'#fff'"
    [_height]="105"
    [_showHeader]="false"
    [_showLegend]="false"
    [_enableAnimations]="false"
    [_useNativeZoom]="false"
    [_data]="chartData"
    [_strokeConfig]="{ width: [1,1,1] }"
    [_disableCustomDrilldown]="true"
    [_useNativeZoom]="false"
    [_xaxis]="xaxisConfig"
    [_yaxis]="yaxisConfig"
    [_xAxisProp]="'timestamp'"
    [_xAxisType]="'datetime'"
    [_yAxisProp]="['total_transactions']"
    [_showToolbar]="false"
    [_showGridXaxisLines]="false"
    [_showGridYaxisLines]="false">
  </data-charts>
</ng-template>
<ng-template #requestsTmpl let-chartData="requests" let-group="group">
  <data-charts
    [_chartId]="group.id+'-requests'"
    [_chartGroup]="group.id"
    [_chartType]="'line'"
    [_chartForeColor]="'#AFB7C8'"
    [_chartBGColor]="'#fff'"
    [_height]="105"
    [_showHeader]="false"
    [_showLegend]="false"
    [_enableAnimations]="false"
    [_useNativeZoom]="false"
    [_data]="chartData"
    [_strokeConfig]="{ width: [1,1,1] }"
    [_disableCustomDrilldown]="true"
    [_useNativeZoom]="false"
    [_xaxis]="xaxisConfig"
    [_yaxis]="yaxisConfig"
    [_xAxisProp]="'timestamp'"
    [_xAxisType]="'datetime'"
    [_yAxisProp]="['total_transactions']"
    [_showToolbar]="false"
    [_showGridXaxisLines]="false"
    [_showGridYaxisLines]="false">
  </data-charts>
</ng-template>
