import { NgModule } from '@angular/core';

import { DispatcherParamsStoreService } from './dispatcher-params-store.service';
import { DispatcherServiceFactory } from './dispatcher.service.factory';

@NgModule({
  imports: [

  ],
  providers: [
    DispatcherParamsStoreService,
    DispatcherServiceFactory
  ],
  declarations: [

  ],
  exports: [

  ]
})
export class DispatcherModule {}
