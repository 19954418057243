<!-- <h1 mat-dialog-title>{{ data.title || 'Please Confirm' }}</h1>

<div mat-dialog-content>
  {{ data.confirmText || 'Are you sure you want to proceed with this operation?' }}
</div>

<div mat-dialog-actions>
  <button class="button button--secondary" (click)="cancelFunc()">Cancel</button>
  <button class="button button--primary" (click)="confirmFunc()">{{ data.confirmButtonLabel || 'Confirm' }}</button>
</div>

 -->
<div>
  <header>
    <h3>{{ data.title || 'Please Confirm' }}</h3>
  </header>

  <section>
    {{ data.confirmText || 'Are you sure you want to proceed with this operation?' }}
  </section>

  <footer>
    <button id="confirmModalConfirmButton" mat-raised-button color="{{ confirmButtonColor }}" class="button button--primary" (click)="confirmWrapper()">{{ data.confirmButtonText || 'Confirm' }}</button>
    <button id="confirmModalCancelButton" mat-raised-button class="button button--secondary" (click)="cancelFunc()">Cancel</button>
  </footer>
</div>
