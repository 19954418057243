<h1 mat-dialog-title>Change User Role</h1>

<div mat-dialog-content>
  <div *ngIf="!userRoles" class="userRolesOverlay">
    <span *ngIf="!getUserRoleError">Loading user roles...</span>
    <span *ngIf="getUserRoleError">We could not get the user roles at the moment. <span class="retryGetUserRoles" (click)="getUserRoles()">Retry</span>.</span>
  </div>

  <mat-form-field *ngIf="userRoles" appearance="outline">
    <mat-select id="user" [(ngModel)]="selectedUserRole" (ngModelChange)="onSelectedRoleChange($event)" placeholder="Select a Role" required>
      <mat-option *ngFor="let role of userRoles" [value]="role">
        {{ role.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <span>{{ selectedUserRole ? selectedUserRole.description : 'Please select a role to see the role description.' }}</span>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Cancel</button>
  <button (click)="changeUserRole()" mat-flat-button color="primary" matDialogClose>Apply</button>
</div>
