import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'santizePipe'})
export class SanitizePipe implements PipeTransform {
  transform(value:string) {
    return value.replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#x27;')
                .replace(/\//g, '&#x2F;');
  }
}
