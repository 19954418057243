import { Injector } from "@angular/core";
import { Observable, Subscription, Subscriber, Subject } from 'rxjs';

import { MediatorInterface } from './mediator-i';
import { BucketedEntry } from '../bucketed-entry-t';
import { DispatcherConfig } from '../dispatcher/dispatcher-config-t';
import { DispatcherMap } from '../dispatcher/dispatcher-map-t';

import { DispatcherServiceFactory } from '../dispatcher/dispatcher.service.factory';
import { DispatcherParamsStoreService } from '../dispatcher/dispatcher-params-store.service';
import { HttpClient } from '@angular/common/http';
import { TimeframeService } from '@trend-common/timeframe/timeframe.service';
import { PollingService } from '@trend-common/polling.service';
import { FilterListServiceFactory } from '@trend-common/filter-list/filter-list.service.factory';
import { PaginationService } from '@trend-common/pagination/pagination.service';
import { SortingService } from '@trend-common/sorting/sorting.service';
import { DrawerService } from '@trend-common/drawer/drawer.service';
import { SearchService } from '@trend-common/search/search.service';
import { FilterListService } from '@trend-common/filter-list/filter-list.service';
import { WindowService } from '../trend/window';
import { AdapterService } from '../adapter/adapter.service';
import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';

export abstract class Mediator implements MediatorInterface {
  dispatcherServiceFactory:DispatcherServiceFactory;
  dispatcherParamsStoreService:DispatcherParamsStoreService;
  http:HttpClient;
  timeframeService:TimeframeService;
  pollingService:PollingService;
  filterListServiceFactory:FilterListServiceFactory;
  paginationService:PaginationService;
  sortingService:SortingService;
  drawerService:DrawerService;
  searchService:SearchService;
  filterListService:FilterListService;
  windowService:WindowService;
  adapterService:AdapterService;
  feedbackMessagesService:FeedbackMessagesService;

  constructor(public injector:Injector){
     this.dispatcherServiceFactory = injector.get(DispatcherServiceFactory);
     this.dispatcherParamsStoreService = injector.get(DispatcherParamsStoreService);
     this.http = injector.get(HttpClient);
     this.timeframeService = injector.get(TimeframeService);
     this.pollingService = injector.get(PollingService);
     this.filterListServiceFactory = injector.get(FilterListServiceFactory);
     this.paginationService = injector.get(PaginationService);
     this.sortingService = injector.get(SortingService);
     this.drawerService = injector.get(DrawerService);
     this.searchService = injector.get(SearchService);
     this.filterListService = injector.get(FilterListService);
     this.windowService = injector.get(WindowService);
     this.adapterService = injector.get(AdapterService);
     this.feedbackMessagesService = injector.get(FeedbackMessagesService);
  }

  abstract name;

  abstract endpoint:string = "";

  abstract adapt(data:any, xProp?:string, yProp?:string | string[], hidden?:string[]):any

  /*
    default subs
    storage of subscriptions to dispatchers, used to kill
  */
  subs:Subscription[] = [];

  /*
    default ignored
    used to store names of ignored dispatchers
  */
  ignored:string[] = [];

  /*
    Default dispatcherMap
    used to link services to service names
  */
  dispatcherMap:DispatcherMap;

  /*
    default subRegistry
    used to house various subs that should be periodically dumped
    and always unsubbed to avoid leaks
  */
  subRegistry:object = {};

  /*
    default $fetchForwardSubject
    used to forward fetch subscriptions to optimize mediators
    which have identical api source & timing, must be nexted
    in the mediator itself, does not happen in the baseclass
  */
  $fetchForwardSubject = new Subject<any>();

  /*
    Default kill
    Gets executed by bound component
    Kills each subscription being made by this mediator
  */
  public kill():void {
    for(let s = 0; s < this.subs.length; s++){
      this.subs[s].unsubscribe();
    }

    for(let s in this.subRegistry){
      this.subRegistry[s].unsubscribe();
    }

    this.subs = [];
    this.subRegistry = {};
    this.dispatcherMap = {};
  }

  /*
    Default fetchData
    Gets executed by request observable invoked by bound component
    Defines URL, executes http request and pushes subscription to this.subs array
    params: timeframe, timeframe object to pull date ranges from TODO: make usable by address
            subscriber, the subscriber that has subscribe, so should be nexted
  */
  public fetchData(componentInstance:any, subscriber:any, source:string, subParams?:object):void {
    const url = this.endpoint;

    if(componentInstance) componentInstance['isLoading'] = true;

    this.killSub('fetchData');

    let fetchSub = this.http.get(url).subscribe(
      (data) => {
        if(componentInstance) componentInstance['error'] = false;
        if(componentInstance) componentInstance['isLoading'] = false;
        subscriber.next(data);
      },
      (error) => {
        if(componentInstance) componentInstance['error'] = error.message;
        if(componentInstance) componentInstance['isLoading'] = false;
        // subscriber.next(data);
        subscriber.next({});
      }
    );

    this.registerSub(fetchSub, 'fetchData');
  }

  /*
    Default registerSub
    used to register a subscription to the sub registry so that we can
    properly control the amount of subs we contain
  */
  public registerSub(sub:Subscription, name:string):void {
    this.subRegistry[this.name + '.' + name] = sub;
  }

  /*
    Default getSub
    gets subscription object from subregister using the name of the mediator + sub name
  */
  public getSub(name:string):void {
    if(this.subRegistry[this.name + '.' + name]){
      return this.subRegistry[this.name + '.' + name];
    }
  }

  /*
    Default killSub
    unsubs and removes subscription object from subregister using the name of the mediator + sub name
  */
  public killSub(name:string):void {
    if(this.subRegistry[this.name + '.' + name]){
      try {
        this.subRegistry[this.name + '.' + name].unsubscribe();
        delete this.subRegistry[this.name + '.' + name];
      } catch(e){
        console.error(e);
      }
    }
  }

  /*
    Default getRequestSubject
    Usually gets executed in the HTML of the view that injects the desired component
    Returns an observable that is subscribed to by the (usually) chart component
    The observable is responsible for executing the subscriptions to the timeframe and pollingSub
    however it will first use subscribeToDispatch to acquire all default params needed to build the state

    This pattern is required to have a chart (or any component) onnload sub to the correct upstreams

    params: mediatorName, name of the mediator that's invoking the subscription, used for share params store
            dispatcherConfig, config object of which dispatchers this will need and what initial dispatcher should be triggered
  */
  public getRequestSubject(componentInstance:any, mediatorName:string, dispatcherConfig:DispatcherConfig):Observable<any> {
    //TODO: build this dispatcher map somewhere else
    this.dispatcherMap = {
      'timeframe':  this.timeframeService,
      'polling':    this.pollingService,
      'pagination': this.paginationService,
      'sorting':    this.sortingService,
      'drawer':     this.drawerService
    };

    if(dispatcherConfig.list.indexOf('type') > -1)
      this.dispatcherMap.type = this.filterListServiceFactory.new(FilterListService, 'threatTypeFilterItems', this.getName());
    if(dispatcherConfig.list.indexOf('priority') > -1)
      this.dispatcherMap.priority = this.filterListServiceFactory.new(FilterListService, 'priorityFilterItems', this.getName());
    if(dispatcherConfig.list.indexOf('mainDrawer') > -1)
      this.dispatcherMap.mainDrawer = this.dispatcherServiceFactory.new(DrawerService, 'mainDrawer', this.getName());
    if(dispatcherConfig.list.indexOf('modalDrawer') > -1)
      this.dispatcherMap.modalDrawer = this.dispatcherServiceFactory.new(DrawerService, 'modalDrawer', this.getName());
    if(dispatcherConfig.list.indexOf('mainSearch') > -1)
      this.dispatcherMap.mainSearch = this.dispatcherServiceFactory.new(SearchService, 'mainSearch', this.getName());
    if(dispatcherConfig.list.indexOf('modalSearch') > -1)
      this.dispatcherMap.modalSearch = this.dispatcherServiceFactory.new(SearchService, 'modalSearch', this.getName());
    if(dispatcherConfig.list.indexOf('pagination') > -1)
      this.dispatcherMap.pagination = this.dispatcherServiceFactory.new(PaginationService, 'tablePagination', this.getName());
    if(dispatcherConfig.list.indexOf('sorting') > -1)
      this.dispatcherMap.sorting = this.dispatcherServiceFactory.new(SortingService, 'tableSorting', this.getName());

    if(!componentInstance) {
      new Error('No component instance found for ' + mediatorName + ', loading states will not be possible.');
    }

    //this final observable that is subbed to in the subscriber component
    return new Observable(subscriber => {
      if(dispatcherConfig.list.length === 0){
        this.fetchData(componentInstance, subscriber, 'firstLoad');
      } else {
        //builds params list null map based off of dispatcher list
        /*
          like this
          params =  {
            'polling': null,
            'timeframe': null
          }
        */
        const params = dispatcherConfig.list.reduce((result, item) => {
          result[item] = null;
          return result;
        }, {});

        let dispatchSubs = [];

        for(let x = 0; x < dispatcherConfig.list.length; x++){
          if(dispatcherConfig.list[x] !== "polling"){
            dispatchSubs.push(this.subscribeToDispatcher(
              componentInstance,
              subscriber,
              mediatorName,
              params,
              dispatcherConfig.list[x],
              this.dispatcherMap[dispatcherConfig.list[x]],
              'onUpdate'
            ));
          }
        }

        //After all other dispatch parameters have been acquired
        Promise.all(dispatchSubs).then(function(){
          if(dispatcherConfig.initial) this.dispatcherMap[dispatcherConfig.initial].hardTrigger();

          if(dispatcherConfig.list.indexOf('polling') > -1){

            this.killSub('polling');

            let pollingSub = this.pollingService.onPolling().subscribe(() => {
              if(this.ignored.indexOf("polling") < 0) {
                this.fetchData(componentInstance, subscriber, 'polling', params);
              }
            });

            this.registerSub(pollingSub, 'polling');

            // this.subs.push(pollingSub);
          }

        }.bind(this));
      }
    });
  }

  /*
    Default subscribeToDispatcher
    This method first verifies if there's a different source of defaults we should be using (likely share-link)
    If we do, it asks the dispatcher to set i, it then method returns a promise to retreive the default values for a dispatcher
    and then follows by subscribing to the dispatcher itself
    params: subscriber, the component is subscribing to a dispatcher
            mediatorName, the name of the mediator that's invoking the subscription, used to populate params store
            params, the params object state
            dispatcherName, the dispatcher name the params are associated to
            dispatcherService, the dispatcher we are subbing to
            dispatcherOnUpdateFnName, the native updateFn name in the dispatcher we need to sub to
  */
  private subscribeToDispatcher(componentInstance:any, subscriber:Subscriber<any>, mediatorName:string, paramsState:any, dispatcherName:string, dispatcherService:any, dispatcherOnUpdateFnName:any):void {
    //Do we have defaults? (ie. is this a page load of a shared-link?)
    //If so, use the store service to get the defaults and update them
    //TODO: Put this in another place maybe? Dont like the fact that
    //      we have to change a dispatcher from here

    //get the defaults of the dispatcher in question and then subscribe to it's update method
    if(dispatcherService === undefined){
      console.error('Can\'t find a dispatcher to subscribe to from the mediator base.');
    }

    this.dispatcherParamsStoreService.getParams().then(()=>{
      return dispatcherService.getDefaults().then((defaults) => {
        let configDefaults = defaults,
            overwrittenDefaults = null;

        //builf pair hash to search dispatcher store for default params from the address bar
        const pairHash = this.dispatcherParamsStoreService.getPairHash({
          mediatorName: mediatorName,
          dispatcherName: dispatcherName,
          json: configDefaults
        });

        //if there is some default overwrites in the address bar, do the overwrite
        if(this.dispatcherParamsStoreService.hasDefaults()){
          overwrittenDefaults = this.dispatcherParamsStoreService.getPairParamDefaults(pairHash, dispatcherName);
          if(overwrittenDefaults){
            dispatcherService.update(overwrittenDefaults, true);
            configDefaults = overwrittenDefaults
          }
        }

        //save the current dispatchers defaults to the mediator
        paramsState[dispatcherName] = configDefaults;

        /*
          WHEN CHANGES OCCUR IN DISPATCHER
          sub to the dispatchers update method
        */
        this.killSub('subscribeToDispatcher.' + dispatcherName);

        const changeSub = dispatcherService[dispatcherOnUpdateFnName]().subscribe(newParams => {
          if(paramsState){
            //if this dispatcher is ignored, don't do anything with the params
            if(this.ignored.indexOf(dispatcherName) < 0){

              //set the params to the current mediator/component param state
              paramsState[dispatcherName] = newParams;

              //set the global params for the mediator
              this.dispatcherParamsStoreService.setParams(pairHash, paramsState);

              if((dispatcherName === 'priority' || dispatcherName === 'type') && paramsState.hasOwnProperty('pagination')) {
                this.paginationService.backToFirstPage();
              }

              this.fetchData(componentInstance, subscriber, 'paramsUpdate:'+dispatcherName, paramsState);
            }
          }
        });

        this.registerSub(changeSub, 'subscribeToDispatcher.' + dispatcherName);
      });
    });
  }

  /*
    Default reinstateDispatcher
    Removes specifica dispatcher from the ignored list
  */
  public reinstateDispatcher(name:string):void {
    if(this.ignored.indexOf(name) > -1){
      this.ignored.splice(this.ignored.indexOf(name), 1);
    }
  }

  /*
    Default reinstateAllDispatchers
    Removes all dispatchers from ignored lise
  */
  public reinstateAllDispatchers():void {
    if(this.ignored.length > 0) {
      this.ignored = [];
    }
  }

  /*
    Default ignoreDispatcher
    Adds dispatcher to ignorelist
  */
  public ignoreDispatcher(name:string):void {
    if(this.ignored.indexOf(name) < 0){
      this.ignored.push(name);
    }
  }

  public getName():string {
    return this.name;
  }
}
