import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

import { APIAccount } from '../../core/types/api/api-account-t';
import { DispatcherConfig } from '@trend-core/dispatcher/dispatcher-config-t';

import { Mediator } from '@trend-core/mediator/mediator';

import { AccountsTableData } from '../types/components/accounts-table-data-t';

import { TimezonedDatePipe } from '@trend-common/pipes/date.pipe';

@Injectable()
export class AccountsTableMediator extends Mediator {
  name = "AccountsTableMediator";
  endpoint = this.apiUrl.url() + 'accounts/account';

  constructor(
    public injector:Injector,
    private apiUrl:APIUrlService
  ) {
    super(injector);
  }

  fetchData(componentInstance:any, subscriber:any, source:string, subParams?:object):void {
    if(componentInstance) componentInstance['isLoading'] = true;

    let httpSub = this.http.get<APIAccount[]>(this.endpoint).subscribe(
      (data) => {
        subscriber.next(data);
      },
      (error) => {
        if(componentInstance) componentInstance['error'] = error.message;
        if(componentInstance) componentInstance['isLoading'] = false;
        // subscriber.next(data);
        subscriber.next({});
      }
    );

    this.subs.push(httpSub);
  }

  getRequestSubject(component:any):Observable<any> {
    let dispatcherConfig:DispatcherConfig = {
      list : [
        'pagination'
      ],
      initial: 'pagination'
    };

    return super.getRequestSubject(component, this.name, dispatcherConfig);
  }

  adapt(data:APIAccount[]):AccountsTableData {
    for(var i=0; i<data.length; i++) {
      data[i]['created_on'] = new TimezonedDatePipe().transform(data[i]['created_on']);
      data[i]['cloud_one_account_id'] = data[i]['cloud_one_account_id'] ? data[i]['cloud_one_account_id'] : 'N/A';
    }

    return data;
  }

  // Completely reloads table data from fetch
  reloadTable():void {
    this.dispatcherMap.pagination['hardTrigger']();
  }
}
