import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { Account } from '../../../core/stores/account';
import { TelemetryService } from '@trend-core/trend/telemetry.service';
import { BreadcrumbsService } from '../../../common/breadcrumbs/breadcrumbs.service';

import { MatDataTableCols } from '@trend-common/mat-data-table/mat-data-table-colsDef-t';


@Component({
  selector: 'accounts-telemetry',
  templateUrl: 'accounts-telemetry.html',
  styleUrls: ['accounts-telemetry.scss']
})
export class AccountsTelemetry {
  public cols:string[] = [ 'accountId', 'requests' ];
  public chartIsReadySubject:Subject<boolean> = new Subject();
  public chartDataSubject:Subject<any> = new Subject();

  @ViewChild('accountTmpl', {static: true}) accountTmpl : TemplateRef<any>;
  @ViewChild('requestsTmpl', {static: true}) requestsTmpl : TemplateRef<any>;

  // mat-data-table members.
  public colsDef:MatDataTableCols;
  public searchConfig = {
    label: 'acccount',
    keys: [ 'account.id', 'account.name', 'account.source', 'account.accountCloundOneId' ],
    info: 'Accounts can by filtered by Account ID, Tenant Name, Source and Cloud One Account ID',
    width: '320px'
  }
  public xaxisConfig = {
    categories: [],
    type: 'datetime',
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    labels: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  }
  public yaxisConfig = {
    labels: {
      show: false
    }
  }

  constructor(
    private breadcrumbsService:BreadcrumbsService,
    private telemetryService:TelemetryService,
    private account:Account
  ) {}

  ngOnInit():void {
    this.colsDef = [
      {
        colId: 'account',
        colHeader: 'Account',
        colTmpl: this.accountTmpl
      },
      {
        colId: 'requests',
        colHeader: 'Requests (week)',
        colTmpl: this.requestsTmpl
      }
    ];

    this.breadcrumbsService.updateBreadcrumbs([
      {
        text:'Accounts Telemetry',
        link: ''
      }
    ]);

    let timeframe = {};
    timeframe['from'] = moment().subtract(7, 'days').utc().startOf('day').format();
    timeframe['to'] = moment().utc().format();

    this.telemetryService.getAccountsStatistics(timeframe).subscribe(stats => {
      this.account.getAccounts().then((accounts:object[]) => {
        this._buildChartData(stats, accounts);
      }, (err:object) => {
        this._buildChartData(stats);
        console.error('AccountsTelemetry | account.getAccounts', err);
      });
    }, err => {
      this.chartDataSubject.error('Error');
      this.chartIsReadySubject.next(true);
      console.error('AccountsTelemetry | getAccountsStatistics failed', err);
    });
  }

  private _buildChartData(stats:object, accounts?:object[]):void {
    let tableData = [];

    for(var accountId in stats['statistics']) {
      let accountName:string = 'View account telemetry';
      let accountSource:string = '';
      let accountCloundOneId:string = '';

      if(accounts) {
        for(var a=0; a<accounts.length; a++) {
          if(accounts[a]['account_id'] == accountId) {
            accountName = accounts[a]['tenant_name'];
            accountSource = accounts[a]['source'];
            accountCloundOneId = accounts[a]['cloud_one_account_id'] || '';
          }
        }
      }

      tableData.push({
        account: {
          id: accountId,
          name: accountName,
          source: accountSource,
          accountCloundOneId: accountCloundOneId
        },
        requests: this.telemetryService.getLineChartData(stats['statistics'][accountId], ['total_transactions'])
      });
    }

    this.chartDataSubject.next(tableData);
    this.chartIsReadySubject.next(true);
  }
}
