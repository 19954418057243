import { Component, Input } from '@angular/core';

@Component({
  selector: 'group-stats',
  templateUrl: 'group-stats.html',
  styleUrls: ['group-stats.scss']
})
export class GroupStats {
  @Input() _activeCount:number;
  @Input() _seenCount:number;
  @Input() _totalCount:number;

  public activeCount:number;
  public seenCount:number;
  public totalCount:number;

  constructor() {}

  ngOnInit():void {
    this.activeCount = this._activeCount;
    this.seenCount = this._seenCount;
    this.totalCount = this._totalCount;
  }
}
