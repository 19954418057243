<section>
  <div *ngIf="!librariesReady" class="librariesOverlay">
    Loading Agent Information
  </div>

  <div *ngIf="libraries === null && librariesReady" class="librariesOverlay">
    No environment was reported for this agent, no libraries to display.
  </div>

  <div *ngIf="libraries && librariesReady">
    <div class="detail-table">
      <ul>
        <li class="detail-table__row">
          <span class="label">Last seen</span>
          <span class="value">{{ libraries.last_seen ? formatTime(libraries.last_seen) : 'N/A' }}</span>
        </li>
      </ul>
    </div>

    <div class="detail-table">
      <h4 class="detail-table__title">Environment</h4>
      <ul>
        <li class="detail-table__row">
          <span class="label">Agent Environment ID</span>
          <span class="value">{{ libraries.agent_environment_id || 'N/A' }}</span>
        </li>
      </ul>
      <ul>
        <li class="detail-table__row">
          <span class="label">vmcode version</span>
          <span class="value">{{ libraries.vmcode_version || 'N/A' }}</span>
        </li>
      </ul>
      <ul>
        <li class="detail-table__row">
          <span class="label">vmdata version</span>
          <span class="value">{{ libraries.vmdata_version || 'N/A' }}</span>
        </li>
      </ul>
      <ul>
        <li class="detail-table__row">
          <span class="label">Host Agent Name</span>
          <span class="value">{{ libraries.host_agent.name || 'N/A' }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">Host Agent Version</span>
          <span class="value">{{ libraries.host_agent.version || 'N/A' }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">Host Agent Deployment Type</span>
          <span class="value">{{ libraries.host_agent.deployment_type || 'N/A' }}</span>
        </li>
      </ul>
      <ul>
        <li class="detail-table__row">
          <span class="label">Tags</span>
          <span class="value" *ngIf="libraries.tags.length == 0">N/A</span>
          <span class="value" *ngIf="libraries.tags.length > 0">
            <span *ngFor="let tag of libraries.tags">{{ tag.key || 'N/A' }} : value: {{ tag.value || 'N/A' }}</span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</section>
