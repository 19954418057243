import { NgModule } from '@angular/core';

import { User } from './stores/user';
import { Account } from './stores/account';
import { Group } from './stores/group';

import { APIUrlService } from '@trend-core/api/api-url.service';

import { TimeframeService } from '@trend-common/timeframe/timeframe.service';
import { PollingService } from '@trend-common/polling.service';
import { PaginationService } from '@trend-common/pagination/pagination.service';
import { SortingService } from '@trend-common/sorting/sorting.service';
import { FilterListServiceFactory } from '@trend-common/filter-list/filter-list.service.factory';
import { DrawerService } from '@trend-common/drawer/drawer.service';
import { SearchService } from '@trend-common/search/search.service';
import { FilterListService } from '@trend-common/filter-list/filter-list.service';

@NgModule({
  providers: [
    User,
    Account,
    Group,
    APIUrlService,
    TimeframeService,
    PollingService,
    PaginationService,
    SortingService,
    FilterListServiceFactory,
    DrawerService,
    SearchService,
    FilterListService
  ],
  imports: []
})
export class ICoreModule {}
