import { Observable, Subject, Subscription, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { User } from '@trend-core/trend/user';

// Usage:
// <feature-toggle-provider [features]="featureToggleService.getFeatures()">
//   <div *featureToggle="'enableFeature1'">
//     This is the new feature 1
//   </div>
//   <div *featureToggle="'enableFeature2'">
//     This is the new feature 2
//   </div>
// </feature-toggle-provider>

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private _featureFlags:string[];
  private _getFlagsPromise:Promise<any>;
  private _stubFeatureFlagsSub:Subject<any> = new Subject();
  private _stubAccountFeatureFlagsSub:Subject<any> = new Subject();
  private _stubSaveAccountFeatureFlagsSub:Subject<any> = new Subject();

  constructor(
    private _httpClient:HttpClient,
    private _APIUrlService:APIUrlService,
    private _user:User
  ) {}

  private _initFeatureFlags():void {
    this._getFlagsPromise = this._user.getUser().then((user) => {
      this._featureFlags = user.enabled_features;
    });
  }

  // private _fetchFeatures():Observable<any> {
  //   // let featuresEndpoint:string = this._APIUrlService.url() + 'features';
  //   // return this._httpClient.get(featuresEndpoint);
  //   timer(1000).subscribe(() => {
  //     this._stubFeatureFlagsSub.next([
  //       'enablePosture'
  //     ]);
  //   });
  //
  //   return this._stubFeatureFlagsSub.asObservable();
  // }

  public getFeatures():Promise<any> {
    if(!this._featureFlags) {
      this._initFeatureFlags();
    }
    //
    // return this._getFlagsPromise;
    return this._user.getUser();
  }

  public getStaticFeatures():string[] {
    return this._featureFlags;
  }

  public getAccountFeatures():Observable<any> {
    // let featuresEndpoint:string = this._APIUrlService.url() + 'features';
    // return this._httpClient.get(featuresEndpoint);
    timer(1000).subscribe(() => {
      this._stubAccountFeatureFlagsSub.next([
        {
          featureKey: 'enablePosture',
          featureLabel: 'Posture View',
          active: true
        },
        {
          featureKey: 'enableCredentialsStuffing',
          featureLabel: 'Cresdentials Stuffing',
          active: true
        }
      ]);
    });

    return this._stubAccountFeatureFlagsSub.asObservable();
  }

  public setAccountFeatures(flags:any):Observable<any> {
    // let featuresEndpoint:string = this._APIUrlService.url() + 'features';
    // return this._httpClient.get(featuresEndpoint);

    timer(1000).subscribe(() => {
      this._stubSaveAccountFeatureFlagsSub.next();
    });

    return this._stubSaveAccountFeatureFlagsSub.asObservable();
  }
}
