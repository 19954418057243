<div class="drawer">
  <header>
    <h4>USER DETAILS</h4>
  </header>

  <section>
    <div class="detail-table">
      <ul>
        <li class="detail-table__row">
          <span class="label">EMAIL</span>
          <span class="value">{{ data.email }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">ACCOUNT ID</span>
          <span class="value">{{ data.account_id }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">ROLE</span>
          <span class="value">{{ data.role }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">CREATED ON</span>
          <span class="value">{{ data.created_on | date:"medium" }}</span>
        </li>
      </ul>
    </div>
  </section>

  <footer>
    <button (click)="openDeleteVerifiedUserModal()" mat-raised-button color="danger">DELETE VERIFIED USER</button>
    <button (click)="openChangeUserRoleModal()" mat-raised-button color="primary">CHANGE USER ROLE</button>
  </footer>
</div>
