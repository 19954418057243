import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

import { CopyToClipboard } from './copy-to-clipboard';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatButtonModule
  ],
  providers: [],
  declarations: [
    CopyToClipboard,
  ],
  exports: [
    CopyToClipboard,
  ],
  entryComponents: []
})
export class CopyToClipboardModule {}
