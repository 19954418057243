import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Platform } from '@angular/cdk/platform';
import { ErrorStateMatcher } from '@angular/material/core';

import { Dashboard } from './dashboard';

import { PageHeader } from '../page-header/page-header';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { TrendCommonModule } from '@trend-common/common.module';
import { FeedbackMessagesModule } from '@trend-common/feedback-messages/feedback-messages.module';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

let matModuleArray:any[] = [
  MatIconModule,
  MatButtonModule,
  MatMenuModule
];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    TrendCommonModule,
    FeedbackMessagesModule,
    matModuleArray,
    BreadcrumbsModule
  ],
  declarations: [
    Dashboard,
    PageHeader
  ],
  providers: [
    Platform,
    ErrorStateMatcher
  ],
  entryComponents: [
    Dashboard
  ],
  exports: [
    Dashboard
  ]
})
export class DashboardModule {}
