<section>
  <header>
    <button mat-flat-button (click)="setTimeframe('24h')" [ngClass]="{'active': activeTimeframe == '24h'}" type="button" name="button" class="timeframeButton">Last 24 hours</button>
    <button mat-flat-button (click)="setTimeframe('7d')" [ngClass]="{'active': activeTimeframe == '7d'}" type="button" name="button" class="timeframeButton">Last 7 days</button>
    <button mat-flat-button (click)="setTimeframe('14d')" [ngClass]="{'active': activeTimeframe == '14d'}" type="button" name="button" class="timeframeButton">Last 14 days</button>
  </header>
  <mat-tab-group class="verticalTabs">
    <mat-tab label="General">
      <div *ngIf="!chartsReady && !telemetryDataError" class="chartsLoading">
        Loading group telemetry...
      </div>
      <div *ngIf="telemetryDataError" class="telemetryError">
        Something went wrong. We could not get the groups telemetry data.
      </div>
      <div *ngIf="chartsReady">
        <div class="chart-wrapper">
          <data-charts
            [_chartType]="'line'"
            [_chartTitle]="'Transactions (Total & Reported)'"
            [_chartForeColor]="'#000'"
            [_chartBGColor]="'#FFF'"
            [_height]="285"
            [_xaxis]="xaxisConfig"
            [_enableAnimations]="false"
            [_useNativeZoom]="false"
            [_data]="totalTransactionsCountChartData"
            [_strokeConfig]="{ width: [1,1] }"
            [_disableCustomDrilldown]="true"
            [_useNativeZoom]="false"
            [_colors]="['#0F6', '#FA532C']"
            [_xAxisProp]="'timestamp'"
            [_xAxisType]="'datetime'"
            [_yAxisProp]="['total_transactions']">
          </data-charts>
        </div>
        <div class="chart-wrapper">
          <data-charts
            [_chartType]="'line'"
            [_chartTitle]="'Reported Transactions'"
            [_chartForeColor]="'#000'"
            [_chartBGColor]="'#FFF'"
            [_height]="285"
            [_xaxis]="xaxisConfig"
            [_enableAnimations]="false"
            [_useNativeZoom]="false"
            [_data]="reportedTransactionsCountChartData"
            [_strokeConfig]="{ width: 1 }"
            [_colors]="['#00A185']"
            [_disableCustomDrilldown]="true"
            [_useNativeZoom]="false"
            [_xAxisProp]="'timestamp'"
            [_xAxisType]="'datetime'"
            [_yAxisProp]="['total_transactions']">
          </data-charts>
        </div>
        <div class="chart-wrapper">
          <data-charts
            [_chartType]="'bar'"
            [_chartTitle]="'Profiled Transactions'"
            [_chartSubTitle]="'Click on any bar to view transaction details'"
            [_chartForeColor]="'#000'"
            [_chartBGColor]="'#FFF'"
            [_height]="285"
            [_xaxis]="xaxisConfig"
            [_enableAnimations]="false"
            [_useNativeZoom]="false"
            [_onSelectFn]="onBarChartSeriesSelect"
            [_drilldownFn]="showDrilldownFunc"
            [_data]="profiledTransactionsChartData"
            [_chartContextExtra]="chartExtraData"
            [_colors]="['#AC63CA']"
            [_useNativeZoom]="false"
            [_xAxisProp]="'timestamp'"
            [_xAxisType]="'datetime'"
            [_yAxisProp]="['total_transactions']">
          </data-charts>
        </div>
        <div class="chart-wrapper">
          <data-charts
            [_chartType]="'bar'"
            [_chartTitle]="'Exceptions'"
            [_chartForeColor]="'#000'"
            [_chartBGColor]="'#FFF'"
            [_height]="285"
            [_xaxis]="xaxisConfig"
            [_enableAnimations]="false"
            [_useNativeZoom]="false"
            [_data]="exceptionsCountChartData"
            [_colors]="['#00A185']"
            [_xAxisProp]="'timestamp'"
            [_xAxisType]="'datetime'"
            [_yAxisProp]="['total_transactions']">
          </data-charts>
        </div>
        <div class="chart-wrapper">
          <data-charts
            [_chartType]="'bar'"
            [_chartTitle]="'Dropped and Rejected Messages'"
            [_chartForeColor]="'#000'"
            [_chartBGColor]="'#FFF'"
            [_height]="285"
            [_xaxis]="xaxisConfig"
            [_enableAnimations]="false"
            [_useNativeZoom]="false"
            [_data]="messagesCountChartData"
            [_colors]="['#4990e2', '#AC63CA']"
            [_xAxisProp]="'timestamp'"
            [_xAxisType]="'datetime'"
            [_yAxisProp]="['total_transactions']">
          </data-charts>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="50th Percentile">
      <ng-template matTabContent>
        <div *ngIf="!chartsReady && !telemetryDataError" class="chartsLoading">
          Loading group telemetry...
        </div>
        <div *ngIf="telemetryDataError" class="telemetryError">
          Something went wrong. We could not get the groups telemetry data.
        </div>
        <div *ngIf="chartsReady">
          <div class="chart-wrapper">
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'Agent Transaction sample time (ms)'"
              [_chartSubTitle]="'Click on any point to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="samplesFiftyChartData"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_chartContextExtra]="chartExtraData"
              [_strokeConfig]="{ width: [1,1] }"
              [_useNativeZoom]="false"
              [_colors]="['#4990e2', '#AC63CA']"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="agentTimePercentFiftyChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Agent Transaction sample (%)'"
              [_drilldownCall]="showDrilldownFunc"
              [_chartSubtitle]="'Click on any bar to view transaction details'"
              [_drilldownTimeframe]="timeframeObj"
              [_drillDownLoadingLabel]="'Loading Hooks...'"
              [_selectedTimeframeLabel]="'Showing hooks'"
              [_onSelect]="onChartSeriesSelect">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Agent Transaction sample (%)'"
              [_chartSubTitle]="'Click on any bar to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="agentTimePercentFiftyChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#00A185']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <line-chart
              [_legend]="true"
              [_inputData]="averageFiftyChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185', '#FDCA0A']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_disableLegendSelect]="true"
              [_chartHeader]="'All Transaction Agent Time (ms)'">
            </line-chart> -->
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'All Transaction Agent Time (ms)'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="averageFiftyChartData"
              [_strokeConfig]="{ width: 1 }"
              [_colors]="['#00A185', '#FDCA0A']"
              [_disableCustomDrilldown]="true"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="averageAgentTimePercentFiftyChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#AC63CA']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Each Transactions Agent %'"
              [_chartSubtitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Each Transactions Agent (%)'"
              [_chartSubTitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="averageAgentTimePercentFiftyChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#AC63CA']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="75th Percentile">
      <ng-template matTabContent>
        <div *ngIf="!chartsReady && !telemetryDataError" class="chartsLoading">
          Loading group telemetry...
        </div>
        <div *ngIf="telemetryDataError" class="telemetryError">
          Something went wrong. We could not get the groups telemetry data.
        </div>
        <div *ngIf="chartsReady">
          <div class="chart-wrapper">
            <!-- <line-chart
              [_legend]="true"
              [_inputData]="samplesSeventyFiveChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#4990e2', '#AC63CA']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_disableLegendSelect]="true"
              [_chartHeader]="'Agent Transaction sample time (ms)'"
              [_drilldownCall]="showDrilldownFunc"
              [_chartSubtitle]="'Click on any bar to view transaction details'"
              [_drilldownTimeframe]="timeframeObj"
              [_drillDownLoadingLabel]="'Loading Hooks...'"
              [_selectedTimeframeLabel]="'Showing hooks'"
              [_onSelect]="onChartSeriesSelect">
            </line-chart> -->
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'Agent Transaction sample time (ms)'"
              [_chartSubTitle]="'Click on any point to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="samplesSeventyFiveChartData"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_chartContextExtra]="chartExtraData"
              [_strokeConfig]="{ width: [1,1] }"
              [_useNativeZoom]="false"
              [_colors]="['#4990e2', '#AC63CA']"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="agentTimePercentSeventyFiveChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Agent Transaction sample (%)'"
              [_drilldownCall]="showDrilldownFunc"
              [_chartSubtitle]="'Click on any bar to view transaction details'"
              [_drilldownTimeframe]="timeframeObj"
              [_drillDownLoadingLabel]="'Loading Hooks...'"
              [_selectedTimeframeLabel]="'Showing hooks'"
              [_onSelect]="onChartSeriesSelect">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Agent Transaction sample (%)'"
              [_chartSubTitle]="'Click on any bar to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="agentTimePercentSeventyFiveChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#00A185']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <line-chart
              [_legend]="true"
              [_inputData]="averageSeventyFiveChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185', '#FDCA0A']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_disableLegendSelect]="true"
              [_chartHeader]="'All Transaction Agent Time (ms)'">
            </line-chart> -->
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'All Transaction Agent Time (ms)'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="averageSeventyFiveChartData"
              [_strokeConfig]="{ width: 1 }"
              [_colors]="['#00A185', '#FDCA0A']"
              [_disableCustomDrilldown]="true"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="averageAgentTimePercentSeventyFiveChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#AC63CA']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Each Transactions Agent %'"
              [_chartSubtitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Each Transactions Agent (%)'"
              [_chartSubTitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="averageAgentTimePercentSeventyFiveChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#AC63CA']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="90th Percentile">
      <ng-template matTabContent>
        <div *ngIf="!chartsReady && !telemetryDataError" class="chartsLoading">
          Loading group telemetry...
        </div>
        <div *ngIf="telemetryDataError" class="telemetryError">
          Something went wrong. We could not get the groups telemetry data.
        </div>
        <div *ngIf="chartsReady">
          <div class="chart-wrapper">
            <!-- <line-chart
              [_legend]="true"
              [_inputData]="samplesNinetyChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#4990e2', '#AC63CA']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_disableLegendSelect]="true"
              [_chartHeader]="'Agent Transaction sample time (ms)'"
              [_drilldownCall]="showDrilldownFunc"
              [_chartSubtitle]="'Click on any bar to view transaction details'"
              [_drilldownTimeframe]="timeframeObj"
              [_drillDownLoadingLabel]="'Loading Hooks...'"
              [_selectedTimeframeLabel]="'Showing hooks'"
              [_onSelect]="onChartSeriesSelect">
            </line-chart> -->
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'Agent Transaction sample time (ms)'"
              [_chartSubTitle]="'Click on any point to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="samplesNinetyChartData"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_chartContextExtra]="chartExtraData"
              [_strokeConfig]="{ width: [1,1] }"
              [_useNativeZoom]="false"
              [_colors]="['#4990e2', '#AC63CA']"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="agentTimePercentNinetyChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Agent Transaction sample (%)'"
              [_drilldownCall]="showDrilldownFunc"
              [_chartSubtitle]="'Click on any bar to view transaction details'"
              [_drilldownTimeframe]="timeframeObj"
              [_drillDownLoadingLabel]="'Loading Hooks...'"
              [_selectedTimeframeLabel]="'Showing hooks'"
              [_onSelect]="onChartSeriesSelect">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Agent Transaction sample (%)'"
              [_chartSubTitle]="'Click on any bar to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="agentTimePercentNinetyChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#00A185']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <line-chart
              [_legend]="true"
              [_inputData]="averageNinetyChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185', '#FDCA0A']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_disableLegendSelect]="true"
              [_chartHeader]="'All Transaction Agent Time (ms)'">
            </line-chart> -->
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'All Transaction Agent Time (ms)'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="averageNinetyChartData"
              [_strokeConfig]="{ width: 1 }"
              [_colors]="['#00A185', '#FDCA0A']"
              [_disableCustomDrilldown]="true"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="averageAgentTimePercentNinetyChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#AC63CA']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Each Transactions Agent %'"
              [_chartSubtitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Each Transactions Agent (%)'"
              [_chartSubTitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="averageAgentTimePercentNinetyChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#AC63CA']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="99th Percentile">
      <ng-template matTabContent>
        <div *ngIf="!chartsReady && !telemetryDataError" class="chartsLoading">
          Loading group telemetry...
        </div>
        <div *ngIf="telemetryDataError" class="telemetryError">
          Something went wrong. We could not get the groups telemetry data.
        </div>
        <div *ngIf="chartsReady">
          <div class="chart-wrapper">
            <!-- <line-chart
              [_legend]="true"
              [_inputData]="samplesNinetyNineChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#4990e2', '#AC63CA']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_disableLegendSelect]="true"
              [_chartHeader]="'Agent Transaction sample time (ms)'"
              [_drilldownCall]="showDrilldownFunc"
              [_chartSubtitle]="'Click on any bar to view transaction details'"
              [_drilldownTimeframe]="timeframeObj"
              [_drillDownLoadingLabel]="'Loading Hooks...'"
              [_selectedTimeframeLabel]="'Showing hooks'"
              [_onSelect]="onChartSeriesSelect">
            </line-chart> -->
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'Agent Transaction sample time (ms)'"
              [_chartSubTitle]="'Click on any point to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="samplesNinetyNineChartData"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_chartContextExtra]="chartExtraData"
              [_strokeConfig]="{ width: [1,1] }"
              [_useNativeZoom]="false"
              [_colors]="['#4990e2', '#AC63CA']"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="agentTimePercentNinetyNineChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Agent Transaction sample (%)'"
              [_drilldownCall]="showDrilldownFunc"
              [_chartSubtitle]="'Click on any bar to view transaction details'"
              [_drilldownTimeframe]="timeframeObj"
              [_drillDownLoadingLabel]="'Loading Hooks...'"
              [_selectedTimeframeLabel]="'Showing hooks'"
              [_onSelect]="onChartSeriesSelect">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Agent Transaction sample (%)'"
              [_chartSubTitle]="'Click on any bar to view transaction details'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="agentTimePercentNinetyNineChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#00A185']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <line-chart
              [_legend]="true"
              [_inputData]="averageNinetyNineChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#00A185', '#FDCA0A']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_disableLegendSelect]="true"
              [_chartHeader]="'All Transaction Agent Time (ms)'">
            </line-chart> -->
            <data-charts
              [_chartType]="'line'"
              [_chartTitle]="'All Transaction Agent Time (ms)'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_data]="averageNinetyNineChartData"
              [_strokeConfig]="{ width: 1 }"
              [_colors]="['#00A185', '#FDCA0A']"
              [_disableCustomDrilldown]="true"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
          <div class="chart-wrapper">
            <!-- <bar-chart
              [_legend]="false"
              [_inputData]="averageAgentTimePercentNinetyNineChartData"
              [_showYAxisLabel]="false"
              [_showYAxis]="true"
              [_showXAxis]="true"
              [_yAxisLabel]="'Agents'"
              [_xAxisProp]="'timestamp'"
              [_yAxisProp]="'events'"
              [_barPadding]="1"
              [_colors]="['#AC63CA']"
              [_height]="300"
              [_xAxisTickFormatting]="barChartxAxisTickFormatting"
              [_rotateXAxisTicks]="rotateXAxisTicks"
              [_chartHeader]="'Each Transactions Agent %'"
              [_chartSubtitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'">
            </bar-chart> -->
            <data-charts
              [_chartType]="'bar'"
              [_chartTitle]="'Each Transactions Agent (%)'"
              [_chartSubTitle]="'Percentile of the agent time in relation to the total time for each transactions.'"
              [_chartInstruction]="'If this goes up, it means the user\'s group has become faster or the agent has become slower. If this goes down, it means the user\'s group has become slower or the agent has become faster.'"
              [_chartForeColor]="'#000'"
              [_chartBGColor]="'#FFF'"
              [_height]="285"
              [_xaxis]="xaxisConfig"
              [_enableAnimations]="false"
              [_useNativeZoom]="false"
              [_onSelectFn]="onBarChartSeriesSelect"
              [_drilldownFn]="showDrilldownFunc"
              [_data]="averageAgentTimePercentNinetyNineChartData"
              [_chartContextExtra]="chartExtraData"
              [_colors]="['#AC63CA']"
              [_useNativeZoom]="false"
              [_xAxisProp]="'timestamp'"
              [_xAxisType]="'datetime'"
              [_yAxisProp]="['total_transactions']">
            </data-charts>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</section>

<ng-template #agentPercentInstruction>
  <div>
    <span style="display: block;">.</span>
    <span style="display: block;"></span>
  </div>
</ng-template>
