import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { TableModule } from '../common/table/table.module';
import { MatDataTableModule } from '@trend-common/mat-data-table/mat-data-table.module';
import { DataChartsModule } from '@trend-common/data-charts/data-charts.module';
import { DrawerModule } from '../common/drawer/drawer.module';
import { CopyToClipboardModule } from '@trend-common/copy-to-clipboard/copy-to-clipboard.module';
import { PipesModule } from '@trend-common/pipes/pipes.module';
import { ModalsModule } from '../common/modals/modals.module';

import { LoginPage } from './login/login';
import { AccountsPage } from './accounts/accounts';
import { AccountsList } from './accounts/accounts-list/accounts-list';
import { AccountsTelemetry } from './accounts/accounts-telemetry/accounts-telemetry';
import { AccountPage } from './account/account';
import { AccountGroups } from '../pages/account/groups/groups';
import { AccountGroup } from '../pages/account/group/group';
import { GroupTelemetry } from '../pages/account/group/group-telemetry/group-telemetry';
import { GroupLibraries } from '../pages/account/group/group-libraries/group-libraries';
import { GroupAgentEnvironments } from '../pages/account/group/group-agent-environments/group-agent-environments';
import { VmData } from '../pages/account/group/vm-data/vm-data';
import { AccountUsers } from '../pages/account/users/users';
import { AccountTelemetry } from '../pages/account/account-telemetry/account-telemetry';
import { UsersPage } from './users/users';
import { AgentsPage } from './agents/agents';


import { PaginationService } from '../common/pagination/pagination.service';

import { PagesParamsStore } from './pages-params.store';

import { GroupStats } from './accounts/group-stats/group-stats';

// Libraries
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';

let matModuleArray:any[] = [
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatCardModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatTableModule,
  MatPaginatorModule,
  MatCheckboxModule
];

let pagesArray:any[] = [
  LoginPage,
  AccountsPage,
  AccountsList,
  AccountsTelemetry,
  AccountPage,
  AccountGroups,
  AccountGroup,
  GroupTelemetry,
  GroupLibraries,
  GroupAgentEnvironments,
  VmData,
  AccountUsers,
  AccountTelemetry,
  UsersPage,
  AgentsPage
];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    MatDataTableModule,
    DataChartsModule,
    DrawerModule,
    CopyToClipboardModule,
    NgxJsonViewerModule,
    PipesModule,
    ModalsModule,
    matModuleArray
  ],
  providers: [
    PaginationService,
    PagesParamsStore
  ],
  declarations: [pagesArray, GroupStats],
  exports: [pagesArray],
  entryComponents: []
})
export class PagesModule {}
