<div class="drawer">
  <header>
    <h4>USER DETAILS</h4>
  </header>

  <section>
    <div class="detail-table">
      <ul>
        <li class="detail-table__row">
          <span class="label">EMAIL</span>
          <span class="value">{{ data.email }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">ACCOUNT ID</span>
          <span class="value">{{ data.account_id }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">VERIFICATION TOKEN</span>
          <span class="value">{{ data.verification_token }}</span>
        </li>
        <li class="detail-table__row verificationCopy">
          <span class="label">VERIFICATION URL</span>
          <span class="value">
            <span id="verification-url">{{ verificationUrl }}</span>
            <copy-to-clipboard srcElementId="verification-url"></copy-to-clipboard>
          </span>
        </li>
        <li class="detail-table__row">
          <span class="label">CREATED ON</span>
          <span class="value">{{ data.created_on | date:"medium" }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">UPDATED ON</span>
          <span class="value">{{ data.updated_on | date:"medium" }}</span>
        </li>
      </ul>
    </div>
  </section>

  <footer>
    <button (click)="openDeletePendingUserModal()" mat-raised-button color="warn">DELETE PENDING USER</button>
  </footer>
</div>
