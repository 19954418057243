import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { User } from '@trend-core/trend/user';

@Injectable()
export class ImpersonateService {
  public impersonateUpdateSub = new Subject();

  constructor(
    private user:User
  ) {
    window.addEventListener('storage', this.onStorageEvent.bind(this), false);
  }

  private onStorageEvent(storageEvent:object):void {
    if(storageEvent['key'] === 'impersonatedAccount') {
      try {
        let newImpersonatedAccountStorageObj:object = JSON.parse(storageEvent['newValue']);
        let oldImpersonatedAccountStorageObj:object = JSON.parse(storageEvent['oldValue']);

        if(!newImpersonatedAccountStorageObj || !newImpersonatedAccountStorageObj['account_id'] || !newImpersonatedAccountStorageObj['access_type'] || !oldImpersonatedAccountStorageObj || !oldImpersonatedAccountStorageObj['account_id'] || !oldImpersonatedAccountStorageObj['access_type']) {
          this.impersonateChanged('account_changed');
          return;
        }

        if(newImpersonatedAccountStorageObj['account_id'] !== oldImpersonatedAccountStorageObj['account_id']) {
          this.impersonateChanged('account_changed');
        } else if(newImpersonatedAccountStorageObj['access_type'] !== oldImpersonatedAccountStorageObj['access_type']) {
          this.impersonateChanged('viewMode_changed');
        }
      } catch(err) {
        console.error('ImpersonateService | onStorageEvent try failed', err);
      }
    }
  }

  impersonateChanged(changeType:string):void {
    this.impersonateUpdateSub.next(changeType);
  }

  onImpersonateChange():Observable<any> {
    return this.impersonateUpdateSub.asObservable();
  }

  setImpersonatedModeOn(impersonatedAccount:object):void {
    let accountObj = {
      account_id: impersonatedAccount['account_id'],
      tenant_name: impersonatedAccount['tenant_name'],
      access_type: impersonatedAccount['access_type'],
      original_auth_token: impersonatedAccount['original_auth_token']
    }

    localStorage.setItem('impersonatedAccount', JSON.stringify(accountObj));
  }

  setImpersonatedModeOff():void {
    localStorage.removeItem('impersonatedAccount');
  }

  getImpersonatedAccount():string {
    return JSON.parse(localStorage.getItem('impersonatedAccount'));
  }

  // public canActivate() {
  //   return new Promise((resolve) => {
  //     this.user.getUser().then((user:object) => {
  //       if(user['role'].indexOf('impersonate') > -1) {
  //         resolve(true);
  //       } else {
  //         resolve(false);
  //       }
  //     });
  //   })
  // }
}
