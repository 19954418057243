import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackMessages } from './feedback-messages';
import { FeedbackMessagesService } from './feedback-messages.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    FeedbackMessagesService
  ],
  declarations: [
    FeedbackMessages
  ],
  exports: [
    FeedbackMessages
  ]
})
export class FeedbackMessagesModule {}
