import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Drawer, InjectedDrawer} from './drawer';
import { DrawerService } from './drawer.service';

import { PendingUserDetailsDrawer } from './drawers/pending-user-details/pending-user-details';
import { VerifiedUserDetailsDrawer } from './drawers/verified-user-details/verified-user-details';

import { CopyToClipboardModule } from '@trend-common/copy-to-clipboard/copy-to-clipboard.module';

import { MatButtonModule } from '@angular/material/button';

let matModuleArray:any[] = [
  MatButtonModule
];

@NgModule({
  imports: [
    RouterModule,
    BrowserAnimationsModule,
    CopyToClipboardModule,
    matModuleArray
  ],
  providers: [
    DrawerService
  ],
  declarations: [
    Drawer,
    InjectedDrawer,
    PendingUserDetailsDrawer,
    VerifiedUserDetailsDrawer
  ],
  exports: [
    Drawer,
    InjectedDrawer
  ],
  entryComponents: [
    PendingUserDetailsDrawer,
    VerifiedUserDetailsDrawer
  ]
})
export class DrawerModule {}
