import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

@Injectable()
export class AgentService {
  private agentGroupsEndpoint:string = this.apiUrlService.url() + 'agent/groups/';

  constructor(
    private http:HttpClient,
    private apiUrlService:APIUrlService
  ) {}

  fetchGroupVmData(groupId:string):Observable<any> {
    return this.http.get(this.agentGroupsEndpoint + groupId + '/vmdatas/@latest');
  }
}
