import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { DispatcherServiceFactory } from '@trend-core/dispatcher/dispatcher.service.factory';
import { PaginationService } from '../pagination/pagination.service';
import { DrawerService } from '../drawer/drawer.service';

import { SubscriberComponent } from '@trend-core/subscriber-component-i';

import { NgxDatatablePage } from './ngx-datatable-page-t';

@Component({
  selector: 'data-table',
  templateUrl: './table.html',
  styleUrls: ['./table.scss']
})
export class Table implements SubscriberComponent {
  @Input() _mediator:any;
  @Input() _headerHeight:number;
  @Input() _footerHeight:number;
  @Input() _columnMode:string;
  @Input() _rowHeight:number;
  @Input() _resultsPerPage:number;
  @Input() _backendPagination:boolean;
  @Input() _onSelect:any;
  @Input() _searchConfig:string[];
  @Input() _searchPlaceholder:string;

  subscription:Observable<any>;
  backendPagination:boolean;
  resultsPerPage:number;
  columnMode:string;
  headerHeight:number;
  footerHeight:number;
  rowHeight:number;
  cols:object[];
  rows:object[];
  updateSubject = new Subject<any>();
  pageChangedSubject = new Subject<any>();
  page:object = {};
  searchConfig:string[];
  searchPlaceholder:string;
  searchValue:string;
  originalRows:any[];

  constructor(
    private paginationService:PaginationService,
    private drawerService:DrawerService,
    // Used in context in classes using the table class.
    private router:Router,
    private dispatcherServiceFactory:DispatcherServiceFactory
  ) {}

  ngOnInit():void {
    if(this._mediator) {
      this.subscribe();
    }

    this.build();
  }

  build():void {
    this.backendPagination = this._backendPagination || false;
    this.resultsPerPage = this._resultsPerPage || 10;
    this.page['limit'] = this.resultsPerPage;
    this.headerHeight = this._headerHeight || 40;
    this.footerHeight = this._footerHeight || 40;
    this.columnMode = this._columnMode || 'force';
    this.rowHeight = this._rowHeight || 40;
    this.onSelect = this._onSelect || this.onSelect;
    this.searchConfig = this._searchConfig || [];
    this.searchPlaceholder = this._searchPlaceholder || 'Search Table';
  }

  subscribe():void {
    this.subscription = this._mediator.getRequestSubject(this).subscribe(
      (data:object) => this.update(data)
    );
  }

  onSelect(event:object):void {
    if(event && event['selected'] && event['selected'].length === 1){
      // this.ignoreDispatcher('polling');
      this.drawerService.update(event['selected'][0], false);
    }
  }

  setFilteredResults() {
    if(this.searchConfig.length == 0 || !this.originalRows || !this.searchValue) {
      this.clearSearch();
      return;
    }

    let filteredResults = [];

    if(this.searchValue == '') {
      this.rows = this.originalRows;
    }

    for(var r=0; r<this.originalRows.length; r++) {
      for(var f=0; f<this.searchConfig.length; f++) {
        if(this.originalRows[r][this.searchConfig[f]].toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) {
          filteredResults.push(this.originalRows[r]);
          break;
        }
      }
    }

    this.rows = filteredResults;
  }

  clearSearch():void {
    this.searchValue = '';
    this.rows = this.originalRows;
  }

  update(data?:any):void {
    let tableData:object;

    try{
      if(data) {
        tableData = this._mediator.adapt(data);
      }

      if(!this.cols) {
        this.cols = tableData['cols'];
      }

      this.originalRows = [...tableData['rows']];

      if(this.searchValue) {
        this.rows = tableData['rows'];
        this.setFilteredResults();
      } else {
        this.rows = tableData['rows'];
      }
    } catch(e){
      console.error(e);
    }

    this.updateSubject.next(data);
  }

  onUpdate():Observable<any> {
    return this.updateSubject.asObservable();
  }

  setPage(pageInfo:NgxDatatablePage) {
    this.paginationService.update(pageInfo);
  }
}
