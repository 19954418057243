<div class="accountsTelemetry">
  <mat-data-table
    [_tableDataSubject]="chartDataSubject"
    [_colsDef]="colsDef"
    [_tableClass]="'telemetryTable'"
    [_searchConfig]="searchConfig">
  </mat-data-table>
</div>

<ng-template #accountTmpl let-accountData="account">
  <a class="accountCol_link" routerLink="/accounts/{{ accountData.id }}/telemetry">{{ accountData.name || 'No Account Name' }}</a>
  <span class="accountCol_id">{{ accountData.id }}</span>
  <span class="accountCol_id">{{ accountData.source }}</span>
</ng-template>
<ng-template #requestsTmpl let-chartData="requests">
  <data-charts
    [_chartType]="'line'"
    [_height]="85"
    [_chartForeColor]="'#AFB7C8'"
    [_chartBGColor]="'#FFF'"
    [_showHeader]="false"
    [_showLegend]="false"
    [_enableAnimations]="false"
    [_useNativeZoom]="false"
    [_data]="chartData"
    [_strokeConfig]="{ width: 1 }"
    [_xaxis]="xaxisConfig"
    [_yaxis]="yaxisConfig"
    [_xAxisProp]="'timestamp'"
    [_xAxisType]="'datetime'"
    [_yAxisProp]="['total_transactions']"
    [_showToolbar]="false"
    [_useNativeZoom]="false"
    [_disableCustomDrilldown]="true"
    [_showGridXaxisLines]="false"
    [_showGridYaxisLines]="false">
  </data-charts>
</ng-template>
