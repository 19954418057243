import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { PagesParamsStore } from '../../pages/pages-params.store';

import { APIVerifiedUser } from '../../core/types/api/api-verified-user-t';
import { DispatcherConfig } from '@trend-core/dispatcher/dispatcher-config-t';
import { VerifiedUsersTableData } from '../types/components/verified-users-table-data-t';
import { DatePipe } from '@angular/common';

import { Mediator } from '@trend-core/mediator/mediator';

import { StripHtmlPipe } from '@trend-common/pipes/strip-html.pipe';
import { TimezonedDatePipe } from '@trend-common/pipes/date.pipe';

class DateAndTimePipe extends DatePipe {
  public transform(value): any {
    return super.transform(value, 'medium');
  }
}

@Injectable()
export class AccountVerifiedUsersTableMediator extends Mediator {
  name = "AccountVerifiedUsersTableMediator";
  endpoint = this.apiUrl.url() + 'accounts/@all/users';

  constructor(
    public injector:Injector,
    private apiUrl:APIUrlService,
    private pagesParamsStore:PagesParamsStore
  ) {
    super(injector);
  }

  verifiedUsersTableData:any = {
    cols: [
      { name: 'Email', prop: 'email', cellClass: 'col-email', headerClass: 'col-email' },
      { name: 'First Name', prop: 'first_name', cellClass: 'col-first_name', headerClass: 'col-first_name' },
      { name: 'Last Name', prop: 'last_name', cellClass: 'col-last_name', headerClass: 'col-last_name' },
      { name: 'Account ID', prop: 'account_id', cellClass: 'col-account_id', headerClass: 'col-account_id' },
      { name: 'Created On', prop: 'created_on', cellClass: 'col-created_on', headerClass: 'col-created_on', pipe: new DateAndTimePipe('en-US') },
    ],
    rows: []
  };

  fetchData(componentInstance:any, subscriber:any, source:string, subParams?:object):void {
    // let paginationParams = '?page=' + subParams['pagination']['offset'] + 1 + '&per_page=' + subParams['pagination']['limit'];
    if(componentInstance) componentInstance['isLoading'] = true;

    let httpSub = this.http.get<APIVerifiedUser[]>(this.endpoint).subscribe(
      (data) => {
        if(componentInstance) componentInstance['isLoading'] = false;
        if(componentInstance) componentInstance['error'] = false;
        subscriber.next(data);
      },
      (error) => {
        if(componentInstance) componentInstance['error'] = error.message;
        if(componentInstance) componentInstance['isLoading'] = false;
        // subscriber.next(data);
        subscriber.next({});
      }
    );

    this.subs.push(httpSub);
  }

  getRequestSubject(component:any):Observable<any> {
    let dispatcherConfig:DispatcherConfig = {
      list : [
        'pagination'
      ],
      initial: 'pagination'
    };

    return super.getRequestSubject(component, this.name, dispatcherConfig);
  }

  adapt(data:APIVerifiedUser[]):VerifiedUsersTableData {
    let account_id = this.pagesParamsStore.getParams().account_id;
    let rows:any[] = [];

    for(var i=0; i < data.length; i++) {
      if(account_id == data[i]['account_id']) {
        data[i]['first_name'] = new StripHtmlPipe().transform(data[i]['first_name']);
        data[i]['last_name'] = new StripHtmlPipe().transform(data[i]['last_name']);
        data[i]['created_on'] = new TimezonedDatePipe().transform(data[i]['created_on']);
        rows.push(data[i]);
      }
    }

    this.verifiedUsersTableData['rows'] = rows;

    return this.verifiedUsersTableData;
  }

  // Completely reloads table data from fetch
  reloadTable():void {
    this.dispatcherMap.pagination['hardTrigger']();
  }
}
