import { Injectable } from '@angular/core';

@Injectable()
export class Threats {

  threatTypesDisplayLabels:object = {
    'SQL Injection': 'SQL Injection',
    'csrf_tampering': 'CSRF Tampering',
    'XSS': 'Cross Site Scripting',
    'suspicious_payload': 'Suspicious Payload',
    'Illegal File Access': 'Illegal File Access',
    'excessive_http_400s': 'Excessive HTTP 400',
    'Shell Command Execution': 'Remote Command Execution',
    'Open Redirect': 'Open Redirect',
    'Malicious Payload': 'Malicious Payload',
    'Malicious File Upload': 'Malicious File Upload',
  };

  threatTypeLabelMap:object = {
    'sqli': this.threatTypesDisplayLabels['SQL Injection'],
    'file_access': this.threatTypesDisplayLabels['Illegal File Access'],
    'rce': this.threatTypesDisplayLabels['Shell Command Execution'],
    'redirect': this.threatTypesDisplayLabels['Open Redirect'],
    'malicious_payload': this.threatTypesDisplayLabels['Malicious Payload'],
    'malicious_file_upload': this.threatTypesDisplayLabels['Malicious File Upload']
  };

  mitreAttackIDsURLMap:object = {
    'T1110.004': 'https://attack.mitre.org/techniques/T1110/004/',
    'T1036': 'https://attack.mitre.org/techniques/T1036/',
    'T1059': 'https://attack.mitre.org/techniques/T1059/',
    'T1185': 'https://attack.mitre.org/techniques/T1185/',
    'T1190': 'https://attack.mitre.org/techniques/T1190/',
    'T1059.006': 'https://attack.mitre.org/techniques/T1059/006/',
    'T1059.004' : 'https://attack.mitre.org/techniques/T1059/004/',
    'T1021.004' : 'https://attack.mitre.org/techniques/T1021/004/',
    'T1087.001': 'https://attack.mitre.org/techniques/T1087/001/',
    'T1087.002': 'https://attack.mitre.org/techniques/T1087/002/'
  };

  cweIDsURLMap:object = {
    'CWE-601': 'https://cwe.mitre.org/data/definitions/601.html',
    'CWE-434': 'https://cwe.mitre.org/data/definitions/434.html',
    'CWE-89': 'https://cwe.mitre.org/data/definitions/89.html',
    'CWE-94': 'https://cwe.mitre.org/data/definitions/94.html'
  };

  constructor() {};

  public getThreatTypesLabels(input:any) {
    let output;

    if(input.constructor === Array) {
      output = [];

      for(var i=0; i < input.length; i++) {
        if(this.threatTypesDisplayLabels[input[i]]){
          output.push(this.threatTypesDisplayLabels[input[i]]);
        }
      }

      return output;
    } else {
      return this.threatTypesDisplayLabels[input] || false;
    }
  }

  public getThreatTypeName(key:string):string {
    return this.threatTypeLabelMap[key];
  }

  public getMitreURLbyID(mitreID:string):string {
    return this.mitreAttackIDsURLMap[mitreID];
  }

  public getCWEURLbyID(cweID:string):string {
    return this.cweIDsURLMap[cweID];
  }
}
