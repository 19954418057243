import { Injectable } from '@angular/core';
import { Observable, Subject, timer, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

import { APIUrlService } from '../api/api-url.service';
import { APIBillingUsage, APIBillingInstances, AccountSubscription, AdminAccountSubscription, APIBillingInvocations, APIBillingFreemiumStatistics, InvocationTimeframe, InstanceTimeframe } from '@trend-core/api/api-billing-t';

@Injectable()
export class BillingService {
  private _accountBillingEndpoint:string = this.api.url() + 'billing/account/usage';
  private _accountsBillingEndpoint:string = this.api.url() + 'billing/accounts/';
  private _accountBillingInvocationsEndpoint:string = this.api.url() + 'billing/account/invocations';
  private _accountBillingInstancesEndpoint:string = this.api.url() + 'billing/account/instances';
  private _accountBillingFreemiumEndpoint:string = this.api.url() + 'billing/account/invocations/freemium';

  constructor(
    private http:HttpClient,
    private api:APIUrlService
  ) {}

  fetchUsage<T>():Observable<APIBillingUsage> {
    return this.http.get<APIBillingUsage>(this._accountBillingEndpoint);
  }

  fetchInstances<T>(range:InstanceTimeframe):Observable<APIBillingInstances> {
    let startOfMonth = moment().startOf('month');
    let fromTime:string = "from_time=",
        toTime:string = "to_time=",
        bucket:string = "bucket_duration=P1D";

    switch(range){
      case "this-month":
        let startOfMonth = moment.utc(moment().startOf('month')).format(),
            endOfMonth = moment.utc(moment().endOf('month')).format();

        fromTime += encodeURIComponent(startOfMonth);
        toTime += encodeURIComponent(endOfMonth);
        break;
      case "last-month":
        let startOfLastMonth = moment.utc(moment().subtract(1, 'month').startOf('month')).format(),
            endOfLastMonth = moment.utc(moment().subtract(1, 'month').endOf('month')).format()

        fromTime += encodeURIComponent(startOfLastMonth);
        toTime += encodeURIComponent(endOfLastMonth)
        break;
    }

    return this.http.get<APIBillingInstances>(this._accountBillingInstancesEndpoint + "?" + fromTime + "&" + toTime + "&" + bucket);
  }

  fetchInvocations<T>(range:InvocationTimeframe):Observable<APIBillingInvocations> {
    let startOfMonth = moment().startOf('month');
    let fromTime:string = "from_time=",
        toTime:string = "to_time=",
        bucket:string = "bucket_duration=P1D";

    switch(range){
      case "this-month":
        let startOfMonth = moment.utc(moment().startOf('month')).format(),
            endOfMonth = moment.utc(moment().endOf('month')).format();

        fromTime += encodeURIComponent(startOfMonth);
        toTime += encodeURIComponent(endOfMonth);
        break;
      case "30-days":
        let thirtyDaysAgo = moment.utc(moment().subtract(30, 'days')).format();

        fromTime += encodeURIComponent(thirtyDaysAgo);
        toTime += encodeURIComponent(moment.utc(moment()).format())
        break;
    }

    return this.http.get<APIBillingInvocations>(this._accountBillingInvocationsEndpoint + "?" + fromTime + "&" + toTime + "&" + bucket);
  }

  fetchFreemiumStatistics<T>():Observable<APIBillingFreemiumStatistics> {
    let fromTime:string = "from_time=",
        toTime:string = "to_time=",
        startOfMonth = moment.utc(moment().startOf('month')).format(),
        endOfMonth = moment.utc(moment().endOf('month')).format();

    fromTime += encodeURIComponent(startOfMonth);
    toTime += encodeURIComponent(endOfMonth);

    return this.http.get<APIBillingFreemiumStatistics>(this._accountBillingFreemiumEndpoint + "?" + fromTime + "&" + toTime);
  }

  fetchAccountsUsage(accountId:string):Observable<AdminAccountSubscription> {
    return this.http.get<AdminAccountSubscription>(this._accountsBillingEndpoint + accountId + '/subscription');
  }

  updateSubscriptionLicenses(accountId:string, licensesNumber:string):Observable<any> {
    return this.http.put<AccountSubscription>(this._accountsBillingEndpoint + accountId + '/subscription', {
      subscribed_licenses: licensesNumber
    });
  }
}
