import * as moment from 'moment';

export default class Helpers {
  static resolveObjPath(path:string, obj:object) {
    return path.split('.').reduce(function(prev, curr) {
        return prev ? prev[curr] : null
    }, obj || self)
  }

  static formatTime(timestamp:string, format:string = 'MM/DD/YYYY, H:mm:ss'):string {
    return moment(timestamp).format(format)
  }
}
