import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { Share } from './share';
import { ShareModal } from './share-modal';
import { ShareService } from './share.service';

import {
  MatButtonModule
} from '@angular/material/button';

let matModuleArray:any[] = [
  MatButtonModule
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    matModuleArray
  ],
  providers: [
    ShareService
  ],
  declarations: [
    Share,
    ShareModal
  ],
  exports: [
    Share,
    ShareModal
  ],
  entryComponents: [
    ShareModal
  ]
})
export class ShareModule {}
