import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

import { ValidatorService } from '@trend-core/trend/validator.service';

@Component({
  templateUrl: 'realtime-custom-range-modal.html',
  styleUrls: ['realtime-custom-range-modal.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RealtimeRangeModal implements OnInit{
  public rangeValue:any;
  public rangeUnit:string;
  public valueFC = new FormControl('', [Validators.required, ValidatorService.onlyNumbers]);

  constructor(
    private dialog: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.valueFC.setValue(this.data.timeframe.value);
    this.rangeUnit = this.data.timeframe.unit;
  }

  setRange() {
    this.data.timeframe.value = this.valueFC.value;
    this.data.timeframe.unit = this.rangeUnit;
    this.data.timeframe.from = moment().subtract(this.rangeValue, this.rangeUnit).utcOffset(moment().utcOffset()).format();
    this.data.timeframe.to = moment().utcOffset(moment().utcOffset()).format();
    this.dialog.close('timeSet');
  }

  cancel() {
    this.dialog.close();
  }
}
