<header>
  <form>
    <mat-form-field appearance="outline">
      <mat-label>Agent ID</mat-label>
      <input id="agent_id" [formControl]="agentIdFC" matInput>
    </mat-form-field>

    <button (click)="searchAgent()" color="primary" mat-flat-button [disabled]="isAgentInfoLoading">
      <span *ngIf="!isAgentInfoLoading">Show Agent</span>
      <span *ngIf="isAgentInfoLoading">Loading...</span>
    </button>
  </form>
</header>

<section class="agentPage__information">
  <h2>Agent Information</h2>
  <div *ngIf="!isAgentInfoLoading && agentInformation && agentInformation.group">
    <div class="detail-table">
      <h4 class="detail-table__title">Group</h4>
      <ul>
        <li *ngIf="currentAgentAccountTenant" class="detail-table__row">
          <span class="label">Account</span>
          <span class="value">
            <a routerLink="/accounts/{{ agentInformation.group.account_id }}">{{ currentAgentAccountTenant }}</a>
          </span>
        </li>
        <li class="detail-table__row">
          <span class="label">Account ID</span>
          <span class="value">{{ agentInformation.group.account_id }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">Group ID</span>
          <span class="value">{{ agentInformation.group.group_id }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">Group Name</span>
          <span class="value">{{ agentInformation.group.name }}</span>
        </li>
        <li class="detail-table__row">
          <span class="label">Deleted</span>
          <span class="value">{{ agentInformation.group.deleted ? 'Yes': 'No' }}</span>
        </li>
      </ul>
    </div>

    <div *ngIf="agentInformation.agent_environment" class="detail-table">
      <h4 class="detail-table__title">Agent Environment</h4>
      <ul>
        <li *ngFor="let agentEnvInfo of object.keys(agentInformation.agent_environment)" class="detail-table__row">
          <span class="label">{{ agentEnvInfo }}</span>
          <span *ngIf="!isObject(agentInformation.agent_environment[agentEnvInfo])" class="value">
            {{ agentEnvInfo === 'last_seen' ? formatTime(agentInformation.agent_environment[agentEnvInfo]) : agentInformation.agent_environment[agentEnvInfo] || 'N/A' }}
          </span>
          <span *ngIf="isObject(agentInformation.agent_environment[agentEnvInfo])" class="value">
            <ngx-json-viewer [json]="agentInformation.agent_environment[agentEnvInfo]" [expanded]="false"></ngx-json-viewer>
          </span>
        </li>
      </ul>
    </div>

    <div *ngIf="agentInformation.application_environment" class="detail-table">
      <h4 class="detail-table__title">Application Environment</h4>
      <ul>
        <li *ngFor="let agentAppInfo of object.keys(agentInformation.application_environment)" class="detail-table__row">
          <span class="label">{{ agentAppInfo }}</span>
          <span *ngIf="!isObject(agentInformation.application_environment[agentAppInfo])" class="value">
            {{ agentAppInfo === 'last_seen' ? formatTime(agentInformation.application_environment[agentAppInfo]) : agentInformation.application_environment[agentAppInfo] || 'N/A' }}
          </span>
          <span *ngIf="isObject(agentInformation.application_environment[agentAppInfo])" class="value">
            <ngx-json-viewer [json]="agentInformation.application_environment[agentAppInfo]" [expanded]="false"></ngx-json-viewer>
          </span>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="!agentInformation && !agentNotFound && !agentError && !isAgentInfoLoading" class="agentPage__empty">
    Enter an Agent ID to get the agent details.
  </div>
  <div *ngIf="isAgentInfoLoading" class="agentPage__empty">
    Loading agent information...
  </div>
  <div *ngIf="!agentInformation && agentNotFound" class="agentPage__empty">
    The agent ID was not found.
  </div>
  <div *ngIf="!agentInformation && !agentNotFound && agentError" class="agentPage__empty">
    Something went wrong, please try again.
  </div>
</section>
