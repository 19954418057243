import { FormControl, ValidatorFn } from '@angular/forms';

export class ValidatorService {

  //ValidatorFn factory
  public static inject(fnName:string, ...args:any):ValidatorFn {
    try {
      return this[fnName].bind({
        args: args
      });
    } catch(e){
      console.error("Injecting deps into validator \"" + fnName + "\" has failed.");
      console.error(e);
    }
  }

  public static minChars(c:FormControl):object {
    return c.value ? (c.value.length >= this['args'][0] ? null : {
      minChars: {
        valid: false
      }
    }) : null;
  }

  public static maxChars(c:FormControl):object {
    return c.value ? (c.value.length <= this['args'][0] ? null : {
      maxChars: {
        valid: false
      }
    }) : null;
  }

  public static greaterThan(c:FormControl):object {
    return parseInt(c.value) ? (parseInt(c.value) > this['args'][0] ? null : {
      greaterThan: {
        valid: false
      }
    }) : null;
  }

  public static greaterThanEqual(c:FormControl):object {
    return parseInt(c.value) ? (parseInt(c.value) >= this['args'][0] ? null : {
      greaterThan: {
        valid: false
      }
    }) : null;
  }

  public static lessThan(c:FormControl):object {
    return parseInt(c.value) ? (parseInt(c.value) < this['args'][0] ? null : {
      lessThan: {
        valid: false
      }
    }) : null;
  }

  public static lessThanEqual(c:FormControl):object {
    return parseInt(c.value) ? (parseInt(c.value) <= this['args'][0] ? null : {
      lessThan: {
        valid: false
      }
    }) : null;
  }

  public static equalTo(c:FormControl):object {
    return c.value ? (c.value == this['args'][0] ? null : {
      equalTo: {
        valid: false
      }
    }) : null;
  }

  public static is32Hex(c: FormControl):object {
    const r = new RegExp(/^[0-9A-Fa-f]{32}?/g);

    return r.test(c.value) ? null : {
      is32Hex: {
        valid: false
      }
    };
  }

  public static is32Int(c: FormControl):object {
    const r = new RegExp(/^[0-9]{32}$/g);

    return r.test(c.value) ? null : {
      is32Int: {
        valid: false
      }
    };
  }

  public static channelname(c: FormControl):object {
    const r = new RegExp(/^[a-z0-9\_\-]+$/i);

    return r.test(c.value) ? null : {
      channelname: {
        valid: false
      }
    };
  }

  public static limit80(c:FormControl):object {
    return c.value ? (c.value.length < 81 ? null : {
      limit80: {
        valid: false
      }
    }) : null;
  }

  public static onlyNumbers(c:FormControl):object {
    const r = new RegExp('^[0-9]+$');

    return r.test(c.value) ? null : {
      onlyNumbers: {
        valid: false
      }
    };
  }

  public static isHref(c:FormControl):object {
    const r = new RegExp('^(http:|https:|tel:|mailto:).{0,10000}$');

    return r.test(c.value) ? null : {
      isHref: {
        valid: false
      }
    };
  }

  public static alphanumeric(c:FormControl):object {
    const r = new RegExp('^[a-zA-Z0-9 -]+$');

    return r.test(c.value) ? null : {
      alphanumeric: {
        valid: false
      }
    };
  }

  public static hexColor(c:FormControl):object {
    const r = new RegExp('^([0-9a-fA-F]{3}){1,2}$');

    return r.test(c.value) ? null : {
      hexColor: {
        valid: false
      }
    };
  }

  public static basicText(c:FormControl):object {
    const r = new RegExp(/^(?=.*[a-zA-Z0-9])[\w.,!"'\(\)\/\\;$ ]+$/);

    return r.test(c.value) ? null : {
      basicText: {
        valid: false
      }
    };
  }

  public static numbers(c: FormControl) {
    const r = new RegExp(/^-?\d+\.?\d*$/);

    return r.test(c.value) ? null : {
      numbers: {
        valid: false
      }
    };
  }

  public static tooSmall(c: FormControl) {
    return c.value > 0 ? null : {
      tooSmall: {
        valid: false
      }
    };
  }

  public static tooLarge(c: FormControl) {
    const maxVal:number = 4096;

    return c.value < maxVal ? null : {
      tooLarge: {
        valid: false
      }
    };
  }

  public static floats(c: FormControl) {
    const r = new RegExp(/\./);

    return !r.test(c.value) ? null : {
      floats: {
        valid: false
      }
    };
  }

  public static pattern(c: FormControl){
    const r = new RegExp(this['args'][0]);

    return r.test(c.value) ? null : {
      pattern: {
        valid: false
      }
    };
  }

  public static passwordPattern(c: FormControl) {
    const r = new RegExp(/^(?=.*[A-Z])(?=.*[a-z]).{8,72}$/);

    return r.test(c.value) ? null : {
      passwordPattern: {
        valid: false
      }
    };
  }
}
