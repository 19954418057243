<div class="dataChartWrapper">
  <header *ngIf="showHeader" [ngStyle]="{'color': chartForeColor}">
    <span class="title">{{ chartTitle }} <i *ngIf="chartInstruction" class="tooltip tooltip-right" [attr.data-tooltip]="chartInstruction"><i class="material-icons">info</i></i></span>
    <span class="chartMessages" [ngClass]="{'is-visible': (isFetching && !isLoading) || hasError}">
      <span class="fetchingMsg" *ngIf="!hasError">Looking for new data <mat-spinner [diameter]="16"></mat-spinner></span>
      <span class="errorMsg" *ngIf="hasError">Could not update the charts data, retrying in a few seconds... <i class="material-icons">warning</i></span>
    </span>
    <div class="drilledTimeWrapper">
      <div *ngIf="isDrilledDown && !isLoading" class="drilledTime">
        <span class="drilledTime__info">Showing data for: {{ drilledTimespan }}</span>
        <button class="back" (click)="restoreTimeframe()" mat-button color="chart-back-button">
          <i class="material-icons">arrow_back</i>
          restore previous range
        </button>
      </div>
    </div>
    <span class="subtitle" *ngIf="chartSubTitle">{{ chartSubTitle }}</span>
  </header>

  <div class="loadingOverlay" [ngClass]="{'is-loading': isLoading}">
    LOADING DATA <mat-spinner [diameter]="20"></mat-spinner>
  </div>

  <div class="emptyOverlay" *ngIf="isEmpty && !isLoading && !isDrilledDown">
    NO DATA FOR SELECTED TIMEFRAME
  </div>

  <div class="chartWrapper" [ngClass]="{'is-loading': isLoading, 'is-empty': isEmpty && !isDrilledDown}">
    <apx-chart
      #chartObj
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [dataLabels]="chartOptions.dataLabels"
      [labels]="chartOptions.labels"
      [grid]="chartOptions.grid"
      [stroke]="chartOptions.stroke"
      [title]="chartOptions.title"
      [fill]="chartOptions.fill"
      [tooltip]="chartOptions.tooltip"
      [legend]="chartOptions.legend"
      [annotations]="chartOptions.annotations"
      [plotOptions]="chartOptions.plotOptions"
      [theme]="chartOptions.theme"
      [colors]="chartOptions.colors">
    </apx-chart>
  </div>
</div>
