import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { PagesParamsStore } from '../../pages/pages-params.store';

import { APIPendingUser } from '../../core/types/api/api-pending-user-t';
import { DispatcherConfig } from '@trend-core/dispatcher/dispatcher-config-t';
import { PendingUsersTableData } from '../types/components/pending-users-table-data-t';

import { Mediator } from '@trend-core/mediator/mediator';

class DateAndTimePipe extends DatePipe {
  public transform(value): any {
    return super.transform(value, 'medium');
  }
}

@Injectable()
export class AccountPendingUsersTableMediator extends Mediator {
  name = "AccountPendingUsersTableMediator";
  endpoint = this.apiUrl.url() + 'accounts/@all/pending_users';

  // Caching logic members.
  pendingUsersTableData:PendingUsersTableData = {
    cols: [
      { name: 'Email', prop: 'email', cellClass: 'col-email', headerClass: 'col-email' },
      { name: 'Account ID', prop: 'account_id', cellClass: 'col-account_id', headerClass: 'col-account_id' },
      { name: 'Verfification Token', prop: 'verification_token', cellClass: 'col-verification_token', headerClass: 'col-verification_token' },
      { name: 'Created On', prop: 'created_on', cellClass: 'col-created_on', headerClass: 'col-created_on', pipe: new DateAndTimePipe('en-US') },
      // { name: 'Updated On', prop: 'updated_on', cellClass: 'col-updated_on', headerClass: 'col-updated_on', pipe: new DateAndTimePipe('en-US') }
    ],
    rows: []
  };

  constructor(
    public injector:Injector,
    private apiUrl:APIUrlService,
    private pagesParamsStore:PagesParamsStore
  ) {
    super(injector);
  }

  fetchData(componentInstance:any, subscriber:any, source:string, subParams?:object):void {
    // let paginationParams = '?page=' + subParams['pagination']['offset'] + 1 + '&per_page=' + subParams['pagination']['limit'];
    if(componentInstance) componentInstance['isLoading'] = true;

    let httpSub = this.http.get<APIPendingUser[]>(this.endpoint).subscribe(
      (data) => {
        subscriber.next(data);
      },
      (error) => {
        if(componentInstance) componentInstance['error'] = error.message;
        if(componentInstance) componentInstance['isLoading'] = false;
        // subscriber.next(data);
        subscriber.next({});
      }
    );

    this.subs.push(httpSub);
  }

  getRequestSubject(component:any):Observable<any> {
    let dispatcherConfig:DispatcherConfig = {
      list : [
        'pagination'
      ],
      initial: 'pagination'
    };

    return super.getRequestSubject(component, this.name, dispatcherConfig);
  }

  adapt(data:APIPendingUser[]):PendingUsersTableData {
    let account_id = this.pagesParamsStore.getParams().account_id;
    let rows:any[] = [];

    for(var i=0; i < data.length; i++) {
      if(account_id == data[i]['account_id'])
      rows.push(data[i]);
    }

    this.pendingUsersTableData['rows'] = rows;

    return this.pendingUsersTableData;
  }

  // Completely reloads table data from fetch
  reloadTable():void {
    this.dispatcherMap.pagination['hardTrigger']();
  }
}
