<nav mat-tab-nav-bar
     [color]="'primary'"
     [backgroundColor]="''"
     class="">
  <a mat-tab-link
     routerLink="/accounts/list"
     [active]="activeTab == 'list'">
     List
  </a>

  <a mat-tab-link
     routerLink="/accounts/telemetry"
     [active]="activeTab == 'telemetry'">
     Telemetry
  </a>
</nav>

<router-outlet></router-outlet>
