import { NgModule } from '@angular/core';

import { AccountsTableMediator } from './accounts-table.mediator';
import { VerifiedUsersTableMediator } from './verified-users-table.mediator';
import { PendingUsersTableMediator } from './pending-users-table.mediator';
import { AccountPendingUsersTableMediator } from './account-pending-users-table.mediator';
import { AccountVerifiedUsersTableMediator } from './account-verified-users-table.mediator';
import { AccountGroupsTableMediator } from './account-groups-table.mediator';
import { GroupDetailsMediator } from './group-details.mediator';
import { PendingUserDetailsMediator } from './pending-user-details.mediator';
import { VerifiedUserDetailsMediator } from './verified-user-details.mediator';


@NgModule({
  imports: [

  ],
  providers: [
    AccountsTableMediator,
    VerifiedUsersTableMediator,
    VerifiedUserDetailsMediator,
    PendingUsersTableMediator,
    PendingUserDetailsMediator,
    AccountGroupsTableMediator,
    GroupDetailsMediator,
    AccountPendingUsersTableMediator,
    AccountVerifiedUsersTableMediator,
  ],
  declarations: [

  ],
  exports: [

  ]
})
export class MediatorsModule {}
