import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Modal service
import { ModalService } from './modal.service';

// Modals components
import { ConfirmModal } from './confirm-modal/confirm-modal';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

let matModuleArray:any[] = [
  MatDialogModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule
];

let modalsArray:any[] = [
  ConfirmModal
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    matModuleArray
  ],
  declarations: [ modalsArray ],
  providers: [
    ModalService
  ],
  entryComponents: [ modalsArray ],
  exports: [ modalsArray ]
})
export class ModalsModule {}
