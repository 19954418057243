import { Observable, Subject } from 'rxjs';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';
import { Injectable } from "@angular/core";

@Injectable()
export class PaginationService implements DispatcherInterface {
  private pageChangeSubject = new Subject<any>();

  updateSubject = this.pageChangeSubject;

  /* tslint:disable */
  private pairHash:string;
  /* tslint:enable */

  private paginationDefault = {
    count: 0,
    page: 1,
    limit: 30,
    perPage: 30,
    first: null,
    prev: null,
    next: null,
    requestType: null
  };
  private pagination = { ...this.paginationDefault };

  constructor() {}

  getDefaults():Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.getPagination());
    });
  }

  setPairHash(pairHash:string):void {
    this.pairHash = pairHash;
  }

  getPagination():any {
    return this.pagination;
  }

  setPagination(payload):any {
    this.pagination = payload;
  }

  update(payload):void {
    this.pagination = payload;
    this.pageChangeSubject.next(this.pagination);
  }

  onUpdate():Observable<any> {
    return this.pageChangeSubject.asObservable();
  }

  hardTrigger():void {
    this.pageChangeSubject.next(this.pagination);
  }

  public setPageControls(pageLinksConfig):void {
    this.pagination.next = pageLinksConfig.next || null;
    this.pagination.prev = pageLinksConfig.prev || null;
    this.pagination.first = pageLinksConfig.first || null;
  }

  public backToFirstPage():void {
    this.pagination.page = 1;
    this.pagination.next = null;
    this.pagination.prev = null;
    this.pagination.first = null;
    this.pagination.requestType = null;
  }

  public resetPagination():void {
    this.pagination = this.paginationDefault;
  }
}
