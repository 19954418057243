<h1 mat-dialog-title>Configure Account Feature Flags</h1>

<div mat-dialog-content>
  <div *ngIf="!accountFeatureFlags" class="featureFlagsOverlay">
    <span *ngIf="!accountFeatureFlagsError">Loading account feature flags...</span>
    <span *ngIf="accountFeatureFlagsError">We could not get the account feature flags at the moment.</span>
    <button *ngIf="accountFeatureFlagsError" mat-flat-button color="primary" (click)="getAccountFeatureFlags()">Retry</button>
  </div>

  <div *ngFor="let featureFlag of accountFeatureFlags" class="featureFlag">
    <span>{{ featureFlag.featureKey }}</span>
    <mat-slide-toggle color="primary"
                      [checked]="featureFlag.enabled"
                      [(ngModel)]="featureFlag.enabled">
    </mat-slide-toggle>
  </div>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Cancel</button>
  <button (click)="setAccountFeatureFlags()" mat-flat-button color="primary" matDialogClose>Save</button>
</div>
