import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

import { APIAccount } from '../types/api/api-account-t';

@Injectable()
export class Account {
  private accounts:APIAccount[];
  private accountsEndpoint:string = this.apiUrl.url() + 'accounts/account';
  private accountEndpoint:string = this.apiUrl.url() + 'accounts/';
  private getAccountsPromise:Promise<APIAccount[]>;

  constructor(
    private http:HttpClient,
    private apiUrl:APIUrlService
  ) {}

  public initAccounts() {
    this.getAccountsPromise = new Promise((resolve, reject) => {
      this.fetchAccounts().subscribe(accounts => {
        this.accounts = accounts;
        resolve(accounts);
      });
    });

    return this.getAccountsPromise;
  }

  public fetchAccounts() {
    return this.http.get<APIAccount[]>(this.accountsEndpoint);
  }

  public getAccounts() {
    if(!this.accounts) {
      this.initAccounts();
    }

    return this.getAccountsPromise;
  }

  public createAccount(newAccount:object):Observable<any> {
    return this.http.post<APIAccount>(this.accountsEndpoint, newAccount);
  }

  public getAccount(accountId:string):Observable<any> {
    return this.http.get<APIAccount>(this.accountEndpoint + accountId);
  }

  public updateAccountName(accountId:string, accountName:string):Observable<any> {
    return this.http.put<APIAccount>(this.accountEndpoint + accountId, {
      tenant_name: accountName
    });
  }

  public deleteAccount(accountId:string):Observable<any> {
    return this.http.delete<null>(this.accountEndpoint + accountId);
  }

  public getAllAccountFeatures():Observable<any> {
    return this.http.get<any>(this.accountEndpoint + 'features');
  }

  public addAccountFeature(featureKey:string):Observable<any> {
    return this.http.post<any>(this.accountEndpoint + 'features', {
      feature_flag: featureKey
    });
  }

  public deleteAccountFeature(featureKey:string):Observable<any> {
    return this.http.delete<any>(this.accountEndpoint + 'features/' + featureKey);
  }

  public setAccountFeatureFlags(accountId:string, featureFlags:object):Observable<any> {
    return this.http.put(this.accountEndpoint + accountId + '/features', featureFlags);
  }
}
