<header *ngIf="_searchConfig" class="tableHeader">
  <div class="tableSearch" [ngClass]="{'is-ready': isTableDataReady}">
    <div class="tooltip-left tableSearch__info" [attr.data-tooltip]="searchInfo">
      <i class="material-icons">info</i>
    </div>
    <div class="tonicFormField">
      <label>Search {{ searchLabel }}</label>
      <mat-form-field appearance="outline" [ngStyle]="{'width': searchWidth}">
        <mat-icon matPrefix>search</mat-icon>
        <!-- <mat-label>Search {{ searchLabel }}</mat-label> -->
        <input
          id="table_search"
          [(ngModel)]="tableSearchValue"
          (ngModelChange)="onTableFiltering()"
          [disabled]="!isTableDataReady"
          matInput>
      </mat-form-field>
    </div>
  </div>
</header>

<section [ngClass]="{'is-ready': isTableDataReady, 'has-no-pagination': noPagination}">
  <div *ngIf="!isTableDataReady" class="tableOverlay">
    Loading...
  </div>

  <div *ngIf="isTableDataReady && displayedResults && displayedResults.length == 0" class="tableOverlay">
    {{ emptyTableMessage || 'No Results' }}
  </div>

  <div *ngIf="tableError" class="tableOverlay">
    {{ tableErrorMsg ? tableErrorMsg : 'Something went wrong.' }}
  </div>

  <mat-table
    *ngIf="isTableDataReady"
    [dataSource]="displayedResults"
    [ngClass]="{'is-ready': isTableDataReady}"
    class="{{ tableClass }}">
    <ng-container *ngFor="let col of colsDef" matColumnDef="{{ col.colId }}">
      <mat-header-cell *matHeaderCellDef (click)="sortData(col.colId, col.colSortConfig)" [ngClass]="{'sortableHeaderCell': col.colSortConfig && col.colSortConfig.isSortable}">{{ col.colHeader }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-container
          [ngIf]="col.colTmpl"
          *ngTemplateOutlet="col.colTmpl;context:element">
        </ng-container>
        <span *ngIf="!col.colTmpl">{{ element[col.colId] }}</span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="cols; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: cols; let i = index"
             (click)="onClickEvent(row, i)"
             [ngClass]="{'rowClickEnabled':rowClickEnabled, 'active': selectedRowIndex == i}"></mat-row>
  </mat-table>
</section>

<mat-paginator #paginator
               *ngIf="!noPagination"
               [ngClass]="{'has-backend-pagination': useBackendPagination}"
               [length]="length"
               [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions"
               [showFirstLastButtons]="true"
               (page)="pageEvent = onPaginatorUpdate($event)">
</mat-paginator>
