import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Breadcrumb } from '../../core/types/components/breadcrumbs-t';


@Injectable()
export class BreadcrumbsService {
  public updateBreadcrumbsSub = new Subject<any>();

  constructor() {}

  public updateBreadcrumbs(breadcrumbs:Breadcrumb[]):void {
    this.updateBreadcrumbsSub.next(breadcrumbs);
  }

  public onBreadcrumbsUpdate():Observable<any> {
    return this.updateBreadcrumbsSub.asObservable();
  }
}
