import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { DispatcherConfig } from '@trend-core/dispatcher/dispatcher-config-t';
import { DrawerBody } from '../../common/drawer/drawer-body-t';

import { Mediator } from '@trend-core/mediator/mediator';

@Injectable()
export class GroupDetailsMediator extends Mediator {
  name = "GroupDetailsMediator";
  endpoint:string;

  constructor(public injector:Injector){
    super(injector);
  }

  adapt(data:any):DrawerBody {
    return {
      body: data
    }
  }

  fetchData(componentInstance:any, subscriber:any, source:string, subParams:object):void {
    if(componentInstance) componentInstance['isLoading'] = false;
    if(componentInstance) componentInstance['error'] = false;

    subscriber.next(subParams['mainDrawer']);
  }

  getRequestSubject(c):Observable<any>{
    let dispatcherConfig:DispatcherConfig = {
      list: [
        'mainDrawer'
      ],
      initial: null //TODO: get this from the url
    }
    return super.getRequestSubject(c, this.name, dispatcherConfig);
  }
}
