<h1 mat-dialog-title>Change account licence subscription</h1>

<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Subscription licenses</mat-label>
    <input id="subscription_licenses" [formControl]="subscriptionLicensesFC" matInput required>
    <mat-error *ngIf="subscriptionLicensesFC.invalid">{{ getInputErrorMessage(subscriptionLicensesFC) }}</mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Cancel</button>
  <button (click)="updateSubscriptionLicenses()" mat-flat-button color="primary" matDialogClose [disabled]="subscriptionLicensesFC.status == 'INVALID'">Update</button>
</div>
