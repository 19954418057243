import { Component, ViewChild, OnInit, OnDestroy, Input, ViewEncapsulation } from "@angular/core";
import { Subscription, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ApexYAxis,
  ChartType,
  ApexAxisChartSeries,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexPlotOptions,
  ApexFill,
  ApexLegend,
  ApexGrid
} from 'ng-apexcharts';

import { ChartComponent, ApexXAxis } from "ng-apexcharts";

import { TimeframeService } from '../timeframe/timeframe.service';
import { CssVariablesService } from '../themes/css-variables.service';
import { FeatureToggleService } from '@trend-common/feature-toggle.service';
import { CHART_COLORS } from '../themes/colors';

import { ChartOptions } from './data-charts-config-t';
import { TimeframeConfig } from '@trend-core/timeframe-config-t';


@Component({
  selector: "data-charts",
  templateUrl: "./data-charts.html",
  styleUrls: ['./data-charts.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DataCharts implements OnInit, OnDestroy {
  @ViewChild("chartObj") chartObj: ChartComponent;

  // Options
  public chartOptions:ChartOptions;
  public chartTitle:string;
  public chartSubTitle:string;
  public chartInstruction:string;
  public chartForeColor:string;
  public chartBGColor:string;
  public drilledTimespan:string;
  public showHeader:boolean;
  // Status
  public hasError:boolean = false;
  public isLoading:boolean = true;
  public isFetching:boolean = false;
  public isDrilledDown:boolean = false;
  public isEmpty:boolean = false;

  private _subscription:Subscription;
  private _timeframeCache:TimeframeConfig[] = [];
  private _isHourDisplay:boolean;
  private d$ = new Subject<any>();

  @Input() _chartType:ChartType;
  @Input() _data:any;
  @Input() _chartTitle:string;
  @Input() _chartTitleConfig:ApexTitleSubtitle;
  @Input() _chartSubTitle:string;
  @Input() _mediator:any;
  @Input() _height:number = 300;
  @Input() _width:number;
  @Input() _yaxis:ApexYAxis | ApexYAxis[];
  @Input() _xaxis:ApexXAxis | ApexXAxis[];
  @Input() _xAxisType:'category' | 'datetime' | 'numeric' = 'category';
  @Input() _xAxisProp:string;
  @Input() _yAxisProp:string[];
  @Input() _strokeConfig:ApexStroke;
  @Input() _showGridXaxisLines:boolean = true;
  @Input() _showGridYaxisLines:boolean = true;
  @Input() _gridLeftPadding:number = 15;
  @Input() _gridRightPadding:number = 15;
  @Input() _enableDataLabels:boolean = false;
  @Input() _isStacked:boolean = false;
  @Input() _chartId:string = '';
  @Input() _chartGroup:string;
  @Input() _colors: string[];
  @Input() _enableAnimations:boolean = true;
  @Input() _useNativeZoom:boolean = true;
  @Input() _chartForeColor:string;
  @Input() _showToolbar:boolean = true;
  @Input() _noDataText:string = 'No Data';
  @Input() _showHeader:boolean = true;
  @Input() _showLegend:boolean = true;
  @Input() _disableCustomDrilldown:boolean = false;
  @Input() _onSelectFn:Function;
  @Input() _drilldownFn:Function;
  @Input() _chartContextExtra:object;
  @Input() _chartInstruction:string;
  @Input() _tooltipConfig:ApexTooltip;
  @Input() _showForZeroSeriesLegend:boolean = true;
  @Input() _plotOptions:ApexPlotOptions;
  @Input() _fillConfig:ApexFill;
  @Input() _legendConfig:ApexLegend;
  @Input() _gridConfig:ApexGrid;
  @Input() _chartBGColor:string;
  @Input() _labels:any[];
  @Input() _hasFeatureFlagsGuard:boolean = false;


  constructor(
    private _timeframeService: TimeframeService,
    private _cssVariablesService:CssVariablesService,
    private _featureToggleService:FeatureToggleService
  ) {}

  ngOnInit():void {
    if(!this._chartType) {
      console.error('DataCharts: No chart type provided.');
      return;
    }

    this._buildChart();

    if(this._mediator) {
      this._subscribe();
    } else if(this._data) {
      this._injectData(this._data, 'init');
    }

    this._timeframeService.onUpdate().subscribe((payload: TimeframeConfig) => {
      // reset drilldown when timeframe is changed through timeframe component.
      if((payload.isRealtime && this.isDrilledDown) || (this.isDrilledDown && !payload.isRealtime && !payload['forced'])) this.restoreTimeframe(true);
    })
  }

  ngOnDestroy() {
    if(this._mediator) {
      this._mediator.kill();
      this._subscription.unsubscribe();
      this.d$.next();
      this.d$.complete();
    }
  }

  private _buildChart():void {
    this.chartTitle = this._chartTitle;
    this.chartSubTitle = this._chartSubTitle;
    this.chartInstruction = this._chartInstruction;
    this.chartForeColor = this._chartForeColor ? this._chartForeColor : this._cssVariablesService.getTheme().get('text_base_color');
    this.chartBGColor = this._chartBGColor ? this._chartBGColor : this._cssVariablesService.getTheme().get('color_secondary_background');
    this.showHeader = this._showHeader;

    let chartOptions:ChartOptions = {
      series: [],
      chart: {
        id: this._chartId,
        height: this._height,
        type: this._chartType,
        zoom: {
          enabled: this._useNativeZoom
        },
        background: this.chartBGColor,
        foreColor: this.chartForeColor,
        fontFamily: '"Segoe UI",-apple-system,BlinkMacSystemFont,"Helvetica Neue",Helvetica,Arial,sans-serif',
        events: {
          dataPointSelection: this._onDataPointClick.bind(this),
          markerClick: this._onMarkerClick.bind(this)
        },
        stacked: this._isStacked,
        animations: {
          enabled: this._enableAnimations
        },
        toolbar: {
          show: this._showToolbar
        }
      },
      dataLabels: {
        enabled: this._enableDataLabels
      },
      grid: {
        row: {
          colors: ["transparent"],
          opacity: 0
        },
        column: {
          colors: ['transparent'],
          opacity: 0
        },
        xaxis: {
          lines: {
            show: this._showGridXaxisLines
          }
        },
        yaxis: {
          lines: {
            show: this._showGridYaxisLines
          }
        },
        padding: {
          left: this._gridLeftPadding,
          right: this._gridRightPadding
        }
      },
      theme: {
        palette: 'palette1'
      },
      xaxis: {
        categories: [],
        type: this._xAxisType,
        axisBorder: {
          color: '#ACACAD'
        },
        axisTicks: {
          color: '#ACACAD'
        },
        labels: {
          style: {
            colors: '#ACACAD'
          },
          trim: true,
          formatter: this._formatterFn('MM/DD/YYYY', false)
        },
        tooltip: {
          enabled: false
        }
      },
      yaxis: this._yaxis,
      legend: {
        show: this._showLegend,
        showForZeroSeries: this._showForZeroSeriesLegend,
        position: 'right',
        horizontalAlign: 'center',
        offsetY: 0,
        itemMargin: {
          vertical: 6
        }
      },
      fill: this._fillConfig,
      noData: {
        text: this._noDataText,
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined
        }
      },
      tooltip: {
        x: {
          formatter: this._tooltipFormatterFn(false)
        },
        y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            return parseFloat(value.toString()).toFixed(0);
          }
        }
      },
      colors: this._colors || CHART_COLORS
    };

    if(this._width) chartOptions.chart.width = this._width;
    if(this._chartTitleConfig) chartOptions.title = this._chartTitleConfig;
    if(this._chartGroup) chartOptions.chart.group = this._chartGroup;
    if(this._strokeConfig) chartOptions.stroke = this._strokeConfig;
    if(this._xaxis) chartOptions.xaxis = this._xaxis;
    if(this._tooltipConfig) chartOptions.tooltip = this._tooltipConfig;
    if(this._plotOptions) chartOptions.plotOptions = this._plotOptions;
    if(this._legendConfig) chartOptions.legend = this._legendConfig;
    if(this._gridConfig) chartOptions.grid = this._gridConfig;
    if(this._labels) chartOptions.labels = this._labels;

    this.chartOptions = chartOptions;
  }

  private _injectData(data:ApexAxisChartSeries, source:string):void {
    if(!data) return;

    if(this._chartType === 'donut') {
      timer(500).subscribe(() => {
        this.chartOptions = {...this.chartOptions, ...{
          series: data['series']
        }};

        timer(500).subscribe(() => {
          this.isEmpty = false;
          this.hasError = false;
          this.isLoading = false;
          this.isFetching = false;
        });
      });
      return;
    }

    if(this._mediator){
      data = this._mediator.adapt(data, this._xAxisProp, this._yAxisProp);
    }

    if(JSON.stringify(data['series']) !== JSON.stringify(this.chartOptions.series)) {
      timer(800).subscribe(() => {
        this.chartOptions = {...this.chartOptions, ...{
          xaxis: {...this.chartOptions.xaxis, ...{
            categories: data['categories'],
            tickAmount: data['categories'].length > 24 ? 24 : undefined,
          }},
          annotations: data['annotations'],
          series: data['series']
        }};

        this._updateDateFormatterForRange(data['categories']);

        timer(500).subscribe(() => {
          this.isEmpty = this._checkIfChartIsEmpty(data['series']);
          this.hasError = false;
          this.isLoading = false;
          this.isFetching = false;
        });
      });
    } else {
      this.hasError = false;
      this.isLoading = false;
      this.isFetching = false;
    }
  }

  private _subscribe():void {
    this._subscription = this._mediator.getRequestSubject(this).pipe(takeUntil(this.d$)).subscribe(
      (data:object) => {
        if(this._hasFeatureFlagsGuard) {
          this._featureToggleService.getFeatures().then((featureFlags) => {
            this._injectData(data['data'], data['source']);
          });
        } else {
          this._injectData(data['data'], data['source']);
        }
      },
      (data:object) => this._handleSubError(data['error'], data['source'])
    );
  }

  private _onDataPointClick(event, chartContext, config) {
    this._drillDown(config);
  }

  private _onMarkerClick(event:Event, chartContext:object, config:object) {
    this._drillDown(config);
  }

  private _drillDown(config:object):void {
    if(this._disableCustomDrilldown || (this._isHourDisplay && !this._onSelectFn)) return;

    if(this._onSelectFn) {
      let date:string = config['w']['config']['xaxis']['categories'][config['dataPointIndex']];

      this._onSelectFn(date);
      return;
    }

    this.isLoading = true;

    // let xaxisLabelb:string = config['w']['globals']['categoryLabels'][config['dataPointIndex']]; // or config['w']['globals']['labels'][config['dataPointIndex']]
    let xaxisLabel:string = config['w']['globals']['lastXAxis']['categories'][config['dataPointIndex']]; // or config['w']['globals']['labels'][config['dataPointIndex']]

    if(xaxisLabel.indexOf(' to ') > -1 || xaxisLabel.indexOf(' and ') > -1) {
      let date = xaxisLabel.split((xaxisLabel.indexOf(' to ') > -1) ? ' to ' : ' and ');

      let from = date[0];
      let fromDay = from.split(' ')[1];
      let fromMonth = from.split(' ')[0];
      let toDay = date[1].split('/')[0];
      let year = date[1].split('/')[1];

      let timeframe = Object.assign({}, this._timeframeService.getTimeframe());
      this._timeframeCache.push(Object.assign({}, this._timeframeService.getTimeframe()));

      timeframe['isRealtime'] = false;
      timeframe['from'] = moment(from + ' 20' + year).format('MMMM Do YYYY');

      //if the to date is in another month, must add a month
      timeframe['to'] = moment(((fromDay < toDay) ? fromMonth : moment(from + ' 20' + year).add(1, 'M').format('MMMM')) + " " + toDay + ' 20' + year).format('MMMM Do YYYY');
      timeframe['unit'] = 'days';

      this._timeframeService.forceTimeframeUpdate(timeframe, 'custom', true);
      this.drilledTimespan = this._timeframeService.formatRangeForDisplay(this._timeframeService.getTimeframe());

      this._isHourDisplay = false;
      this.isDrilledDown = true;
    } else if(moment(xaxisLabel)['_isValid']) {
      // xaxisLabel is a timestamp
      let timeframe = Object.assign({}, this._timeframeService.getTimeframe());
      this._timeframeCache.push(Object.assign({}, this._timeframeService.getTimeframe()));

      timeframe['isRealtime'] = false;
      // timeframe['from'] = moment(xaxisLabel).utcOffset(moment().utcOffset()).format();
      timeframe['from'] = moment(xaxisLabel).format();
      // timeframe['to'] = moment(xaxisLabel).add(1, 'day') .utcOffset(moment().utcOffset()).format();
      timeframe['to'] = moment(xaxisLabel).add(1, 'day').format();
      timeframe['unit'] = 'hours';
      timeframe['value'] = 24;

      this.isDrilledDown = true;
      this._isHourDisplay = true;

      this._timeframeService.forceTimeframeUpdate(timeframe, 'custom', true);
      this.drilledTimespan = this._timeframeService.formatRangeForDisplay(this._timeframeService.getTimeframe());
      this.chartOptions.xaxis.labels.formatter = this._formatterFn('HH:mm', true);
    }
  }

  private _formatterFn(format:string, isHourDisplay) {
    return function(value, timestamp, index) {
      if(value === 0) return 0;
      if(!isHourDisplay) {
        return moment(value)['_isValid'] ? moment(value).utc().format(format) : value;
      } else {
        return moment(value)['_isValid'] ? moment(value).utcOffset(moment().utcOffset()).format(format) : value;
      }
    };
  }

  private _tooltipFormatterFn(isHourDisplay:boolean):any {
    return function(value, { series, seriesIndex, dataPointIndex, w }) {
      if(value === 0) return '';
      if(!w) return value;
      let fullDate = w['globals']['lastXAxis']['categories'][dataPointIndex];
      if(!isHourDisplay) {
        return moment(fullDate)['_isValid'] ? moment(fullDate).utc().format('MM/DD/YYYY') : fullDate;
      } else {
        return moment(fullDate)['_isValid'] ? moment(fullDate).utcOffset(moment().utcOffset()).format('MM/DD/YYYY, H:mm:ss') : fullDate;
      }
    }
  }

  private _updateDateFormatterForRange(categories:string[]):void {
    let firstDate = moment(categories[0]);
    let lastDate = moment(categories[categories.length - 1]);

    if(lastDate.diff(firstDate, 'days') < (categories.length - 2)) {
      this._isHourDisplay = true;
      this.chartOptions.xaxis.labels.formatter = this._formatterFn('HH:mm', true);
      if(!this._tooltipConfig) this.chartOptions.tooltip.x.formatter = this._tooltipFormatterFn(true);
    } else {
      this._isHourDisplay = false;
      this.chartOptions.xaxis.labels.formatter = this._formatterFn('MM/DD/YYYY', false);
      if(!this._tooltipConfig) this.chartOptions.tooltip.x.formatter = this._tooltipFormatterFn(false);
    }
  }

  private _handleSubError(error, source:string) {
    if(source === 'polling') return;
    this.isLoading = false;
    this.isFetching = false;
    this.hasError = true;
  }

  private _checkIfChartIsEmpty(series):boolean {
    let totalCount:number = 0;
    for(var s=0; s<series.length; s++) {
      for(var d=0; d<series[s].data.length; d++) {
        totalCount += series[s].data[d];
      }
    }
    return totalCount === 0;
  }

  public restoreTimeframe(resetDrilldown?:boolean):void {
    this.isLoading = true;
    this.chartOptions.xaxis.labels.formatter = this._formatterFn('MM/DD/YYYY', false);
    this._isHourDisplay = false;

    if(resetDrilldown) {
      this._timeframeCache = [];
      this.isDrilledDown = false;
      this.drilledTimespan = '';
      return;
    }

    if(this._timeframeCache.length > 1) {
      this._timeframeService.forceTimeframeUpdate(this._timeframeCache[this._timeframeCache.length - 1], 'custom', false);
      this.drilledTimespan = this._timeframeService.formatRangeForDisplay(this._timeframeCache[this._timeframeCache.length - 1]);
      this._timeframeCache.pop();
    } else {
      this._timeframeService.forceTimeframeUpdate(this._timeframeCache[0], 'custom', false);
      this._timeframeCache = [];
      this.isDrilledDown = false;
    }
  }
}
