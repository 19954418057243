import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APIUrlService } from '../api/api-url.service';

@Injectable()
export class Events {
  private eventsEndpoint:string = this.api.url() + 'security/';

  constructor(
    private http:HttpClient,
    private api:APIUrlService
  ) {}

  fetchEvent(event_id:string, event_type:string):Observable<any> {
    return this.http.get(this.eventsEndpoint + event_type + '/events/' + event_id);
  }
}
