import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';

import { WindowService } from '@trend-core/trend/window';


@Component({
  selector: 'login',
  templateUrl: 'login.html',
  styleUrls: ['./login.scss']
})
export class LoginPage implements OnInit {
  public emailFC = new FormControl('', [ Validators.required, Validators.email ]);
  public passwordFC = new FormControl('', [ Validators.required ]);

  public isProd:boolean = false;
  public isLoggingIn:boolean = false;
  public wrongCredentialsError:boolean = false;
  public authError:boolean = false;


  constructor(
    private router: Router,
    private windowService:WindowService
  ) {}

  ngOnInit():void {
    if(this.windowService.getWindow().location.href.indexOf('http://localhost:1101/#/login') === 0) {
      window.addEventListener('storage', this._onStorageEvent.bind(this), false);
    }
    if(this.windowService.getWindow().location.href.indexOf('kontroller.prod.im7.io') > -1 || this.windowService.getWindow().location.href.indexOf('kontroller.app-protect.trendmicro.com') > -1) {
      this.isProd = true;
    }
  }

  private _onStorageEvent(event:object):void {
    if(event['key'] === '_tmcotok' && event['newValue']) this.router.navigate(['accounts']);
  }

  public getInputErrorMessage(formControl:any) {
    return formControl.hasError('required') ? 'You must enter a value' :
           formControl.hasError('email') ? 'Not a valid email' : '';
  }

  public getADFSUrl(region:string):void {
    let tenantId:string = '?tenantId=3e04753a-ae5b-42d4-a86d-d6f05460f9e4';
    let baseUrl:string = 'https://myapps.microsoft.com/signin/';
    let applicationId:string = '';

    if(this.windowService.getWindow().location.href.indexOf('.stg.') > -1){
      if(region !== '') {
        applicationId = '9fb2ad63-d338-4555-b0fd-adba95de92e4';
      } else {
        applicationId = '9287c0e1-3983-4b28-a238-7715b3dc3d15';
      }
    } else if(this.windowService.getWindow().location.href.indexOf('.test.') > -1) {
      if(region !== '') {
        applicationId = '6996b2e0-9d62-41b0-b301-2e19ec8e655a';
      } else {
        applicationId = '6162ee43-1050-4791-a92f-88880ea90f9a';
      }
    } else if(this.windowService.getWindow().location.href.indexOf('http://localhost:1101/#/') > -1) {
      if(region !== '') {
          if(environment.apiEnv === "test"){
            applicationId = '6996b2e0-9d62-41b0-b301-2e19ec8e655a';
          }else{
            applicationId = '9fb2ad63-d338-4555-b0fd-adba95de92e4';
          }
      } else {
          if(environment.apiEnv === "test"){
            applicationId = '6162ee43-1050-4791-a92f-88880ea90f9a';
          }else{
            applicationId = '9287c0e1-3983-4b28-a238-7715b3dc3d15';
          }
      }
    } else {
      switch(region) {
        case 'au-1':
            applicationId = 'b3ae9925-7dd6-42f2-a3e5-620aa58ba52b';
            break;
        case 'ca-1':
            applicationId = '69fce878-1b7d-4bb3-bb9d-c357a5c33e20';
            break;
        case 'de-1':
            applicationId = '08663936-8217-4423-9ad4-74eec4f6b89e';
            break;
        case 'gb-1':
            applicationId = 'e2f13c2b-92d4-4ee9-a7b8-6127790b5bb8';
            break;
        case 'jp-1':
            applicationId = '545d35c9-9925-4424-902d-21e1e6361558';
            break;
        case 'in-1':
            applicationId = 'a35db8ee-7bc1-4de3-a037-baf71f719ffc';
            break;
        case 'trend-us-1':
            applicationId = '89367f12-e8a0-4b65-a87d-c5c6b78c5b46';
            break;
        default:
            applicationId = 'bb241fbe-6de7-46f7-b9ea-4739d159821c';
      }
    }
    this.windowService.getWindow().location.href = baseUrl + applicationId + tenantId
  }
}
