import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';


@Component({
  selector: 'accounts',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'accounts.html',
  styleUrls: ['accounts.scss']
})
export class AccountsPage {
  private _activeTab:string;
  public get activeTab():string {
    return this._activeTab;
  }
  public set activeTab(activeTab:string) {
    this._activeTab = activeTab;
  }

  constructor(
    private router:Router,
    private route:ActivatedRoute
  ) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.setActiveTab();
      }
    });
  }

  public setActiveTab():void {
    this.activeTab = this.route.children[0] ? this.route.children[0].routeConfig.path : 'list';
  }
}
