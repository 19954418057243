<div *ngIf="!vmDataLoaded && !vmDataError" class="vmDataOverlay">
  Fetching the group vmData...
</div>

<div *ngIf="vmDataError" class="vmDataOverlay">
  The group vmData cannot be retrieve at the moment.
</div>

<section>
  <div *ngIf="vmData && vmDataLoaded">
    <div class="detail-table">
      <ul>
        <li class="detail-table__row">
          <span class="label">version</span>
          <span class="value">{{ vmData.version }}</span>
        </li>
      </ul>
    </div>

    <h4>blobs</h4>
    <div class="detail-table">
      <ul>
        <li *ngFor="let blob of vmData.blobs" class="detail-table__row">
          <span class="label">{{ blob.name }}</span>
          <span class="value">{{ blob.version }}</span>
        </li>
      </ul>
    </div>

    <h4>entries</h4>
    <div class="detail-table" *ngFor="let entry of vmData.entries">
      <h5 class="detail-table__title">{{ entry.key }}</h5>
      <ul>
        <li class="detail-table__row">
          <span class="label">Version</span>
          <span class="value">{{ entry.version }}</span>
        </li>
        <li class="detail-table__row detail-table__row--overflow">
          <span class="label">Payload</span>
          <span class="value">
            <ngx-json-viewer [json]="entry.payload" [expanded]="false"></ngx-json-viewer>
          </span>
        </li>
      </ul>
    </div>
  </div>
</section>
