import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, timer } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

@Injectable()
export class CloudProviderAccountsService {
  private _addAWSAccountInfo:string = this.apiUrlService.url() + 'agent/apps/';

  constructor(
    private http:HttpClient,
    private apiUrlService:APIUrlService
  ) {}

  public addAWSAccount(awsAccountID:string, awsAccountName:string):Observable<any> {
    // return this.http.get(this._addAWSAccountInfo + groupId + '/vmdatas/@latest');
    let stubSub = new Subject();
    timer(2000).subscribe(() => {
      stubSub.next({
        url: 'someUrl?id=' + awsAccountID + '&name=' + awsAccountName
      })
    });
    return stubSub.asObservable();
  }

  public addARNToAWSAccount(awsAccountID:string, awsAccountARN:string):Observable<any> {
    // return this.http.get(this._addAWSAccountInfo + groupId + '/vmdatas/@latest');
    let stubSub = new Subject();
    timer(2000).subscribe(() => {
      stubSub.next()
    });
    return stubSub.asObservable();
  }
}
