export const TREND_COLORS = {
  'trend:red': '#D71F20',
  'trend:grey': '#050708',
  'red:100': '#6e0002',
  'red:90': '#9d0003',
  'red:80': '#b80003',
  'red:70': '#d71920',
  'red:60': '#e52630',
  'red:50': '#f24c4f',
  'red:40': '#f46f71',
  'red:30': '#fd999a',
  'red:20': '#fcc3c4',
  'red:10': '#fee1e2',
  'magenta:100': '#750037',
  'magenta:90': '#960043',
  'magenta:80': '#b3004c',
  'magenta:70': '#ca0455',
  'magenta:60': '#dc1d68',
  'magenta:50': '#e94181',
  'magenta:40': '#f36fa0',
  'magenta:30': '#f9a0c1',
  'magenta:20': '#fcc3d8',
  'magenta:10': '#fee1ec',
  'purple:100': '#460086',
  'purple:90': '#5300a5',
  'purple:80': '#6304ca',
  'purple:70': '#771ddc',
  'purple:60': '#8f41e9',
  'purple:50': '#ab6ff3',
  'purple:40': '#bb89f6',
  'purple:30': '#cca6f9',
  'purple:20': '#ddc3fc',
  'purple:10': '#eee1fe',
  'blue:100': '#002a7e',
  'blue:90': '#00349d',
  'blue:80': '#003db8',
  'blue:70': '#0547cd',
  'blue:60': '#1e5ede',
  'blue:50': '#578aef',
  'blue:40': '#6f9bf4',
  'blue:30': '#95b7fc',
  'blue:20': '#c3d6fc',
  'blue:10': '#e1ebfe',
  'green:100': '#003011',
  'green:90': '#00461a',
  'green:80': '#005c24',
  'green:70': '#00712e',
  'green:60': '#008539',
  'green:50': '#00a94f',
  'green:40': '#04c45a',
  'green:30': '#40e884',
  'green:20': '#89f6b2',
  'green:10': '#c3fcd8',
  'teal:100': '#004034',
  'teal:90': '#005242',
  'teal:80': '#006451',
  'teal:70': '#00755f',
  'teal:60': '#00866c',
  'teal:50': '#00a584',
  'teal:40': '#04caa1',
  'teal:30': '#41e9c5',
  'teal:20': '#89f6df',
  'teal:10': '#c3fcf0',
  'cyan:100': '#003664',
  'cyan:90': '#004575',
  'cyan:80': '#005486',
  'cyan:70': '#006496',
  'cyan:60': '#0075a5',
  'cyan:50': '#0095bf',
  'cyan:40': '#10b4d3',
  'cyan:30': '#41d8e9',
  'cyan:20': '#89f0f6',
  'cyan:10': '#c3f9fc',
  'gray:100': '#151515',
  'gray:90': '#212121',
  'gray:85': '#292929',
  'gray:80': '#303030',
  'gray:70': '#424242',
  'gray:60': '#5e5e5e',
  'gray:50': '#8a8a8a',
  'gray:40': '#adadad',
  'gray:30': '#c9c9c9',
  'gray:20': '#e0e0e0',
  'gray:10': '#f2f2f2',
  'orange:50': '#ff7633',
  'yellow:50': '#faba2a',
  'white:emphasis': '#FFFFFF',
  'white:primary': '#EDEDED',
  'white:secondary': '#A1A1A2',
  'white:tertiary': '#838383',
  'white:disabled': '#565656',
  'black:emphasis': '#000000',
  'black:primary': '#141414',
  'black:secondary': '#595959',
  'black:tertiary': '#757575',
  'black:disabled': '#B2B2B2'
}

export const TREND_LIGHT_TEXT_COLORS = {
  emphasis: '#000000',
  primary: '#141414',
  secondary: '#595959',
  tertiary: '#757575',
  disabled: '#B2B2B2',
  link: '#1E5EDE',
  error: '#e52630',
  warning: '#ff7633'
}
export const TREND_DARK_TEXT_COLORS = {
  emphasis: '#FFFFFF',
  primary: '#EDEDED',
  secondary: '#A1A1A2',
  tertiary: '#838383',
  disabled: '#565656',
  link: '#6F9BF4',
  error: '#f24c4f',
  warning: '#ff7633'
}
export const TREND_LIGHT_FUNCTIONAL_COLORS = {
  risk_high: '#e52630',
  risk_medium: '#ff7633',
  risk_low: '#faba2a',
  risk_safe: '#00a94f',
  risk_information: '#1e5ede',
  risk_unknown: '#8a8a8a',
  table_loading: '#FAFAD6'
}
export const TREND_DARK_FUNCTIONAL_COLORS = {
  risk_high: '#f24c4f',
  risk_medium: '#ff7633',
  risk_low: '#faba2a',
  risk_safe: '#04c45a',
  risk_information: '#578aef',
  risk_unknown: '#8a8a8a',
  table_loading: '#252525'
}

export const CHART_COLORS = [
  TREND_COLORS['gray:50'],
  TREND_COLORS['blue:50'],
  TREND_COLORS['green:40'],
  TREND_COLORS['orange:50'],
  TREND_COLORS['cyan:40'],
  TREND_COLORS['red:50'],
  TREND_COLORS['purple:50'],
  TREND_COLORS['teal:40'],
  TREND_COLORS['magenta:40'],
  TREND_COLORS['green:30'],
  TREND_COLORS['yellow:50'],
]
