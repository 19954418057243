import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Account } from '../../../core/stores/account';

import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';


@Component({
  selector: 'create-account-modal',
  templateUrl: 'create-account-modal.html',
})
export class CreateAccountModal {
  public tenantNameFC:FormControl = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<CreateAccountModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private account:Account,
    private feedbackMessagesService:FeedbackMessagesService
  ) {}

  public create():void {
    this.account.createAccount({
      tenant_name: this.tenantNameFC.value
    }).subscribe(() => {
      if(this.modalData && this.modalData['afterSuccessFn']) {
        this.modalData['afterSuccessFn']();
      }

      this.dialogRef.close();

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Account successfully created',
        text: 'The new account will appear in the accounts table in a few moments.'
      });

      this.account.initAccounts();
    }, (err:object) => {
      console.error('CreateAccountModal | account.createAccount failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not create the account.'
      });
    })
  }

  public cancel():void {
    this.dialogRef.close();
  }
}
