import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIIntegration, IntegrationCreate, IntegrationUpdate } from '@trend-core/api/api-integrations-t';
import { APIUrlService } from '../api/api-url.service';

@Injectable()
export class Integrations {
  public integrationsEndpoint:string = this.api.url() + 'monitoring/integrations/';
  public integrationsNewEndpoint:string = this.api.url() + 'monitoring/';
  public externalIdGeneratorEndpoint:string = this.api.url() + 'monitoring/aws/generate-external-id/';

  constructor(
    private http:HttpClient,
    private api:APIUrlService
  ) {}

  public fetchIntegration(name:string, integrationId:string):Observable<any> {
    return this.http.get<APIIntegration>(this.integrationsEndpoint + name + '/' + integrationId);
  }

  public getGeneratedId():Observable<any> {
    return this.http.get(this.externalIdGeneratorEndpoint);
  }

  public addIntegrationToAccount(type:string, integration:IntegrationCreate):Observable<any> {
    return this.http.post(this.integrationsEndpoint + type.toLowerCase(), integration);
  }

  public updateIntegration(type:string, integration:IntegrationUpdate, integrationId:string):Observable<any> {
    return this.http.put(this.integrationsEndpoint + type.toLowerCase() + '/' + integrationId, integration);
  }

  public removeIntegrationFromAccount(integrationName:string, integrationId:string):Observable<any> {
    return this.http.delete(this.integrationsEndpoint + integrationName + '/' + integrationId);
  }

  public testIntegration(integrationId:string):Observable<any> {
    return this.http.post(this.integrationsEndpoint + integrationId + '/tests', {});
  }
}
