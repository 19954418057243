<!-- <h1 class="pageTitle">{{ title }}</h1> -->

<button (click)="openAddPendingUserModal()" mat-raised-button color="primary" style="margin-left: 15px; margin-top: 15px; margin-bottom: 15px;">ADD PENDING USER</button>
<button (click)="openValidatePendingUserModal()" mat-raised-button color="primary" style="margin-left: 15px; margin-top: 15px; margin-bottom: 15px;">VALIDATE PENDING USER</button>

<mat-tab-group (selectedTabChange)="onTabChange()">
  <mat-tab label="Verified Users">
    <div class="tableWrapper tableWrapper--hasDrawer">
      <mat-data-table
        id="verified-users-table"
        [_colsDef]="verifiedColsDef"
        [_mediator]="verifiedUsersTableMediator"
        [_searchConfig]="verifiedSearchConfig"
        [_onClickEvent]="onUserRowClick">
      </mat-data-table>
      <drawer
        [_targetEl]="'verified-users-table'"
        [_mediator]="verifiedUserDetailsMediator"
        [_uuidProp]="'user_id'">
      </drawer>
    </div>
  </mat-tab>
  <mat-tab label="Pending Users">
    <div class="tableWrapper tableWrapper--hasDrawer">
      <data-table
        id="pending-users-table"
        [_mediator]="pendingUsersTableMediator"
        [_onSelect]="onPendingUsersTableSelectRow"
        [_searchConfig]="['email']"
        [_searchPlaceholder]="'Search Users'">
      </data-table>
      <drawer
        [_targetEl]="'pending-users-table'"
        [_mediator]="pendingUserDetailsMediator"
        [_uuidProp]="'user_id'">
      </drawer>
    </div>
  </mat-tab>
<mat-tab-group></mat-tab-group>
