import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({name: 'timezonedDate'})
export class TimezonedDatePipe implements PipeTransform {
  transform(date:string):string {
    if(!date) return 'N/A';
    return moment(date).utcOffset(moment().utcOffset()).format('MM/DD/YYYY, H:mm:ss');
  }
}
