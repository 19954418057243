import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { APIAccount } from '../../../core/types/api/api-account-t';

import { Account } from '../../../core/stores/account';
import { User } from '../../../core/stores/user';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';


@Component({
  selector: 'add-pending-user-modal',
  templateUrl: 'add-pending-user-modal.html',
})
export class AddPendingUserModal {
  public accounts:APIAccount[];
  public emailFC:FormControl = new FormControl('', [Validators.required, Validators.email]);
  public selectedAccountId:string;

  public modalTitle:string = 'Add Pending User';

  constructor(
    public dialogRef: MatDialogRef<AddPendingUserModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private account:Account,
    private user:User,
    private feedbackMessagesService:FeedbackMessagesService
  ) {}

  ngOnInit():void {
    if(this.modalData['account_id']) {
      this.selectedAccountId = this.modalData['account_id'];
    } else {
      this.account.getAccounts().then((accounts:APIAccount[]) => {
        this.accounts = accounts;
      });
    }

    if(this.modalData && this.modalData['modalTitle']) {
      this.modalTitle = this.modalData['modalTitle'];
    }
  }

  public addAccountUser():void {
    if(!this.emailFC || !this.emailFC.value || !this.selectedAccountId) {
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something is missing',
        text: 'We could not create the user. Make sure that an account is selected and that the email field is correctyl filled.'
      });
      return;
    }

    this.user.createUser(
      {
        email: this.emailFC.value
      },
      this.selectedAccountId
    ).subscribe(() => {
      if(this.modalData && this.modalData['afterSuccessFn']) {
        this.modalData['afterSuccessFn']();
      }

      this.dialogRef.close();

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'User successfully created',
        text: 'The new user will appear in the pending users table in a few moments.'
      });
    }, (err:object) => {
      console.error('AddPendingUserModal | user.createUser failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not create the user.'
      });
    });
  }

  public cancel():void {
    this.dialogRef.close();
  }

  public getInputErrorMessage(formControl:any) {
    return formControl.hasError('required') ? 'You must enter a value' :
           formControl.hasError('email') ? 'Not a valid email' : '';
  }
}
