export const FilterConfigs = {
  priorityFilterItems: {
    high: {
      label: 'High',
      infoText: '',
      active: true,
      show: true
    },
    medium: {
      label: 'Medium',
      infoText: '',
      active: true,
      show: true
    },
    low: {
      label: 'Low',
      infoText: '',
      active: true,
      show: true
    }
  },
  threatTypeFilterItems: {
    sqli: {
      label: 'SQL Injection',
      infoText: '',
      active: true,
      show: true
    },
    rce: {
      label: 'Remote Command Execution',
      infoText: '',
      active: true,
      show: true
    },
    redirect: {
      label: 'Open Redirect',
      infoText: '',
      active: true,
      show: true
    },
    fileio: {
      label: 'Illegal File Access',
      infoText: '',
      active: true,
      show: true
    },
    dsx: {
      label: 'Malicious Payload',
      infoText: '',
      active: true,
      show: true
    },
    antivirus: {
      label: 'Malicious File Upload',
      infoText: '',
      active: true,
      show: true
    },
    ip_protection: {
      label: 'IP Protection',
      infoText: '',
      active: true,
      show: true
    },
    credential_stuffing: {
      label: 'Credential Stuffing',
      infoText: '',
      active: false,
      show: false // Will be feature flag checked in the component.
    }
  }
};
