import { Observable, Subject } from 'rxjs';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';

import { SortingConfig } from './sorting-config-t';
import { Injectable } from "@angular/core";

@Injectable()
export class SortingService implements DispatcherInterface {
  private sortSubject = new Subject<any>();
  // private defaultsLoadedSubject = new Subject<any>();
  private sortingDefault:SortingConfig = {
    prop: null,
    dir: null
  }
  private sortingConfig = {...this.sortingDefault};

  updateSubject = this.sortSubject;

  /* tslint:disable */
  private pairHash:string;
  /* tslint:enable */

  constructor() {}

  loadDefaults(sortConfig:SortingConfig) {
    this.sortingConfig = sortConfig;
    // this.defaultsLoadedSubject.next(sortConfig);
  }

  // onDefaultsLoaded():Observable<any> {
  //   return this.defaultsLoadedSubject.asObservable();
  // }

  getDefaults():Promise<any> {
    return new Promise((resolve, reject) => {
      // this.onDefaultsLoaded().subscribe(() => {
        resolve(this.getSorting());
      // }, err => {
      //   reject();
      // });
    });
  }

  setPairHash(pairHash:string):void {
    this.pairHash = pairHash;
  }

  getSorting():any {
    return this.sortingConfig;
  }

  update(payload:SortingConfig):void {
    this.sortingConfig = payload;
    this.sortSubject.next(this.sortingConfig);
  }

  onUpdate():Observable<any> {
    return this.sortSubject.asObservable();
  }

  formatForUrlParams(sortingConfig:SortingConfig):string {
    return sortingConfig.dir + ':' + sortingConfig.prop;
  }
}
