import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { APIPendingUser } from '../../../core/types/api/api-pending-user-t';

import { User } from '../../../core/stores/user';

import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';


@Component({
  selector: 'validate-pending-user-modal',
  templateUrl: 'validate-pending-user-modal.html',
  styleUrls: ['validate-pending-user-modal.scss']
})
export class ValidatePendingUserModal {
  public pendingUsers:APIPendingUser[];
  public firstNameFC:FormControl = new FormControl('', [Validators.required]);
  public lastNameFC:FormControl = new FormControl('', [Validators.required]);
  public passwordFC:FormControl = new FormControl('', [Validators.required]);
  public selectedPendingUser:object;
  public getPendingUsersError:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ValidatePendingUserModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private user:User,
    private feedbackMessagesService:FeedbackMessagesService
  ) {}

  ngOnInit():void {
    this.getPendingUsers();
  }

  public getPendingUsers():void {
    this.getPendingUsersError = false;

    this.user.getPendingUsers().subscribe((pendingUsers:APIPendingUser[]) => {
      this.pendingUsers = pendingUsers;
    }, (err:object) => {
      console.error('ValidatePendingUserModal | user.getPendingUsers failed',err);
      this.getPendingUsersError = true;
    });
  }

  public validatePendingUser():void {
    if(!this.selectedPendingUser || !this.selectedPendingUser['verification_token'] || !this.firstNameFC.value || !this.lastNameFC.value || !this.passwordFC.value) {
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something is missing',
        text: 'Please verify that all the fields are filled correctly.'
      });
      return;
    }

    this.user.validatePendingUser({
      verification_token: this.selectedPendingUser['verification_token'],
      first_name: this.firstNameFC.value,
      last_name: this.lastNameFC.value,
      password: this.passwordFC.value
    }).subscribe(() => {
      this.dialogRef.close();

      if(this.modalData && this.modalData['afterSuccessFn']) {
        this.modalData['afterSuccessFn']();
      }

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'User successfully validated',
        text: 'The user will appear in the validated users table in a few moments.'
      });
    }, (err:object) => {
      console.error('ValidatePendingUserModal | user.validatePendingUser failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not validate the user.'
      });
    });
  }

  public cancel():void {
    this.dialogRef.close();
  }

  public getInputErrorMessage(formControl:FormControl) {
    return formControl.hasError('required') ? 'You must enter a value' : '';
  }
}
