import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FeedbackMessage } from '@trend-core/feedback-message-t';

@Injectable()
export class FeedbackMessagesService {
  addMsgSubject = new Subject<any>();

  constructor(){}

  add(msgData:FeedbackMessage):void {
    this.addMsgSubject.next(msgData);
  }

  onMsgAdd():Observable<any> {
    return this.addMsgSubject.asObservable();
  }
}
