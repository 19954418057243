import { NgModule } from '@angular/core';

import { TimezonedDatePipe } from './date.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { EnabledDisabledPipe } from './enabled-disabled.pipe';
import { SourceIpPipe } from './source-ip.pipe';

@NgModule({
  imports: [],
  declarations: [
    TimezonedDatePipe,
    StripHtmlPipe,
    SanitizePipe,
    EnabledDisabledPipe,
    SourceIpPipe
  ],
  exports: [
    TimezonedDatePipe,
    StripHtmlPipe,
    SanitizePipe,
    EnabledDisabledPipe,
    SourceIpPipe
  ]
})
export class PipesModule {}
