import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

import { DashboardSubService } from './dashboard/dashboard-sub.service'
import { TimeFrame } from './timeframe/timeframe';
import { RealtimeRangeModal } from './timeframe/realtime-custom-range-modal/realtime-custom-range-modal';
import { ShareModule } from './share/share.module';
import { PipesModule } from './pipes/pipes.module';
import { ModalsModule } from './modals/modals.module';
import { CodeBlockModule } from './code-block/code-block.module';

import { CssVariablesService } from './themes/css-variables.service';

@NgModule({
  providers: [
    DashboardSubService,
    CssVariablesService
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule,
    MaterialModule,
    PipesModule,
    ModalsModule,
    CodeBlockModule
  ],
  declarations: [
    TimeFrame,
    RealtimeRangeModal
  ],
  entryComponents: [
    TimeFrame,
    RealtimeRangeModal
  ],
  exports: [
    TimeFrame,
    RealtimeRangeModal
  ]
})
export class TrendCommonModule {}
