import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

import { APIVerifiedUser } from '../../core/types/api/api-verified-user-t';
import { DispatcherConfig } from '@trend-core/dispatcher/dispatcher-config-t';
import { VerifiedUsersTableData } from '../types/components/verified-users-table-data-t';

import { Mediator } from '@trend-core/mediator/mediator';

import { StripHtmlPipe } from '@trend-common/pipes/strip-html.pipe';
import { TimezonedDatePipe } from '@trend-common/pipes/date.pipe';


@Injectable()
export class VerifiedUsersTableMediator extends Mediator {
  name = "VerifiedUsersTableMediator";
  endpoint = this.apiUrl.url() + 'accounts/@all/users';

  constructor(
    public injector:Injector,
    private apiUrl:APIUrlService
  ) {
    super(injector);
  }

  fetchData(componentInstance:any, subscriber:any, source:string, subParams?:object):void {
    // let paginationParams = '?page=' + subParams['pagination']['offset'] + 1 + '&per_page=' + subParams['pagination']['limit'];

    if(componentInstance) componentInstance['isLoading'] = true;

    let httpSub = this.http.get<APIVerifiedUser[]>(this.endpoint).subscribe(
      (data) => {
        if(componentInstance) componentInstance['isLoading'] = false;
        if(componentInstance) componentInstance['error'] = false;
        subscriber.next(data);
      },
      (error) => {
        if(componentInstance) componentInstance['error'] = error.message;
        if(componentInstance) componentInstance['isLoading'] = false;
        // subscriber.next(data);
        subscriber.next({});
      }
    );

    this.subs.push(httpSub);
  }

  getRequestSubject(component:any):Observable<any> {
    let dispatcherConfig:DispatcherConfig = {
      list : [
        'pagination'
      ],
      initial: 'pagination'
    };

    return super.getRequestSubject(component, this.name, dispatcherConfig);
  }

  adapt(data:APIVerifiedUser[]):VerifiedUsersTableData {
    for(var i=0; i<data.length; i++) {
      data[i]['first_name'] = new StripHtmlPipe().transform(data[i]['first_name']);
      data[i]['last_name'] = new StripHtmlPipe().transform(data[i]['last_name']);
      data[i]['created_on'] = new TimezonedDatePipe().transform(data[i]['created_on']);
    }

    return data;
  }

  // Completely reloads table data from fetch
  reloadTable():void {
    this.dispatcherMap.pagination['hardTrigger']();
  }
}
