import { Component, OnInit, OnDestroy  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { TelemetryService } from '@trend-core/trend/telemetry.service';
import { Account } from '../../core/stores/account';

import { BreadcrumbsService } from '../../common/breadcrumbs/breadcrumbs.service';

import Helpers from '@trend-common/helpers/helpers';

import { APIAgentInformation } from '@trend-core/api/api-agent-information-t';


@Component({
  selector: 'agents',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'agents.html',
  styleUrls: ['agents.scss']
})
export class AgentsPage implements OnInit, OnDestroy {

  public agentIdFC = new FormControl('', []);
  public agentInformation:APIAgentInformation;
  public isAgentInfoLoading:boolean = false;
  public getAgentSub:Subscription;
  public agentNotFound:boolean = false;
  public agentError:boolean = false;
  public object = Object;
  public currentAgentAccountTenant:string;

  constructor(
    private breadcrumbsService:BreadcrumbsService,
    private telemetryService:TelemetryService,
    private account:Account
  ) {}

  ngOnInit():void {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        text:'Agents',
        link: ''
      }
    ]);
  }

  ngOnDestroy():void {
    if(this.getAgentSub) {
      this.getAgentSub.unsubscribe();
      this.getAgentSub = null;
    }
  }

  public objectKeys(object:object) {
    return Object.keys(object);
  }

  public searchAgent():void {
    this.isAgentInfoLoading = true;
    this.getAgentSub = this.telemetryService.getAgentStatistics(this.agentIdFC.value).subscribe((agentInformation:APIAgentInformation) => {
      this.account.getAccount(agentInformation.group.account_id).subscribe(account => {
        this.currentAgentAccountTenant = account['tenant_name'];
      }, () => {
        this.currentAgentAccountTenant = '';
      });

      this.agentNotFound = false;
      this.agentError = false;
      this.agentInformation = agentInformation;
      this.isAgentInfoLoading = false;
    }, (err:object) => {
      this.isAgentInfoLoading = false;
      if(err['status'] === 404) {
        this.agentNotFound = true;
      } else {
        this.agentError = true;
      }
    });
  }

  public isObject(prop:any) {
    return typeof prop === 'object' ? true : false;
  }

  public formatTime(dateTime:string):string {
    return Helpers.formatTime(dateTime);
  }

  // public getInputErrorMessage(formControl:any) {
  //   return formControl.hasError('required') ? 'You must enter a value' :
  //          formControl.hasError('email') ? 'Not a valid email' : '';
  // }
}
