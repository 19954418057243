<div class="drawer-inner">
  <div class="drawer-close" (click)="close()">
    <div class="drawer-close-button" ><i class="fa fa-caret-right"></i></div>
  </div>
  
  <div *ngIf="isLoading && !error" class="drawerLoading">Loading data <i class="fa fa-spinner"></i></div>
  <div *ngIf="error && !isLoading" class="drawerError"><b><i class="fa fa-exclamation-triangle"></i> Error gathering details:</b> &nbsp;{{error}}</div>

  <div class="container" [ngClass]="{'loading': (isLoading && !error)}">
  <div injected-drawer></div>
  </div>
</div>
