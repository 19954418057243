import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { User } from '../../../core/stores/user';

import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';

@Component({
  selector: 'delete-verified-user-modal',
  templateUrl: 'delete-verified-user-modal.html'
})
export class DeleteVerifiedUserModal {
  public selectedVerifiedUserEmail:object;

  constructor(
    public dialogRef: MatDialogRef<DeleteVerifiedUserModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private user:User,
    private feedbackMessagesService:FeedbackMessagesService
  ) {}

  ngOnInit():void {
    if(this.modalData && this.modalData['verifiedUser']) this.selectedVerifiedUserEmail = this.modalData['verifiedUser']['email'];
  }

  public deleteVerifiedUser():void {
    this.user.deleteVerifiedUser(this.modalData['verifiedUser']).subscribe(() => {
      this.dialogRef.close();

      if(this.modalData && this.modalData['afterSuccessFn']) {
        this.modalData['afterSuccessFn']();
      }

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'User successfully deleted',
        text: 'The user has been deleted and wont be able to join the account.'
      });
    }, (err:object) => {
      console.error('DeleteVerifiedUserModal | user.deleteVerifiedUser failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not delete the user at the moment.'
      });
    });
  }

  public cancel():void {
    this.dialogRef.close();
  }
}
