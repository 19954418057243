import { NgModule } from '@angular/core';
import { Threats } from './trend/threats';
import { Groups } from './trend/groups';
import { User } from './trend/user';
import { Events } from './trend/events';
import { Policies } from './trend/policies';
import { WindowService } from './trend/window';
import { AdapterService } from './adapter/adapter.service';
import { DomService } from './dom.service';
import { Algos } from './trend/algos';
import { Feeds } from './trend/feeds';
import { APIKeyService } from './trend/api-key.service';
import { Integrations } from './trend/integrations';
import { TelemetryService } from './trend/telemetry.service';
import { AgentService } from './trend/agent.service';
import { PostureService } from './trend/posture.service';
import { CloudProviderAccountsService } from './trend/cloud-provider-accounts.service';
import { APIUrlService } from './api/api-url.service';
import { BillingService } from './trend/billing';
import { ImpersonateService } from './trend/impersonate.service';

@NgModule({
  providers: [
    APIUrlService,
    WindowService,
    AdapterService,
    BillingService,
    Threats,
    Groups,
    User,
    Events,
    Policies,
    DomService,
    Algos,
    Feeds,
    APIKeyService,
    Integrations,
    TelemetryService,
    AgentService,
    PostureService,
    CloudProviderAccountsService,
    ImpersonateService
  ],
  imports: [

  ]
})
export class TrendCoreModule {}
