import { Component, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TelemetryService } from '@trend-core/trend/telemetry.service';
import { ModalService } from '@trend-common/modals/modal.service';
import { TelemetryChartDrilldownModal } from '../../../../common/modals/telemetry-chart-drilldown-modal/telemetry-chart-drilldown-modal';

import * as moment from 'moment';


@Component({
  selector: 'group-telemetry',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'group-telemetry.html',
  styleUrls: ['group-telemetry.scss']
})
export class GroupTelemetry {
  public messagesCountChartData:object;
  public exceptionsCountChartData:object;
  public reportedTransactionsCountChartData:object;
  public totalTransactionsCountChartData:object;
  public profiledTransactionsChartData:object;

  public samplesFiftyChartData:object;
  public averageFiftyChartData:object;
  public agentTimePercentFiftyChartData:object;
  public averageAgentTimePercentFiftyChartData:object;

  public samplesSeventyFiveChartData:object;
  public averageSeventyFiveChartData:object;
  public agentTimePercentSeventyFiveChartData:object;
  public averageAgentTimePercentSeventyFiveChartData:object;

  public samplesNinetyChartData:object;
  public averageNinetyChartData:object;
  public agentTimePercentNinetyChartData:object;
  public averageAgentTimePercentNinetyChartData:object;

  public samplesNinetyNineChartData:object;
  public averageNinetyNineChartData:object;
  public agentTimePercentNinetyNineChartData:object;
  public averageAgentTimePercentNinetyNineChartData:object;

  public chartsReady:boolean = false;
  public telemetryDataError:boolean = false;

  public activeTimeframe:string = '7d';
  public barChartxAxisTickFormatting:any;
  public rotateXAxisTicks:boolean;

  public showDrilldownFunc:Function = this.modalService.openModal.bind(this.modalService);
  public timeframeObj:object;
  public chartExtraData:object = {};
  public xaxisConfig = {
    type: 'datetime',
    labels: {
      trim: true
    },
    tooltip: {
      enabled: false
    }
  }

  @ViewChild('agentPercentInstruction') agentPercentInstruction : TemplateRef<any>;

  constructor(
    private telemetryService:TelemetryService,
    private route:ActivatedRoute,
    private modalService:ModalService
  ) {}

  ngOnInit():void {
    this.chartExtraData['groupId'] = this.route.snapshot.parent.params.group_id;
    this.loadStats();
  }

  private loadStats():void {
    this.chartsReady = false;
    this.telemetryDataError = false;

    this.timeframeObj = {};

    if(this.activeTimeframe === '24h') {
      this.barChartxAxisTickFormatting = this.hoursTickFormatting.bind(this);
      this.rotateXAxisTicks = true;
      this.timeframeObj['from'] = moment().utc().subtract(24, 'hours').startOf('hour').format();
      this.timeframeObj['to'] = moment().utc().utc().format();
    } else if(this.activeTimeframe === '7d') {
      this.barChartxAxisTickFormatting = this.daysTickFormatting
      this.rotateXAxisTicks = false;
      this.timeframeObj['from'] = moment().utc().subtract(7, 'days').startOf('day').format();
      this.timeframeObj['to'] = moment().utc().utc().format();
    } else if(this.activeTimeframe === '14d') {
      this.barChartxAxisTickFormatting = this.daysTickFormatting.bind(this);
      this.rotateXAxisTicks = false;
      this.timeframeObj['from'] = moment().utc().subtract(14, 'days').startOf('day').format();
      this.timeframeObj['to'] = moment().utc().utc().format();
    }

    this.telemetryService.getGroupStatistics(this.route.snapshot.parent.params.group_id, this.timeframeObj).subscribe(statistics => {
      this.exceptionsCountChartData = this.telemetryService.getCountChartData(statistics, ['exceptions']);
      this.messagesCountChartData = this.telemetryService.getCountChartData(statistics, ['dropped_messages', 'rejected_messages']);
      this.reportedTransactionsCountChartData = this.telemetryService.getCountChartData(statistics, ['reported_transactions']);
      this.totalTransactionsCountChartData = this.telemetryService.getLineChartData(statistics, ['total_transactions', 'reported_transactions']);
      this.profiledTransactionsChartData = this.telemetryService.getCountChartData(statistics, ['sampled_total_ns'], 'count');

      this.samplesFiftyChartData = this.telemetryService.getLineChartData(statistics, ['sampled_total_ns', 'sampled_agent_time_ns'], 'p50th', this.nsToMs, this.route.snapshot.parent.params.group_id);
      this.averageFiftyChartData = this.telemetryService.getLineChartData(statistics, ['average_total_ns', 'average_agent_time_ns'], 'p50th', this.nsToMs);
      this.agentTimePercentFiftyChartData = this.telemetryService.getCountChartData(statistics, ['sampled_agent_time_percent'], 'p50th');
      this.averageAgentTimePercentFiftyChartData = this.telemetryService.getCountChartData(statistics, ['average_agent_time_percent'], 'p50th');

      this.samplesSeventyFiveChartData = this.telemetryService.getLineChartData(statistics, ['sampled_total_ns', 'sampled_agent_time_ns'], 'p75th', this.nsToMs, this.route.snapshot.parent.params.group_id);
      this.averageSeventyFiveChartData = this.telemetryService.getLineChartData(statistics, ['average_total_ns', 'average_agent_time_ns'], 'p75th', this.nsToMs);
      this.agentTimePercentSeventyFiveChartData = this.telemetryService.getCountChartData(statistics, ['sampled_agent_time_percent'], 'p75th');
      this.averageAgentTimePercentSeventyFiveChartData = this.telemetryService.getCountChartData(statistics, ['average_agent_time_percent'], 'p75th');

      this.samplesNinetyChartData = this.telemetryService.getLineChartData(statistics, ['sampled_total_ns', 'sampled_agent_time_ns'], 'p90th', this.nsToMs, this.route.snapshot.parent.params.group_id);
      this.averageNinetyChartData = this.telemetryService.getLineChartData(statistics, ['average_total_ns', 'average_agent_time_ns'], 'p90th', this.nsToMs);
      this.agentTimePercentNinetyChartData = this.telemetryService.getCountChartData(statistics, ['sampled_agent_time_percent'], 'p90th');
      this.averageAgentTimePercentNinetyChartData = this.telemetryService.getCountChartData(statistics, ['average_agent_time_percent'], 'p90th');

      this.samplesNinetyNineChartData = this.telemetryService.getLineChartData(statistics, ['sampled_total_ns', 'sampled_agent_time_ns'], 'p99th', this.nsToMs, this.route.snapshot.parent.params.group_id);
      this.averageNinetyNineChartData = this.telemetryService.getLineChartData(statistics, ['average_total_ns', 'average_agent_time_ns'], 'p99th', this.nsToMs);
      this.agentTimePercentNinetyNineChartData = this.telemetryService.getCountChartData(statistics, ['sampled_agent_time_percent'], 'p99th');
      this.averageAgentTimePercentNinetyNineChartData = this.telemetryService.getCountChartData(statistics, ['average_agent_time_percent'], 'p99th');

      this.chartsReady = true;
      this.telemetryDataError = false;
    }, (err:object) => {
      console.error('GroupTelemetry | telemetryService.getGroupStatistics failed', err);
      this.telemetryDataError = true;
    });
  }

  public setTimeframe(timeframe:string):void {
    this.activeTimeframe = timeframe;

    this.loadStats();
  }

  private hoursTickFormatting(xVal:string) {
    return xVal;
  }

  private daysTickFormatting(xVal:string) {
    if(xVal.indexOf('00:00.') === -1) {
      // ngx formatted date
      if(xVal.indexOf('00:00') === -1) {
        return '';
      } else {
        return xVal;
      }
    } else {
      // full Date
      if(xVal.indexOf('00:00:00') > -1) {
        return moment(xVal).format('MMM dd DD HH:mm');
      } else {
        return '';
      }
    }
  }

  public hooksXAxisTickFormatting(xVal:string):string {
    return xVal;
  }

  // Will be called in table context.
  public onChartSeriesSelect(event:object):void {
    let drillDowntimeframe = {
      from: moment(event['extra'].timestamp).utc().format(),
      to: moment(event['extra'].timestamp).add(1, 'hour').utc().format()
    }

    this['drilldownCall'](TelemetryChartDrilldownModal, {
      data: {
        groupId: event['extra'].groupId,
        timeframe: drillDowntimeframe,
        chartHeader: this['chartHeader']
      },
      panelClass: 'wide'
    });
  }

  public onBarChartSeriesSelect(date):void {
    let drillDowntimeframe = {
      from: moment(date).utc().format(),
      to: moment(date).utc().add(1, 'hour').format()
    }

    this['_drilldownFn'](TelemetryChartDrilldownModal, {
      data: {
        groupId: this['_chartContextExtra'].groupId,
        timeframe: drillDowntimeframe,
        chartHeader: this['_chartTitle']
      },
      panelClass: 'wide'
    });
  }

  public nsToMs(nsValue:number) {
    return parseFloat((nsValue * 0.000001).toFixed(2));
  }
}
