import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, empty } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { APIUserAuth } from '@trend-core/api/api-user-auth-t';
import { APIUserLogin } from '@trend-core/api/api-user-login-t';
import { APIUrlService } from '@trend-core/api/api-url.service';
import { WindowService } from '@trend-core/trend/window';

@Injectable()
export class AuthService {
  private lsPrefix:string = "_tmco";
  private lsTokenName:string = this.lsPrefix + "tok";
  private lsDeepLinkName:string = this.lsPrefix + "dl";
  private cloudOneShellStateName:string = "C1C_Shell_State";
  // private enrolled:boolean = undefined;

  get report():string {
    return this._report;
  }
  private _report:string;

  get panel():string {
    return this._panel;
  }
  private _panel:string;

  get event():object {
    return this._event;
  }
  private _event:object;

  constructor(public router: Router,
              private http:HttpClient,
              private api:APIUrlService,
              private windowService:WindowService) {}

  public getToken():any {
    if(this.windowService.getWindow()['INTERNAL_SHELL']){
      return localStorage.getItem(this.lsTokenName) ? atob(localStorage.getItem(this.lsTokenName)) : false;
    } else {
      try{
        const c1Token = JSON.parse(sessionStorage.getItem(this.cloudOneShellStateName)).c1Token.token;
        return (c1Token && c1Token !== "") ? c1Token : false;
      } catch(e){
        console.error(e);
        this.windowService.getWindow().location.href = "home";
        return false;
      }
    }
  }

  public setToken(token:string):boolean {
    localStorage.setItem(this.lsTokenName, btoa(token));
    return localStorage.getItem(this.lsTokenName) === btoa(token);
  }

  public removeToken():void {
    localStorage.removeItem(this.lsTokenName);
    this.api.removeStoredAPIEndpoint();
  }

  // public getAccountInfo():Observable<any> {
  //   return this.http.get(this.api.url() + 'accounts/enroll');
  // }

  public getADFSLoginUrl():string {
    return this.api.url() + 'accounts/auth/saml/consume';
  }

  public logIn(credentials:APIUserLogin):Observable<any> {
    return this.http.post<APIUserAuth>(this.api.url() + 'accounts/auth/login', credentials);
  }

  public logOut():Observable<any> {
    return this.http.post(this.api.url() + 'accounts/auth/logout', {});
  }

  public canActivate():any {
    if(!this.windowService.getWindow()['INTERNAL_SHELL']) {
      return true;
    } else if(!this.isAuthenticated()){
      if(this.windowService.getWindow()['INTERNAL_SHELL']){
        if(this.windowService.getWindow()['IS_KONTROLLER']) {
          this.router.navigate(['login']);
        } else {
          this.windowService.getWindow().location.href = "external";
        }

        return false;
      } else {
        this.windowService.getWindow().location.href = "home";
        return false;
      }
    } else {
      return true;
    }

    // if(!this.windowService.getWindow()['INTERNAL_SHELL'] && this.enrolled === undefined){
    //   return this.getAccountInfo().pipe(
    //     map(e => {
    //       if (e) {
    //         this.enrolled = false;
    //         this.windowService.getWindow().location.href = "home";
    //         return false;
    //       } else {
    //         this.enrolled = true;
    //         return true;
    //       }
    //     }),
    //     catchError(() => {
    //       this.enrolled = false;
    //       this.router.navigate(['critical-error']);
    //       return empty();
    //     })
    //   )
    // } else {
      // return true;
    // }
  }

  public handleDeepLinkParams(fromDashboard:boolean):void {
    const href:string = this.windowService.getWindow().location.href;
    let deepLinked:boolean = false;

    if(href.indexOf('?report=') > -1) {
      this._report = href.split('?report=')[1];
      deepLinked = true;
    }

    if(href.indexOf('?panel=') > -1) {
      this._panel = href.split('?panel=')[1];
      deepLinked = true;
    }

    if(href.indexOf('?event_id=') > -1) {
      this._event = {
        event_id: href.split("event_id=")[1].split('&')[0],
        event_type: href.split("event_type=")[1]
      };
      deepLinked = true;
    }
  }

  public getDeepLinkParams():object {
    return JSON.parse(atob(localStorage.getItem(this.lsDeepLinkName)));
  }

  public removeUnauthedContent():void {
    this._report = undefined;
    this._panel = undefined;
    this._event = undefined;
  }

  public isAuthenticated():any {
    return this.getToken();
  }

  public refreshToken():Observable<any> {
    return this.http.post(this.api.url() + 'accounts/auth/token', {});
  }

  public getPasswordPatternInfo():object {
    return {
      pattern: '^(?=.*[A-Z])(?=.*[a-z]).{8,72}$',
      hint: 'Password should be between 8 and 72 characters with at least one uppercase and lowercase letter'
    }
  }

  public requestImpersonationToken(account_access:object):Observable<any> {
    return this.http.post(this.api.url() + 'accounts/auth/impersonate', account_access);
  }
}
