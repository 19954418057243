import { Component, OnInit, OnDestroy, HostListener, HostBinding, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimeframeService } from '../timeframe/timeframe.service';

import { ShareModal } from './share-modal';
import { ShareService } from './share.service';

@Component({
  selector: 'share',
  templateUrl: 'share.html',
  styleUrls: ['share.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class Share implements OnInit, OnDestroy {

  shareModal:MatDialogRef<ShareModal>;

  /* tslint:disable */
  /* tslint:enable */

  constructor(private modal:MatDialog,
              private shareService:ShareService,
              private timeframeService:TimeframeService) {}

  @HostListener('click', ['$event']) onClick(e):void {
    this.shareModal = this.modal.open(ShareModal, { panelClass: 'share-modal-wrapper' });

    this.shareService.setModal(this.shareModal);
  }

  @HostBinding('class.visible') visible:boolean = true;
  @HostBinding('attr.data-telemetry-id') telemetryAttr = 'events-page_shareButton';

  ngOnInit():void {
    this.timeframeService.onHide().subscribe(()=>{
      this.hide();
    });
    this.timeframeService.onShow().subscribe(()=>{
      this.show();
    });
  }

  ngOnDestroy():void {}

  hide():void {
    this.visible = false;
  }

  show():void {
    this.visible = true;
  }

}
