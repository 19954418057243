import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { timer, Observable, Subscription } from 'rxjs';

import { DispatcherParamsStoreService } from '@trend-core/dispatcher/dispatcher-params-store.service';
import { ShareService } from './share.service';

@Component({
  selector: 'share-modal',
  templateUrl: 'share-modal.html',
  styleUrls: ['share-modal.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ShareModal implements OnInit {
  parentModal:MatDialogRef<any>;
  copyTimer:Observable<number> = timer(1500);
  copySub:Subscription;
  public isCopied:boolean = false;

  /* tslint:disable */
  url:string = "";
  /* tslint:enable */

  @ViewChild('copybutton', { read: ElementRef }) private _copyButton: ElementRef;
  @ViewChild('urlinput') private _urlInput: ElementRef;

  constructor(private shareService:ShareService,
              private dispatcherParamsStoreService:DispatcherParamsStoreService) {}

  ngOnInit():void {
    this.parentModal = this.shareService.getModal();

    this.shareService.buildUrl(this.dispatcherParamsStoreService.params).then((str:string) => {
      this.url = location.href.split('?report=')[0] + "?report=" + str;
    }).catch((e:object) => {
      console.error('There was an issue generating the data needed for this url.');
      console.error(e);
    });
  }

  //the copy to clipboard behaviour
  public copyLink():void {
    let b = <HTMLInputElement>this._copyButton.nativeElement;
    b.classList.add('clicked');

    let i = <HTMLInputElement>this._urlInput.nativeElement;
    i.select();
    document.execCommand('copy');
    this.isCopied = true;

    this.copySub = this.copyTimer.subscribe(()=>{
      this.isCopied = false;
      this.parentModal.close();
    });
  }

  close():void {
    this.parentModal.close();
  }
}
