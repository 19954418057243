<h1 mat-dialog-title>
  {{ modalTitleChartType }} plugins for {{ modalTitleChartTimeframe }} (hour)
  <i (click)="cancel()" class="material-icons">close</i>
</h1>

<!-- <div mat-dialog-content style="max-width: 100%; overflow-y: auto"> -->
<div mat-dialog-content>
  <div class="chartWrapper" [ngStyle]="{'width.px': chartWidth}">
    <div id="chart"></div>
  </div>

  <div *ngIf="!chartIsReady" class="modalLoading">
    <span *ngIf="!chartError">Loading...</span>
    <span *ngIf="chartError">Something went wrong, we are unable to get the plugins and hooks at this moment.</span>
  </div>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Back</button>
</div>
