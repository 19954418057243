import { Component, ViewEncapsulation } from '@angular/core';
import { Observable, timer } from 'rxjs'

import { FeedbackMessagesService } from './feedback-messages.service';

import { FeedbackMessage } from '@trend-core/feedback-message-t';

@Component({
  selector: 'feedback-messages',
  templateUrl: './feedback-messages.html',
  styleUrls: ['feedback-messages.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class FeedbackMessages {
  messages:object = {};
  objectKeys = Object.keys;
  showMsgClassTimer:Observable<any> = timer(300);
  removeMsgTimer:Observable<any> = timer(300);
  removeOnTtlMsgTimer:Observable<any> = timer(300);

  constructor(private feedbackMessagesService:FeedbackMessagesService){}

  ngOnInit():void {
    this.feedbackMessagesService.onMsgAdd().subscribe(msgData => this.addMessageToQueue(msgData));
  }

  ngOnDestroy():void {}

  addMessageToQueue(msgData:FeedbackMessage):void {
    if(!msgData.type || !msgData.text) {
      console.warn('Couldn\'t add message, minimal config not met. Make sure the msg object has at least a type and text property.');
      return;
    }

    let msgId = 'msg_' + Math.floor(100000 + Math.random() * 900000);

    msgData.animClass = 'entering';

    let self = this;

    this.showMsgClassTimer.subscribe(()=>{
      this.removeAnimClass(msgData);
    });

    if(msgData.type === 'success' || msgData.sticky) {
      if(!msgData.ttl) {
        msgData.ttl = msgData.link ? 15000 : 3000;
      }
    }

    if(msgData.ttl) {
      this.removeOnTtlMsgTimer = timer(msgData.ttl);
      this.removeOnTtlMsgTimer.subscribe(() => {
        this.dismissMsg(msgId);
      });
    }

    this.messages[msgId] = msgData;
  }

  removeAnimClass(msgData:FeedbackMessage):void {
    msgData.animClass = '';
  }

  removeMsgFromQueue(msgId:string):void {
    delete this.messages[msgId];
  }

  dismissMsg(msgId):void {
    if(!this.messages[msgId]) return;

    this.messages[msgId].animClass = 'exiting';

    this.removeMsgTimer.subscribe(() => {
      this.removeMsgFromQueue(msgId);
    });
  }
}
