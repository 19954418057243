import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DispatcherParamsStore } from '@trend-core/dispatcher/dispatcher-params-store-t';
import { DispatcherParamsStoreService } from '@trend-core/dispatcher/dispatcher-params-store.service';

@Injectable()
export class ShareService {

  /* tslint:disable */
  /* tslint:enable */
  parentModal:MatDialogRef<any>;

  constructor(private dispatcherParamsStoreService:DispatcherParamsStoreService) {}

  setModal(modal:MatDialogRef<any>):void {
    this.parentModal = modal;
  }

  getModal():MatDialogRef<any> {
    return this.parentModal;
  }

  //builds the url
  //converts params json object to string and base64a teh string,
  //attaches to report= query param
  buildUrl(params?:DispatcherParamsStore) {
    return this.dispatcherParamsStoreService.jsonToURL(params || this.dispatcherParamsStoreService.params);
  }
}
