<h1 mat-dialog-title>Validate Pending User</h1>

<div mat-dialog-content>
  <div *ngIf="!pendingUsers" class="pendingUsersOverlay">
    <span *ngIf="!getPendingUsersError">Loading pending users...</span>
    <span *ngIf="getPendingUsersError">We could not get the pending users at the moment. <span class="retryGetPendingUsers" (click)="getPendingUsers()">Retry</span>.</span>
  </div>

  <mat-form-field *ngIf="pendingUsers" appearance="outline">
    <mat-select id="pending_users" [(ngModel)]="selectedPendingUser" placeholder="User" required>
      <mat-option *ngFor="let pendingUser of pendingUsers" [value]="pendingUser">
        {{ pendingUser.email }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>User First Name</mat-label>
    <input id="pending_user_first_name" [formControl]="firstNameFC" matInput required>
    <mat-error *ngIf="firstNameFC.invalid">{{ getInputErrorMessage(firstNameFC) }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>User Last Name</mat-label>
    <input id="pending_user_last_name" [formControl]="lastNameFC" matInput required>
    <mat-error *ngIf="lastNameFC.invalid">{{ getInputErrorMessage(lastNameFC) }}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>User Password</mat-label>
    <input id="pending_user_password" [formControl]="passwordFC" matInput required>
    <mat-error *ngIf="passwordFC.invalid">{{ getInputErrorMessage(passwordFC) }}</mat-error>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Cancel</button>
  <button (click)="validatePendingUser()" mat-flat-button color="primary" matDialogClose [disabled]="firstNameFC.status == 'INVALID' || lastNameFC.status == 'INVALID' || passwordFC.status == 'INVALID'">Create</button>
</div>
