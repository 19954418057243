import { Component } from '@angular/core';

@Component({
  selector: 'application-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: { class: '_appsec_ns' }
})
export class AppComponent {
  constructor() {}

  ngOnInit() {
    this.loadCssVariables();
  }

  private loadCssVariables():void {
    const VARIABLES = new Map([
      // Colors
      ['brand_primary_color', '#DA3832'],
      ['brand_secondary_color', '#050708'],
      ['color_secondary', '#578aef'],
      ['menubg_color', '#2c2c30'],
      ['menubg_active_color', '#161618'],
      ['off_white_color', '#F0F0F0'],
      ['off_white_bg_color', '#f7f7f7'],
      ['off_white_border_color', '#EDEDED'],
      ['color_secondary_background', '#F0F0F0'],
      ['info_bg_color', '#4f95dc'],
      ['success_color', '#00a178'],
      ['warning_color', '#eeb81c'],
      ['danger_bg_color', '#f7f7f7'],
      ['danger_color', '#db2d1c'],
      ['base_text_color', '#3d3d3d'],
      ['light_text_color', '#AFB7C8'],
      ['dark_text_color', '#222222'],
      // Layout
      ['layout_base_padding', '15px'],
      ['layout_header_logo_width', '130px'],
      ['layout_menu_width', '160px']
    ]);

    Array.from(VARIABLES.entries()).forEach(([name, value]) => {
      document.body.style.setProperty(`--${name}`, value);
    });
  }
}
