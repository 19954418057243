import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

import { APIUrlService } from '@trend-core/api/api-url.service';
import { PagesParamsStore } from '../../pages/pages-params.store';

import { APIGroup } from '../../core/types/api/api-group-t';
import { DispatcherConfig } from '@trend-core/dispatcher/dispatcher-config-t';
import { AccountGroupsTableData } from '../types/components/account-groups-table-data-t';

import { Mediator } from '@trend-core/mediator/mediator';

import { StripHtmlPipe } from '@trend-common/pipes/strip-html.pipe';

class DateAndTimePipe extends DatePipe {
  public transform(value): any {
    return super.transform(value, 'medium');
  }
}

@Injectable()
export class AccountGroupsTableMediator extends Mediator {
  name = "AccountGroupsTableMediator";
  endpoint;

  // Caching logic members.
  accountGroupsTableData:AccountGroupsTableData = {
    cols: [
      { name: 'Name', prop: 'name', cellClass: 'col-name', headerClass: 'col-name', pipe: new StripHtmlPipe() },
      { name: 'Group Id', prop: 'group_id', cellClass: 'col-group_id', headerClass: 'col-group_id' },
      { name: 'Activated On', prop: 'activated_on', cellClass: 'col-activated_on', headerClass: 'col-activated_on', pipe: new DateAndTimePipe('en-US') }
    ],
    rows: []
  };

  constructor(
    public injector:Injector,
    private apiUrl:APIUrlService,
    private pagesParamsStore:PagesParamsStore
  ) {
    super(injector);
  }

  fetchData(componentInstance:any, subscriber:any, source:string, subParams?:object):void {
    this.endpoint = this.apiUrl.url() + 'accounts/' + this.pagesParamsStore.getParams().account_id + '/groups';

    if(componentInstance) componentInstance['isLoading'] = true;

    let httpSub = this.http.get<APIGroup[]>(this.endpoint).subscribe(
      (data) => {
        subscriber.next(data);
      },
      (error) => {
        if(componentInstance) componentInstance['error'] = error.message;
        if(componentInstance) componentInstance['isLoading'] = false;
        // subscriber.next(data);
        subscriber.next({});
      }
    );

    this.subs.push(httpSub);
  }

  getRequestSubject(component:any):Observable<any> {
    let dispatcherConfig:DispatcherConfig = {
      list : [
        'pagination'
      ],
      initial: 'pagination'
    };

    return super.getRequestSubject(component, this.name, dispatcherConfig);
  }

  adapt(data:APIGroup[]):AccountGroupsTableData {
    let rows:any[] = [];

    for(var i=0; i < data.length; i++) {
      rows.push(data[i]);
    }

    this.accountGroupsTableData['rows'] = rows;

    return this.accountGroupsTableData;
  }

  // Completely reloads table data from fetch
  reloadTable():void {
    this.dispatcherMap.pagination['hardTrigger']();
  }
}
