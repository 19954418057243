import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { Platform } from '@angular/cdk/platform';
import { ErrorStateMatcher } from '@angular/material/core';

import { External } from './external';


@NgModule({
  imports: [
    RouterModule
  ],
  declarations: [
    External
  ],
  providers: [
    Platform,
    ErrorStateMatcher
  ],
  entryComponents: [
    External
  ],
  exports: [
    External
  ]
})
export class ExternalModule {}
