import { Component, Input, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { MediatorInterface } from '@trend-core/mediator/mediator-i';
import { SubscriberComponent } from '@trend-core/subscriber-component-i';

import Helpers from '@trend-common/helpers/helpers';

import { MatDataTableCols } from './mat-data-table-colsDef-t';
import { MatDataTableSearchConfig } from './mat-data-table-searchConfig-t';

import { MatDataTableService } from './mat-data-table.service';
import { SortingConfig } from '@trend-common/sorting/sorting-config-t';
import { DrawerService } from '@trend-common/drawer/drawer.service';

@Component({
  selector: 'mat-data-table',
  templateUrl: './mat-data-table.html',
  styleUrls: ['./mat-data-table.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MatDataTable implements SubscriberComponent, OnInit, OnDestroy {
  @Input() _mediator:MediatorInterface;
  @Input() _colsDef:MatDataTableCols;
  @Input() _data:object[];
  @Input() _tableClass:string;
  @Input() _mediatorEndpointParams:object;
  // @Input() _isTableDataReadySubject:Subject<boolean>;
  @Input() _tableDataSubject:Subject<object[]>;
  @Input() _searchConfig:MatDataTableSearchConfig;
  @Input() _onClickEvent:Function;
  @Input() _noPagination:boolean;
  @Input() _useBackendPagination:boolean;
  @Input() _emptyTableMessage:string;

  @ViewChild('paginator') paginator: MatPaginator;

  // Table states
  // private _isTableDataReadySub:Subscription;
  private _firstPageRequestSub:Subscription;
  public isTableDataReady:boolean;
  public tableErrorMsg:string;
  public tableError:boolean = false;

  // Table interactions
  public selectedRowIndex:number = -1;
  public onClickEvent:Function;
  public rowClickEnabled:boolean;

  // Search
  public searchLabel:string;
  public searchInfo:string;
  public searchWidth:string;
  public tableSearchValue:string;
  private _filterDebounce:Subscription;

  // Display
  public tableClass:string;
  public emptyTableMessage:string;

  // Data
  public cols:string[] = [];
  public colsDef:object[];
  public displayedResults:any;
  private _originalDataSet:object[];
  private _subscription:any;
  private d$ = new Subject<any>();

  // MatPaginator
  public noPagination:boolean;
  public length:number;
  public pageIndex:number = 0;
  public pageSize:number = 10;
  public pageSizeOptions:number[] = [5, 10, 25];
  public pageEvent: PageEvent;
  public useBackendPagination:boolean;

  // Mediator config
  public mediatorEndpointParams:object;


  constructor(
    private matDataTableService:MatDataTableService,
    private _drawerService:DrawerService
  ) {}

  ngOnInit():void {
    // this._isTableDataReadySub = this._isTableDataReadySubject.subscribe((isTableDataReady:boolean) => {
    //   this.isTableDataReady = isTableDataReady;
    // });
    if(this._mediatorEndpointParams) this.mediatorEndpointParams = this._mediatorEndpointParams;

    this._drawerService.onClose().subscribe(() => {
      this.selectedRowIndex = -1;
    });

    this._firstPageRequestSub = this.matDataTableService.onFirstPageRequest().subscribe(() => {
      this.paginator.firstPage();
    });

    this.tableClass = this._tableClass;
    this.emptyTableMessage = this._emptyTableMessage;

    this.colsDef = this._colsDef;
    this._buildCols();

    if(this._mediator) {
      this.subscribe();
    } else if(this._tableDataSubject) {
      this._tableDataSubject.subscribe(data => {
        // this._originalDataSet = [...data];
        this._loadTableData([...data]);
      }, (error:string) => {
        this.tableErrorMsg = error;
        this.tableError = true;
      });
    } else {
      // this._originalDataSet = [...this._data];
      this._loadTableData([...this._data]);
    }

    if(this._searchConfig) {
      this.searchLabel = this._searchConfig['label'];
      this.searchInfo = this._searchConfig['info'];
      this.searchWidth = this._searchConfig['width'];
    }

    this.noPagination = this._noPagination;
    this.useBackendPagination = this._useBackendPagination;

    // this.onClickEvent = this._onClickEvent === undefined ? this._defaultOnClickEvent : this._onClickEvent;
    this.onClickEvent = this._defaultOnClickEvent;
    this.rowClickEnabled = this._onClickEvent !== undefined;
  }

  ngOnDestroy():void {
    // if(this._isTableDataReadySub) {
    //   this._isTableDataReadySub.unsubscribe();
    //   this._isTableDataReadySub = null;
    // }

    if(this._mediator) this._mediator.kill();

    this.d$.next();
    this.d$.complete();

    if(this._tableDataSubject) {
      this._tableDataSubject.unsubscribe();
      this._tableDataSubject = null;
    }
  }

  private _buildCols():void {
    for(var i=0;i<this._colsDef.length; i++) {
      this.cols.push(this._colsDef[i]['colId']);
    }
  }

  private _loadTableData(data?:any):void {
    let dataSet:object[];

    if(data) this._originalDataSet = [...data];

    this.displayedResults = null;

    if(this.tableSearchValue) {
      dataSet = this._filterTable();
    } else {
      dataSet = [...this._originalDataSet];
    }

    this.length = dataSet.length;
    this.displayedResults = dataSet.splice((this.pageIndex * this.pageSize), this.pageSize);
    this.isTableDataReady = true;
    this.matDataTableService.triggerTableReady(this.length);
  }

  private _filterTable():object[] {
    this.paginator.firstPage();
    let filteredResults:object[] = [];

    for(var i=0; i<this._originalDataSet.length; i++) {
      for(var k=0; k<this._searchConfig.keys.length; k++) {
        if(Helpers.resolveObjPath(this._searchConfig.keys[k], this._originalDataSet[i]).toLowerCase().indexOf(this.tableSearchValue.toLowerCase()) > -1) {
          filteredResults.push(this._originalDataSet[i]);
          break;
        }
      }
    }

    return filteredResults;
  }

  public onTableFiltering():void {
    if(this._filterDebounce) this._filterDebounce.unsubscribe();

    this._filterDebounce = timer(600).subscribe(() => {
      this._loadTableData();
    });
  }

  public onPaginatorUpdate(event:PageEvent):any {
    if(this._useBackendPagination) {
      this._mediator['onPaginationUpdate']({
        count: event.pageSize,
        page: event.pageIndex,
        limit: event.pageSize,
        perPage: event.pageSize,
        first: null,
        prev: null,
        next: null,
        requestType: null
      });
    } else {
      this.pageSize = event['pageSize'];
      this.pageIndex = event['pageIndex'];
      this._loadTableData();
    }
  }

  public update(data:object):void {
    this._loadTableData(this._mediator.adapt(data));
  }

  private _defaultOnClickEvent(row:object, index:number):void {
    if(this.rowClickEnabled) this.selectedRowIndex = index;

    if(this._onClickEvent) this._onClickEvent(row);
  }

  public sortData(colId:string, colSortConfig:object):void {
    if(!colSortConfig || !colSortConfig['isSortable']) return;

    let sortConfig:SortingConfig = {
      prop: colId,
      dir: 'asc'
    };

    this._mediator['sortTableData'](sortConfig);
  }

  subscribe():void {
    this._subscription = this._mediator.getRequestSubject(this).pipe(takeUntil(this.d$)).subscribe(
      data => this.update(data),
      error => () => {
        this.tableError = true;
        this.tableErrorMsg = error;
      }
    );
  }
}
