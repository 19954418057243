import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';

//Auth
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '@trend-common/auth/auth.service';
import { AuthTokenInterceptor } from '../common/auth/auth.token.interceptor';

import { TrendCoreModule } from '@trend-core/core.module';
import { TrendCommonModule } from '@trend-common/common.module';

 // Core modules
import { ICoreModule } from '../core/core.module';
import { MediatorsModule } from '../core/mediators/mediators.module';
import { DispatcherModule } from '@trend-core/dispatcher/dispatcher.module';

// Common Modules
import { ExternalModule } from '../common/external/external.module';
import { DashboardModule } from '../common/dashboard/dashboard.module';
import { PagesModule } from '../pages/pages.module';

// Services
import { AdapterService } from '@trend-core/adapter/adapter.service';
import { PaginationService } from '../common/pagination/pagination.service';
import { BreadcrumbsService } from '../common/breadcrumbs/breadcrumbs.service';

import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ExternalModule,
    DashboardModule,
    TrendCoreModule,
    TrendCommonModule,
    ICoreModule,
    PagesModule,
    MediatorsModule,
    DispatcherModule
  ],
  providers: [
    AuthService,
    AdapterService,
    PaginationService,
    BreadcrumbsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
