<h1 mat-dialog-title>Configure Feature Flags</h1>

<div mat-dialog-content>
  <div *ngIf="(featureFlags && featureFlags.length === 0) || featureFlagsError || (featureFlags.length === 0 && featureFlagsLoading)" class="featureFlagsOverlay">
    <span *ngIf="featureFlagsLoading && !featureFlagsError">Loading feature flags...</span>
    <span *ngIf="!featureFlagsError && !featureFlagsLoading">No feature flags found.</span>
    <span *ngIf="featureFlagsError && !featureFlagsLoading">We could not retrieve the feature flags.</span>
    <button *ngIf="featureFlagsError" mat-flat-button color="primary" (click)="getAccountFeatureFlags()">Retry</button>

  </div>

  <div *ngFor="let featureFlag of featureFlags" class="featureFlag">
    <span>{{ featureFlag }}</span>
    <button (click)="deleteFeatureFlag(featureFlag)" mat-icon-button>
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div *ngIf="addNewFeatureFlagDialog" class="featureFlag featureFlag--new">
    <mat-form-field appearance="outline">
      <mat-label>New feature flag key</mat-label>
      <input [formControl]="newFeatureFlagKeyFC" matInput>
    </mat-form-field>

    <button (click)="addFeatureFlag()" mat-icon-button color="primary">
      <mat-icon>save</mat-icon>
    </button>
    <button (click)="hideNewFeatureFlagDialog()" mat-icon-button color="primary">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <button (click)="showNewFeatureFlagDialog()" mat-flat-button color="addFeatureFlagButton">Add new</button>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button matDialogClose>Close</button>
</div>
