<header *ngIf="searchConfig">
  <mat-form-field *ngIf="searchConfig">
    <input matInput type="text" placeholder="{{ searchPlaceholder }}" [(ngModel)]="searchValue" (ngModelChange)="setFilteredResults()">
    <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</header>

<ngx-datatable
  class="material"
  *ngIf="!backendPagination"
  [headerHeight]="headerHeight"
  [footerHeight]="footerHeight"
  [columnMode]="columnMode"
  [rowHeight]="rowHeight"
  [rows]="rows"
  [columns]="cols"
  [limit]="10"
  [selectionType]="'single'"
  (select)="onSelect($event)">
</ngx-datatable>

<ngx-datatable
  class="material"
  *ngIf="backendPagination"
  [headerHeight]="headerHeight"
  [footerHeight]="footerHeight"
  [columnMode]="columnMode"
  [rowHeight]="rowHeight"
  [rows]="rows"
  [columns]="cols"
  [limit]="10"
  [selectionType]="'single'"
  (page)="setPage($event)"
  (select)="onSelect($event)">
</ngx-datatable>
