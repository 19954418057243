<section>
  <div *ngIf="!librariesReady" class="librariesOverlay">
    Loading Group Libraries
  </div>

  <div *ngIf="libraries === null && librariesReady" class="librariesOverlay">
    No environment was reported for this group, no libraries to display.
  </div>

  <div *ngIf="libraries && librariesReady">
    <div class="detail-table">
      <ul>
        <li class="detail-table__row">
          <span class="label">Last seen</span>
          <span class="value">{{ libraries.last_seen ? formatTime(libraries.last_seen) : 'N/A' }}</span>
        </li>
      </ul>
    </div>

    <div class="detail-table">
      <h4 class="detail-table__title">Environment</h4>
      <ul>
        <li class="detail-table__row">
          <span class="label">Group Environment ID</span>
          <span class="value">{{ libraries.application_environment_id || 'N/A' }}</span>
        </li>
      </ul>
      <ul>
        <li class="detail-table__row">
          <span class="label">Language</span>
          <span class="value">{{ libraries.language.name || 'N/A' }} version: {{ libraries.language.version }}</span>
        </li>
      </ul>
    </div>

    <div class="detail-table">
      <h4 class="detail-table__title">Runtime</h4>
      <ul>
        <li class="detail-table__row">
          <span class="label">{{ libraries.runtime.name || 'N/A' }}</span>
          <span class="value">{{ libraries.runtime.version || 'N/A' }}</span>
        </li>
      </ul>
    </div>

    <div class="detail-table">
      <h4 class="detail-table__title">Libraries</h4>
      <ul *ngFor="let library of libraries.libraries">
        <li class="detail-table__row">
          <span class="label">{{ library.name || 'N/A' }}</span>
          <span class="value">{{ library.version || 'N/A' }}</span>
        </li>
      </ul>
    </div>
  </div>
</section>
