import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APIUrlService } from '../api/api-url.service';
import { APIPolicySettings, APIPolicyCaptchaSettings, APIPolicyBlockPageSettings } from '../api/api-policy';

@Injectable()
export class Policies {
  private policiesEndpoint:string = this.api.url() + 'accounts/groups/';
  private captchaEndpoint:string = this.api.url() + 'captcha/groups/';
  private blockPageEndpoint:string = this.api.url() + 'security/';

  constructor(
    private http:HttpClient,
    private api:APIUrlService
  ) {}

  fetchPolicies(groupId:string):Observable<APIPolicySettings> {
    return this.http.get<APIPolicySettings>(this.policiesEndpoint + groupId + '/settings');
  }

  savePolicies(groupId:string, policies:APIPolicySettings):Observable<any> {
    return this.http.put(this.policiesEndpoint  + groupId + '/settings', policies);
  }

  fetchCaptchaSettings(groupId:string):Observable<APIPolicyCaptchaSettings> {
    return this.http.get<APIPolicyCaptchaSettings>(this.captchaEndpoint + groupId);
  }

  saveCaptchaSettings(groupId:string, settings:APIPolicyCaptchaSettings):Observable<any> {
    return this.http.put(this.captchaEndpoint + groupId, settings);
  }

  fetchBlockPageSettings(groupId:string):Observable<APIPolicyBlockPageSettings> {
    return this.http.get<APIPolicyBlockPageSettings>(this.blockPageEndpoint + groupId + "/block_page");
  }

  saveBlockPageSettings(groupId:string, settings:APIPolicyBlockPageSettings):Observable<any> {
    return this.http.put(this.blockPageEndpoint + groupId + "/block_page", settings);
  }
}
