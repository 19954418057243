import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { APIUrlService } from '@trend-core/api/api-url.service';

import * as moment from 'moment';

import { APIAgentInformation } from '@trend-core/api/api-agent-information-t';


@Injectable()
export class TelemetryService {
  private _statsItemLabels:object = {
    dropped_messages: 'Dropped messages',
    exceptions: 'Exceptions',
    rejected_messages: 'Rejected Messages',
    reported_transactions: 'Reported Transactions',
    total_transactions: 'Total Transactions',
    sampled_total_ns: 'Total transaction time (ms)',
    sampled_agent_time_ns: 'Total agent time (ms)',
    average_total_ns: 'Average total transaction time (ms)',
    average_agent_time_ns: 'Average total agent time (ms)',
    sampled_agent_time_percent: 'Sampled agent time percent',
    average_agent_time_percent: 'Average agent time percent'
  };

  constructor(
    private http:HttpClient,
    private apiUrlService:APIUrlService
  ) {}

  public getAccountsStatistics(timeframe:object):Observable<any> {
    let accountsStatisticsEndpoint = this.apiUrlService.url() + 'telemetry/accounts/statistics?from_time=' + timeframe['from'] + '&to_time=' + timeframe['to'] + '&bucket_duration=PT1H';
    return this.http.get(accountsStatisticsEndpoint);
  }

  public getAccountStatistics(accountId:string, timeframe:object):Observable<any> {
    let accountStatisticsEndpoint = this.apiUrlService.url() + 'telemetry/accounts/' + accountId + '/statistics?from_time=' + timeframe['from'] + '&to_time=' + timeframe['to'] + '&bucket_duration=PT1H';
    return this.http.get(accountStatisticsEndpoint);
  }

  public getGroupStatistics(groupId:string, timeframe:object):Observable<any> {
    let appStatisticsEndpoint = this.apiUrlService.url() + 'telemetry/groups/' + groupId + '/statistics?from_time=' + timeframe['from'] + '&to_time=' + timeframe['to'] + '&bucket_duration=PT1H';
    return this.http.get(appStatisticsEndpoint);
  }

  public getAgentStatistics(agentId:string):Observable<APIAgentInformation> {
    let agentStatisticsEndpoint = this.apiUrlService.url() + 'telemetry/agents/' + agentId;
    return this.http.get<APIAgentInformation>(agentStatisticsEndpoint);
  }

  public getGroupLibraries(groupId:string):Observable<any> {
    let appLibrariesEndpoint = this.apiUrlService.url() + 'telemetry/groups/' + groupId + '/application-environments/@latest';
    return this.http.get(appLibrariesEndpoint);
  }

  public getAgentEnvironments(groupId:string):Observable<any> {
    let agentEnvironmentsEndpoint = this.apiUrlService.url() + 'telemetry/groups/' + groupId + '/agent-environments/@latest';
    return this.http.get(agentEnvironmentsEndpoint);
  }

  public getCountChartData(data:object[], statsProp:string[], prop?:string):object {
    let countChartDataSeries = [];
    let countChartCategories = [];

    for(var s=0; s<statsProp.length; s++) {
      countChartDataSeries.push({
        name: this._statsItemLabels[statsProp[s]],
        data: []
      });

      for(var i=0; i<data.length; i++) {
        countChartDataSeries[s].data.push(prop !== undefined ? data[i][statsProp[s]][prop] : data[i][statsProp[s]]);
        if(s === 0) countChartCategories.push(new Date(data[i]['timestamp']).getTime());
      }
    }

    return {
      series: countChartDataSeries,
      categories: countChartCategories
    };
  }

  public getLineChartData(data:object[], statsProp:string[], prop?:string, formatFunction?:Function, groupId?:string):object {
    let countChartData = [];
    let countChartCategories = [];

    for(var s=0; s<statsProp.length; s++) {
      countChartData.push({
        name: this._statsItemLabels[statsProp[s]] || statsProp[s],
        data: []
      });

      for(var i=0; i<data.length; i++) {
        let currentValue:any;
        if(formatFunction) {
          currentValue = prop !== undefined ? formatFunction(data[i][statsProp[s]][prop]) : formatFunction(data[i][statsProp[s]]);
        } else {
          currentValue = prop !== undefined ? data[i][statsProp[s]][prop] : data[i][statsProp[s]];
        }

        countChartData[s].data.push(currentValue);
        if(s === 0) countChartCategories.push(new Date(data[i]['timestamp']).getTime());
      }
    }

    return {
      series: countChartData,
      categories: countChartCategories
    };
  }


  public getHooksPerStat(groupId:string, timeframeObj:object):Observable<any> {
    let appHookEndpoint = this.apiUrlService.url() + 'telemetry/groups/' + groupId + '/samples?from_time=' + timeframeObj['from'] + '&to_time=' + timeframeObj['to'];
    return this.http.get(appHookEndpoint);
  }

  public formatPluginsDataForStackedChart(data:object[], formatFunction?:Function):object {
    let chartData:object = {};
    let timestamps:Date[] = [];
    let totalTimeValues:number[] = [];
    let pluginList:string[] = [];
    let pluginsValues:object = {};
    let pluginsCounts:object = {};
    let hookList:string[] = [];
    let hooksValues:object = {};
    let hooksCounts:object = {};
    let luaList:string[] = [];
    let luaValues:object = {};
    let luaCounts:object = {};

    for(var i=0; i<data.length; i++) {
      for(var key in data[i]['plugin_timings']) {
        if(pluginList.indexOf(key) === -1) {
          pluginList.push(key);
        }
      }

      for(var key in data[i]['hook_timings']) {
        if(hookList.indexOf(key) === -1) {
          hookList.push(key);
        }
      }

      for(var key in data[i]['lua_timings']) {
        if(luaList.indexOf(key) === -1) {
          luaList.push(key);
        }
      }
    }

    for(var i=0; i<pluginList.length; i++) {
      pluginsValues[pluginList[i]] = [];
      pluginsCounts[pluginList[i]] = [];
    }
    for(var i=0; i<hookList.length; i++) {
      hooksValues[hookList[i]] = [];
      hooksCounts[hookList[i]] = [];
    }
    for(var i=0; i<luaList.length; i++) {
      luaValues[luaList[i]] = [];
      luaCounts[luaList[i]] = [];
    }

    for(var i=0; i<data.length; i++) {
      timestamps.push(new Date(data[i]['timestamp']));
      if(formatFunction) {
        totalTimeValues.push(formatFunction(data[i]['total_time_ns']));
      } else {
        totalTimeValues.push(data[i]['total_time_ns']);
      }

      for(var key in pluginsValues) {
        if(!data[i]['plugin_timings'].hasOwnProperty(key)) {
          pluginsValues[key].push(0);
          pluginsCounts[key].push(0);
        } else {
          if(formatFunction) {
            pluginsValues[key].push(formatFunction(data[i]['plugin_timings'][key]['total_time_ns']));
          } else {
            pluginsValues[key].push(data[i]['plugin_timings'][key]['total_time_ns']);
          }
          pluginsCounts[key].push(data[i]['plugin_timings'][key]['count']);
        }
      }

      for(var key in hooksValues) {
        if(!data[i]['hook_timings'].hasOwnProperty(key)) {
          hooksValues[key].push(0);
          hooksCounts[key].push(0);
        } else {
          if(formatFunction) {
            hooksValues[key].push(formatFunction(data[i]['hook_timings'][key]['total_time_ns']));
          } else {
            hooksValues[key].push(data[i]['hook_timings'][key]['total_time_ns']);
          }
          try {
            hooksCounts[key].push(data[i]['hook_timings'][key]['count']);
          } catch(err) {
            hooksCounts[key].push(0);
          }
        }
      }

      for(var key in luaValues) {
        if(!data[i]['lua_timings'].hasOwnProperty(key)) {
          luaValues[key].push(0);
          luaCounts[key].push(0);
        } else {
          if(formatFunction) {
            luaValues[key].push(formatFunction(data[i]['lua_timings'][key]['total_time_ns']));
          } else {
            luaValues[key].push(data[i]['lua_timings'][key]['total_time_ns']);
          }
        }
        try {
          luaCounts[key].push(data[i]['lua_timings'][key]['count']);
        } catch(err) {
          luaCounts[key].push(0);
        }
      }
    }

    let series:object[] = [];

    for(var key in pluginsValues) {
      series.push({
        name: key,
        type: 'column',
        data: pluginsValues[key],
        count: pluginsCounts[key]
      });
    }
    series.push({
      name: 'Total transaction time',
      type: 'line',
      data: totalTimeValues
    })

    chartData['series'] = series;
    chartData['hooksSeries'] = {
      time: hooksValues,
      count: hooksCounts
    }
    chartData['luaSeries'] = {
      time: luaValues,
      count: luaCounts
    };
    chartData['timestamps'] = timestamps;

    return chartData;
  }
}
