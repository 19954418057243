import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BillingService } from '@trend-core/trend/billing';
import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';
import { ValidatorService } from '@trend-core/trend/validator.service';


@Component({
  selector: 'change-account-billing-modal',
  templateUrl: 'change-account-billing-modal.html',
})
export class ChangeAccountBillingModal {
  public subscriptionLicensesFC:FormControl = new FormControl('', [Validators.required, ValidatorService.onlyNumbers]);
  public selectedAccountId:string;

  constructor(
    public dialogRef: MatDialogRef<ChangeAccountBillingModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private feedbackMessagesService:FeedbackMessagesService,
    private billingService:BillingService
  ) {}

  public updateSubscriptionLicenses():void {
    if(!this.subscriptionLicensesFC.value) {
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Missing form value',
        text: 'The subscription licenses count is missing in the form above.'
      });
      return;
    }

    this.billingService.updateSubscriptionLicenses(
      this.modalData['account']['account_id'],
      this.subscriptionLicensesFC.value
    ).subscribe(() => {
      this.dialogRef.close();
      this.modalData['onSuccessFn']();
      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Success',
        text: 'The subscription licenses has successfully been updated for ' + this.modalData['account']['tenant_name'] + '/\'s account.'
      });
    }, (err:object) => {
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'The subscription licenses could not be updated at the moment.'
      });
    });
  }

  public cancel():void {
    this.dialogRef.close();
  }

  public getInputErrorMessage(formControl:any) {
    return formControl.hasError('required') ? 'You must enter a value' :
           formControl.hasError('email') ? 'Not a valid email' :
           formControl.hasError('onlyNumbers') ? 'You must enter a number' : '';
  }
}
