import { Component } from '@angular/core';

import { ModalService } from '@trend-common/modals/modal.service';
import { DrawerService } from '../../common/drawer/drawer.service';
import { BreadcrumbsService } from '../../common/breadcrumbs/breadcrumbs.service';
import { DispatcherServiceFactory } from '@trend-core/dispatcher/dispatcher.service.factory';

import { VerifiedUsersTableMediator } from '../../core/mediators/verified-users-table.mediator';
import { VerifiedUserDetailsMediator } from '../../core/mediators/verified-user-details.mediator';

import { PendingUsersTableMediator } from '../../core/mediators/pending-users-table.mediator';
import { PendingUserDetailsMediator } from '../../core/mediators/pending-user-details.mediator';

import { PendingUserDetailsDrawer } from '../../common/drawer/drawers/pending-user-details/pending-user-details';
import { VerifiedUserDetailsDrawer } from '../../common/drawer/drawers/verified-user-details/verified-user-details';

import { AddPendingUserModal } from '../../common/modals/add-pending-user-modal/add-pending-user-modal';
import { ValidatePendingUserModal } from '../../common/modals/validate-pending-user-modal/validate-pending-user-modal';

import { MatDataTableCols } from '@trend-common/mat-data-table/mat-data-table-colsDef-t';


@Component({
  selector: 'users',
  host: {
    class: 'pageComponent'
  },
  templateUrl: 'users.html',
  styleUrls: ['users.scss']
})
export class UsersPage {
  public title:string = 'Users';
  addUserModal:any;

  public verifiedCols:string[] = [ 'email', 'first_name', 'last_name', 'account_id', 'created_on' ];
  public verifiedColsDef:MatDataTableCols;
  public verifiedSearchConfig = {
    label: 'users',
    keys: [ 'email', 'first_name', 'last_name' ],
    info: 'Filter by email, first or last name.',
    width: '300px'
  }

  constructor(
    public verifiedUsersTableMediator:VerifiedUsersTableMediator,
    public pendingUsersTableMediator:PendingUsersTableMediator,
    private modal: ModalService,
    private dispatcherServiceFactory:DispatcherServiceFactory,
    public verifiedUserDetailsMediator:VerifiedUserDetailsMediator,
    public pendingUserDetailsMediator:PendingUserDetailsMediator,
    private breadcrumbsService:BreadcrumbsService
  ) {}

  ngOnInit():void {
    this.verifiedColsDef = [
      {
        colId: 'email',
        colHeader: 'Email'
      },
      {
        colId: 'first_name',
        colHeader: 'First Name'
      },
      {
        colId: 'last_name',
        colHeader: 'Last Name'
      },
      {
        colId: 'account_id',
        colHeader: 'Account ID'
      },
      {
        colId: 'created_on',
        colHeader: 'Created On'
      }
    ];

    this.breadcrumbsService.updateBreadcrumbs([
      {
        text: 'Users',
        link: ''
      }
    ]);
  }

  public openAddPendingUserModal():void {
    this.addUserModal = this.modal.openModal(
      AddPendingUserModal, {
        data: {
          afterSuccessFn: this.postAddPendingUser.bind(this)
        }
      }
    );
  }

  public openValidatePendingUserModal():void {
    this.modal.openModal(
      ValidatePendingUserModal, {
        data: {
          afterSuccessFn: this.postValidatePendingUser.bind(this)
        }
      }
    );
  }

  private postAddPendingUser():void {
    this.pendingUsersTableMediator['reloadTable']();
  }

  private postValidatePendingUser():void {
    this.verifiedUsersTableMediator['reloadTable']();
    this.pendingUsersTableMediator['reloadTable']();
  }

  public onTabChange():void {
    window.dispatchEvent(new Event('resize'));
  }

  public onUserRowClick = (row:object):void => {
    this.dispatcherServiceFactory.getServiceInstance(DrawerService, 'mainDrawer', "VerifiedUserDetailsMediator").forceUpdateBodyInto(row, VerifiedUserDetailsDrawer);
  }

  public onPendingUsersTableSelectRow(e:object) {
    this.dispatcherServiceFactory.getServiceInstance(DrawerService, 'mainDrawer', "PendingUserDetailsMediator").forceUpdateBodyInto(e['selected'][0], PendingUserDetailsDrawer);
  }
}
