import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({name: 'enabledDisabledPipe'})
export class EnabledDisabledPipe implements PipeTransform {
  private icons:object = {
    "enabled": "<i class='material-icons'>check_circle</i>",
    "disabled": "<i class='material-icons'>error</i>",
    "pending": "<i class='material-icons'>sync</i>"
  };
  private text:object = {
    "enabled": "Idle",
    "disabled": "Error",
    "pending": "Pending"
  }
  public transform(status:string):string {
    return "<span class='col-status-inner " + status + "-cell'>" + this.icons[status] + " " + this.text[status][0].toUpperCase() + this.text[status].slice(1) + "</span>";
  }
}
