import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DispatcherInterface } from '@trend-core/dispatcher/dispatcher-i';
import { FilterConfigs } from './filter-configs';

@Injectable()
export class FilterListService implements DispatcherInterface {

  constructor() {}

  /* tslint:disable */
  private filterSet:string;
  private pairHash:string;
  /* tslint:enable */

  private filterItems = FilterConfigs;

  private filterSubject = new Subject<any>();
  private forceUpdateSubject = new Subject<any>();

  //builds default list of filters
  private filters:string

  updateSubject = this.filterSubject;

  //triggere by the mediator
  getDefaults():Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.getFilters());
    });
  }

  //gets the current filter items
  //if they haven't been set yet, set them based off default config
  getFilters():string {
    if(this.filters === undefined){
      let activeFilters:string[] = [],
          filterStr:string;

      for(let x in this.filterItems[this.filterSet]) {
        let filterItem = this.filterItems[this.filterSet][x];
        if(filterItem.active){
          activeFilters.push(filterItem.label);
        }
      }

      filterStr = activeFilters.join(', ');

      this.filters = filterStr;
    }

    return this.filters;
  }

  //is triggered by the apply button
  setFilters(filters:string):void {
    this.filters = filters;
    this.filterSubject.next(filters);
  }

  //sets the title of the filter set of this current service
  setFilterSet(set:string):void {
    this.filterSet = set;
  }

  //is triggered by the component for it's defaults
  getFilterSetItems(filterSetName:string):any {
    return this.filterItems[filterSetName];
  }

  setPairHash(pairHash:string):void {
    this.pairHash = pairHash;
  }

  filterData(data:any[], subParams:object, severityProp:string, typeProp:any):any[] {
    let filteredData:any;

    //handle priority filter
    if(subParams && subParams['priority'] && severityProp){
      filteredData = [];

      let priorityLowerCase = Array.from(subParams['priority'].split(', '), x => x['toLowerCase']());

      for(let d = 0; d < data['length']; d++){
        if(priorityLowerCase.indexOf(data[d][severityProp].toLowerCase()) > -1){
          filteredData.push(data[d]);
        }
      }
    } else {
      filteredData = data;
    }

    let typeFilteredData:any;

    if(subParams && subParams['type'] && typeProp){
      typeFilteredData = [];

      let typeFilterList = subParams['type'].split(", ");

      if(typeof typeProp === 'string'){
        for(let d = 0; d < filteredData['length']; d++){
          if(typeFilterList.indexOf(filteredData[d][typeProp]) > -1){
            typeFilteredData.push(filteredData[d]);
          }
        }
      } else {
        let dataSeriesProp = Object.keys(typeProp)[0];

        for(let d = 0; d < filteredData['length']; d++){

          let newDateItem:object = {
            timestamp: filteredData[d].timestamp
          };

          newDateItem[dataSeriesProp] = {};

          for(let seriesName in filteredData[d][dataSeriesProp]){
            if(typeFilterList.indexOf(seriesName) > -1){
              newDateItem[dataSeriesProp][seriesName] = filteredData[d][dataSeriesProp][seriesName];
            }
          }

          typeFilteredData.push(newDateItem);
        }
      }
    } else {
      typeFilteredData = filteredData;
    }

    return typeFilteredData;
  }

  //updates the defaults, is triggered by the mediator coming from the address bar
  update(payload:string):void {
    //find out which filterset one it's part of
    if(payload != undefined ){
      let filterEntries = payload.split(', '),
          firstFilterEntry = filterEntries[0],
          filterSet;

      for(let fs in this.filterItems){
        for(let fi in this.filterItems[fs]){
          if(firstFilterEntry === this.filterItems[fs][fi].label){
            filterSet = fs;
          }
        }
      }

      for(let fsi in this.filterItems[filterSet]){
        this.filterItems[filterSet][fsi].active = filterEntries.indexOf(this.filterItems[filterSet][fsi].label) > -1;
      }

      this.filters = payload;
    }
  }

  onForceUpdate():Observable<any>{
    return this.forceUpdateSubject.asObservable();
  }

  //subbed to by mediator
  onUpdate():Observable<any> {
    return this.filterSubject.asObservable();
  }
}
