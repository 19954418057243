import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIAPIKeyCreate, APIAPIKey } from '@trend-core/api/api-api-key-t';
import { APIUrlService } from '../api/api-url.service';

@Injectable()
export class APIKeyService {
  public apiKeysEndpoint = this.api.url() + 'accounts/api_keys';

  constructor(
    private http:HttpClient,
    private api:APIUrlService
  ) {}

  public fetchAPIKeys():Observable<APIAPIKey[]> {
    return this.http.get<APIAPIKey[]>(this.apiKeysEndpoint);
  }

  public addAPIKeyToAccount(key:APIAPIKeyCreate):Observable<any> {
    return this.http.post(this.apiKeysEndpoint, key);
  }

  public updateAPIKey(id:string, key:APIAPIKeyCreate):Observable<any> {
    return this.http.put(this.apiKeysEndpoint + "/" + id, key);
  }

  public removeAPIKeyFromAccount(key:APIAPIKey):Observable<any> {
    return this.http.delete(this.apiKeysEndpoint + "/" + key.api_key_id);
  }
}
