import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';
import { Observable, Subject, throwError } from 'rxjs';
import { map, switchMap, catchError, takeUntil } from 'rxjs/operators';

import { AuthService } from '@trend-common/auth/auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  private refreshTokenInProgress:boolean;
  private refreshTokenSubject:any = new Subject();

  private refreshAuthTokenObs:Observable<any> = new Observable(observer => {
    let $complete = new Subject();

    this.auth.refreshToken().pipe(takeUntil($complete)).subscribe(data => {
      this.auth.setToken(data.auth_token);
      this.refreshTokenInProgress = false;
      this.refreshTokenSubject.next();

      $complete.next();

      observer.next();
      observer.complete();
    }, err => {
      this.refreshTokenInProgress = false;
      this.auth.removeToken();
      this.router.navigate(['login']);

      $complete.next();

      return Rx.throwError(err);
    });
  });

  private refreshInProgressObs:Observable<any> = new Observable(observer => {
    let $complete = new Subject();

    this.onRefreshToken().pipe(takeUntil($complete)).subscribe(() => {
      $complete.next();

      observer.next();
      observer.complete();
    });
  });

  constructor(
    public auth:AuthService,
    public router:Router
  ) {}

  onRefreshToken():Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let req = request.clone({
       setHeaders: {
         Authorization: `AppSecBearer ${this.auth.getToken()}`
       }
     });

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        //success
        if (event instanceof HttpResponse) {

        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if(request.url.includes("token") || request.url.includes("login")) {
          if(request.url.includes("token")) {
            this.router.navigate(['login']);

            return Rx.throwError(error);
          }
        }

        if(error.status === 403) {
          // captcha
        }

        if(error instanceof HttpErrorResponse && error.status === 401) {
          // unauthorized
          return this.refreshToken().pipe(switchMap(()=>{
            return next.handle(this.tokenizeRequest(req));
          }));
        }
        return throwError(error);
      }));
  }

  private tokenizeRequest(request: HttpRequest<any>):HttpRequest<any> {
    return request.clone({
       setHeaders: {
         Authorization: `AppSecBearer ${this.auth.getToken()}`
       }
    });
  }

  refreshToken() {
    if (this.refreshTokenInProgress) {
      return this.refreshInProgressObs;
    }

    this.refreshTokenInProgress = true;
    return this.refreshAuthTokenObs;
  }
}
