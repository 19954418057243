import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';
import { FeatureToggleService } from '@trend-common/feature-toggle.service';
import { Account } from '../../../core/stores/account';

@Component({
  selector: 'account-feature-flags-modal',
  templateUrl: 'account-feature-flags-modal.html',
  styleUrls: ['account-feature-flags-modal.scss']
})
export class AccountFeatureFlagModal {
  public accountFeatureFlags:any[];
  public accountFeatureFlagsError:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AccountFeatureFlagModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private feedbackMessagesService:FeedbackMessagesService,
    private _featureToggleService:FeatureToggleService,
    private _account:Account
  ) {}

  ngOnInit():void {
    this.getAccountFeatureFlags();
  }

  public getAccountFeatureFlags():void {
    this.accountFeatureFlagsError = false;

    this._account.getAllAccountFeatures().subscribe((features:any) => {
      let accountFeatures:object[] = [];
      for(var i=0; i<features.feature_flags.length; i++) {
        accountFeatures.push({
          featureKey: features.feature_flags[i]
        });
      }

      this._account.getAccount(this.modalData['accountId']).subscribe((account:any) => {
        for(var i=0; i<accountFeatures.length; i++) {
          accountFeatures[i]['enabled'] = account.enabled_features.indexOf(accountFeatures[i]['featureKey']) > -1 ? true : false;
        }
        this.accountFeatureFlags = accountFeatures;
        this.accountFeatureFlagsError = false;
      }, (err:object) => {
        console.error('AccountFeatureFlagModal | _account.getAccount failed',err);
        this.accountFeatureFlagsError = true;
      });
    }, (err:object) => {
      console.error('AccountFeatureFlagModal | _account.getAllAccountFeatures failed',err);
      this.accountFeatureFlagsError = true;
    });
  }

  public setAccountFeatureFlags():void {
    let payload = {
      enabled_features: []
    };
    for(var i=0; i<this.accountFeatureFlags.length; i++) {
      if(this.accountFeatureFlags[i].enabled) payload.enabled_features.push(this.accountFeatureFlags[i].featureKey);
    };

    this._account.setAccountFeatureFlags(this.modalData['accountId'], payload).subscribe(() => {
      this.dialogRef.close();

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'Feature Flags Saved',
        text: 'The feature flags were saved for the account.'
      });
    }, (err:object) => {
      console.error('AccountFeatureFlagModal | setAccountFeatureFlags failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not save the feature flags.'
      });
    });
  }

  public cancel():void {
    this.dialogRef.close();
  }
}
