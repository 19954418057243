<div class="realtimeContent" *ngIf="isRealtimeOn">
  <mat-button-toggle-group name="realtimeOptions" aria-label="Realtime Options" value="{{activeRangeType}}">
    <mat-button-toggle value="day" (click)="handleRealtimeRangeClick('lastday')">Last 24 Hours</mat-button-toggle>
    <mat-button-toggle value="week" (click)="handleRealtimeRangeClick('lastweek')">Last 7 Days</mat-button-toggle>
    <mat-button-toggle value="custom" (click)="openRealtimeCustomRangeModal()">
      <span *ngIf="activeRangeType === 'custom'">Last {{activeTimeframe.value}} {{capitalize(activeTimeframe.unit)}}</span>
      <span *ngIf="activeRangeType !== 'custom'">Custom range</span>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div class="statictimeContent" *ngIf="!isRealtimeOn">
  <div class="statictimeContent__formContainer statictimeContent__formContainer--from">
    <span class="statictimeContent__label statictimeContent__label--start">Start</span>
    <mat-form-field>
      <input matInput [matDatepicker]="frompicker" [max]="endDate" placeholder="Start Date" [formControl]="staticFromFC" readonly (dateChange)="staticTimeChanged($event)">
      <mat-datepicker-toggle matSuffix [for]="frompicker"></mat-datepicker-toggle>
      <mat-datepicker #frompicker disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="statictimeContent__formContainer statictimeContent__formContainer--to">
    <span class="statictimeContent__label statictimeContent__label--end">End</span>
    <mat-form-field>
      <input matInput [matDatepicker]="topicker" [min]="startDate" [max]="today" placeholder="End Date" [formControl]="staticToFC" readonly (dateChange)="staticTimeChanged($event)">
      <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
      <mat-datepicker #topicker disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>
</div>

<div class="realtimeToggle"
     [ngClass]="{'isRealtimeOn':isRealtimeOn, 'isRealtimeOff':!isRealtimeOn}"
     [attr.data-telemetry-id]="isRealtimeOn ? 'timeframe_toggleRealtimeOff' : 'timeframe_toggleRealtimeOn'">
  <mat-slide-toggle color="primary" id="realtime-toggle" checked="{{isRealtimeOn}}" [(ngModel)]="isRealtimeOn" (click)="handleToggleClick()">Real-time</mat-slide-toggle>
  <!-- <ion-item>
    <ion-label>Auto Refresh</ion-label>
    <ion-toggle checked="{{isRealtimeOn}}" [(ngModel)]="isRealtimeOn" (click)="handleToggleClick()"></ion-toggle>
  </ion-item> -->
</div>
