import { Injectable } from '@angular/core';
import { WindowService } from '../trend/window';
import { environment } from 'src/environments/environment';


@Injectable()
export class APIUrlService {
  private cloudOneShellStateName:string = "C1C_Shell_State";
  private c1Endpoint:string = undefined;

  private _localApis:object = {
    test: {
      'US-1': 'https://api.test.im7.io/',
      'TREND-US-1': 'https://api.trend-us-1.test.app-protect.trendmicro.com/',
    },
    stg: {
      'US-1': 'https://api.stg.im7.io/',
      'TREND-US-1': 'https://api.trend-us-1.stg.app-protect.trendmicro.com/',
    },
    prod: {
      'US-1': 'https://api.app-protect.trendmicro.com/',
      'TREND-US-1': 'https://api.trend-us-1.app-protect.trendmicro.com/',
      'IN-1': 'https://api.in-1.app-protect.trendmicro.com/',
      'AU-1': 'https://api.au-1.app-protect.trendmicro.com/',
      'DE-1': 'https://api.de-1.app-protect.trendmicro.com/',
      'GB-1': 'https://api.gb-1.app-protect.trendmicro.com/',
      'JP-1': 'https://api.jp-1.app-protect.trendmicro.com/',
      'SG-1': 'https://api.sg-1.app-protect.trendmicro.com/',
      'CA-1': 'https://api.ca-1.app-protect.trendmicro.com/',
    }
  };

  private _dashboards:object = {
    green: {
      test: "https://dashboard.test-1.trendmicro.com/#/",
      // stg: "https://dashboard.stg.im7.io/#/",
      prod: "https://dashboard.app-protect.trendmicro.com/#/"
    },
    red: {
      test: "https://dashboard.test.im7.io/#/",
      stg: "https://dashboard.stg.im7.io/#/",
      prod: "https://dashboard.prod.im7.io/#/"
    }
  };

  constructor(
    private windowService:WindowService
  ){};

  public getRegions():string[] {
    return Object.keys(this._localApis[this._getEnvironment()]);
  }

  public getRegion():string {
    let region = localStorage.getItem('c1as-api-endpoint') ? localStorage.getItem('c1as-api-endpoint').split('https://api.')[1].split('.')[0] : 'us-1';
    return (region === 'stg' || region === 'test' || region === 'app-protect' || region === 'prod') ? 'us-1': region;
  }

  public getRegionalizedAPIUrl(regionName:string):string {
    return this._localApis['']
  }

  public url(regionName?:string):string {
    let url:string;
    let region:string;

    if(this.windowService.getWindow()['INTERNAL_SHELL']){
      let sessionAPIUrl = this._getSessionAPIUrl();
      if(!regionName && sessionAPIUrl) {
        sessionStorage.setItem('_tmco_api', sessionAPIUrl);
        return sessionAPIUrl;
      }

      region = regionName ? regionName.toUpperCase() : 'US-1';

      if(environment.apiEnv && (environment.apiEnv === 'test' || environment.apiEnv === 'stg')) {
        sessionStorage.setItem('_tmco_api', this._localApis[environment.apiEnv][region]);
        return this._localApis[environment.apiEnv][region];
      }

      url = this._localApis['prod'][region];

      switch(true){
        case (this.windowService.getWindow().location.href.indexOf('.stg.') > -1):
          url = this._localApis['stg'][region];
          break;
        case (this.windowService.getWindow().location.href.indexOf('.test') > -1):
        case (this.windowService.getWindow().location.href.indexOf('localhost') > -1):
        case (this.windowService.getWindow().location.href.indexOf('appsecinternal') > -1):
          url = this._localApis['test'][region];
          break;
      }

      sessionStorage.setItem('_tmco_api', url);
    } else {
      try{
        url = this.getCloudOneEndpoint();
      } catch(e){
        console.log(e);
        this.windowService.getWindow().location.href = "home";
      }
    }

    return url;
  }

  public removeStoredAPIEndpoint() {
    localStorage.removeItem('c1as-api-endpoint');
  }

  private _getEnvironment() {
    if(this.windowService.getWindow().location.href.indexOf('.test.') > -1) {
      return 'test';
    } else if(this.windowService.getWindow().location.href.indexOf('.stg.') > -1) {
      return 'stg';
    } else {
      return 'prod';
    }
  }

  public setSessionAPIUrl(apiUrl:string):void {
    localStorage.setItem('c1as-api-endpoint', apiUrl);
  }

  private _getSessionAPIUrl():string {
    return localStorage.getItem('c1as-api-endpoint');
  }

  public dashboardUrl(addExternal?:boolean, forceRed:boolean = false):string {
    if(forceRed) return addExternal ? this._dashboards['red']['prod'].replace('#', 'external#') : this._dashboards['red']['prod'];

    let mainEnv:string = this.windowService.getWindow().location.href.indexOf('.trendmicro.com') > -1 ? 'green': 'red';
    let url:string = this._dashboards[mainEnv]['prod'];

    switch(true){
      case (this.windowService.getWindow().location.href.indexOf('.stg.') > -1):
        url = this._dashboards['red']['stg'];
        break;
      case (this.windowService.getWindow().location.href.indexOf('.test') > -1):
      case (this.windowService.getWindow().location.href.indexOf('localhost') > -1):
        url = this._dashboards[mainEnv]['test'];
        break;
    }

    return addExternal ? url.replace('#', 'external#') : url;
  }

  public getCloudOneEndpoint():string {
    if(!this.c1Endpoint){
      const c1Services = JSON.parse(sessionStorage.getItem(this.cloudOneShellStateName)).c1Services.c1ServicesList,
            applicationService = c1Services.find(service => service.id === "application")

      this.c1Endpoint = applicationService.links.find(link => link.rel === "endpoint").href;
    }

    return this.c1Endpoint;
  }

  public interpolateEndpoint(url:string, data:object):string {
    return url.replace(/{(.*?)}/g, (v, k) => data[k]);
  }
}
