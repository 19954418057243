import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { APIUserRoles } from '../../../core/types/api/api-user-roles-t';

import { User } from '../../../core/stores/user';

import { FeedbackMessagesService } from '@trend-common/feedback-messages/feedback-messages.service';


@Component({
  selector: 'change-user-role-modal',
  templateUrl: 'change-user-role-modal.html',
  styleUrls: ['change-user-role-modal.scss']
})
export class ChangeUserRoleModal {
  public userRoles:APIUserRoles[];
  public selectedUserRole:APIUserRoles;
  public getUserRoleError:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ChangeUserRoleModal>,
    @Inject(MAT_DIALOG_DATA) public modalData:object,
    private user:User,
    private feedbackMessagesService:FeedbackMessagesService
  ) {}

  ngOnInit():void {
    this.getUserRoles();
  }

  public getUserRoles():void {
    this.getUserRoleError = false;

    this.user.getUserRoles().subscribe((userRoles:APIUserRoles[]) => {
      this.userRoles = userRoles;

      for(var i=0; i<userRoles.length; i++) {
        if(userRoles[i].name === this.modalData['verifiedUser'].role) {
          this.selectedUserRole = userRoles[i];
          break;
        }
      }
    }, (err:object) => {
      console.error('ChangeUserRoleModal | user.getUserRoles failed',err);
      this.getUserRoleError = true;
    });
  }

  public changeUserRole():void {
    if(!this.selectedUserRole) {
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something is missing',
        text: 'Please verify that all the fields are filled correctly.'
      });
      return;
    }

    this.user.updateUserRoles(this.modalData['verifiedUser'].account_id, this.modalData['verifiedUser'].user_id, this.selectedUserRole.name).subscribe(() => {
      this.dialogRef.close();

      if(this.modalData && this.modalData['afterSuccessFn']) {
        this.modalData['afterSuccessFn'](this.selectedUserRole.name);
      }

      this.feedbackMessagesService.add({
        type: 'success',
        title: 'User role successfully updated',
        text: 'The new user role is now in effect.'
      });
    }, (err:object) => {
      console.error('ChangeUserRoleModal | user.changeUserRole failed', err);
      this.feedbackMessagesService.add({
        type: 'error',
        title: 'Something went wrong',
        text: 'We could not the user\'s role.'
      });
    });
  }

  public onSelectedRoleChange(selectedRole:APIUserRoles):void {
    this.selectedUserRole = selectedRole;
  }

  public cancel():void {
    this.dialogRef.close();
  }

  public getInputErrorMessage(formControl:FormControl) {
    return formControl.hasError('required') ? 'You must enter a value' : '';
  }
}
