import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgApexchartsModule } from "ng-apexcharts";

import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DataCharts } from './data-charts';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    NgApexchartsModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  providers: [

  ],
  declarations: [
    DataCharts
  ],
  exports: [
    DataCharts
  ]
})
export class DataChartsModule {}
