<h1 mat-dialog-title>Create Account</h1>

<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Account Tenant Name</mat-label>
    <input [formControl]="tenantNameFC" matInput>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-stroked-button>Cancel</button>
  <button (click)="create()" mat-flat-button color="primary" matDialogClose [disabled]="tenantNameFC.status == 'INVALID'">Create</button>
</div>
